import {
  DefaultButton,
  DetailsListLayoutMode,
  IColumn,
  IObjectWithKey,
  ISelection,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  TextField,
} from "@fluentui/react";
import { useRecoilState } from "recoil";
import { userSearchState } from "../../state/userSearchState";
import { useTranslator } from "../../state/translatorState";
import { useMemo, useRef } from "react";
import { useSearchUsersQuery } from "../../../features/users/userQueries";
import EditDialog from "./EditDialog";
import { ADUserSearchResult } from "../../../services/contactReportsApi";

const UserSearchDialog = () => {
  const t = useTranslator();
  const [state, setState] = useRecoilState(userSearchState);
  const { data: foundUsers, isLoading, isFetched } = useSearchUsersQuery(state.query, { enabled: state.query.length > 0 });
  const isEmpty = isFetched && (!Array.isArray(foundUsers) || foundUsers.length === 0);
  const selection = useRef<ISelection<IObjectWithKey>>(
    new Selection({
      onSelectionChanged: () => {
        const selectedItems = selection.current.getSelection();
        setState((s) => ({ ...s, selectedUser: (selectedItems[0] as ADUserSearchResult) ?? null }));
      },
      getKey: (item) => {
        return (item as ADUserSearchResult).uniqueId;
      },
    })
  );
  const columns: IColumn[] = useMemo(
    (): IColumn[] => [
      {
        key: "displayName",
        name: t.Components.UserSearchDialog.GridColumn.Name.Label,
        fieldName: "displayName",
        minWidth: 220,
      },
      {
        key: "email",
        name: t.Components.UserSearchDialog.GridColumn.Email.Label,
        fieldName: "email",
        minWidth: 280,
      }
      // {
      //   key: "loginName",
      //   name: t.Components.UserSearchDialog.GridColumn.LoginName.Label,
      //   fieldName: "loginName",
      //   minWidth: 200,
      // },
    ],
    [t]
  );

  const renderFooter = () => (
    <Stack horizontal className="mt-4 gap-4 flex-row-reverse">
      <DefaultButton onClick={state.cancel} text={t.Components.Button.Cancel.Label} />
      <PrimaryButton onClick={handleSaveClick} text={t.Components.Button.Confirm.Label} disabled={!state.selectedUser || isLoading} />
    </Stack>
  );

  const handleSaveClick = () => {
    if (state.selectedUser) {
      state.confirm(state.selectedUser);
    }
  };

  const handleQueryTextChanged = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
    setState((s) => ({ ...s, query: newValue ?? "" }));
  };

  return (
    <EditDialog
      isOpen={state.isVisible}
      onCancel={state.cancel}
      title={t.Components.UserSearchDialog.Title}
      containerClassName="w-full lg:w-2/3 xl:w-1/2"
      footer={renderFooter()}
      height="24rem"
    >
      <div className="h-full">
        <div className="mt-4 w-full md:w-64">
          <TextField
            autoFocus
            placeholder={t.Components.UserSearchDialog.Filter.Query.Placeholder}
            iconProps={{ iconName: "Search" }}
            onChange={handleQueryTextChanged}
          />
        </div>
        <ShimmeredDetailsList
          columns={columns}
          items={foundUsers ?? []}
          compact
          selectionMode={SelectionMode.single}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selection={selection.current}
          enableShimmer={isLoading}
          listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0, renderCount: 5 }}
        />
        {isEmpty && <div className="text-center">{t.Components.UserSearchDialog.List.NoUsers}</div>}
      </div>
    </EditDialog>
  );
};

export default UserSearchDialog;
