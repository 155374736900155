import { ChangeEvent, FC, useRef } from "react";
import { HookFormProps } from "../../model/hookFormProps";
import { DefaultButton, IButtonProps, Label } from "@fluentui/react";
import { Controller } from "react-hook-form";
import DeleteIconButton from "../button/DeleteIconButton";
import { UploadedFileDetailDTO } from "../../../services/contactReportsApi";
import useErrorHandler, { useErrorDialog } from "../../hooks/useErrorHandler";
import { useTranslator } from "../../state/translatorState";
import environment from "../../../app/environment";
import { formatFileSize } from "../../utilities";

type FileUploadProps = {
  onFileSelected: (file: File) => UploadedFileDetailDTO | Promise<UploadedFileDetailDTO>;
  allowedFileExtensions?: string[];
};

const hasValidFileExtension = (fileName: string, allowedExtensions?: string[]): boolean => {
  if (!Array.isArray(allowedExtensions) || allowedExtensions.length === 0) return true;
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1) return false;

  const extension = fileName.substring(lastDotIndex);
  return allowedExtensions.some((ext) => ext === extension);
};

const hasExceededMaxFileSize = (file: File) => file.size >= environment.maxUploadFileSizeInBytes;

const ControlledFileUploadButton: FC<IButtonProps & HookFormProps & FileUploadProps> = ({
  onFileSelected,
  allowedFileExtensions,
  name,
  defaultValue,
  ...props
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const errorHandler = useErrorHandler();
  const { showErrors } = useErrorDialog();
  const t = useTranslator();

  const handleFileChanged = async (e: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
    const file = e.target.files != null ? e.target.files[0] ?? null : null;

    if (file) {
      try {
        if (hasExceededMaxFileSize(file)) {
          showErrors(t.Components.ErrorDialog.ValidationError.Title, [
            t.UploadedFilesValidator.UploadedFile.FileIsTooBig(file.name, formatFileSize(environment.maxUploadFileSizeInBytes)),
          ]);
          resetFileInput();
          onChange(null);
        } else if (hasValidFileExtension(file.name, allowedFileExtensions)) {
          const uploaded = await onFileSelected(file);
          onChange(uploaded);
        } else {
          resetFileInput();
          onChange(null);
          showErrors(t.Components.ErrorDialog.ValidationError.Title, [
            t.UploadedFilesValidator.UploadedFile.FileTypeIsNotSupported(file.name),
          ]);
        }
      } catch (error) {
        errorHandler(error);
        resetFileInput();
        onChange(null);
      }
    }
  };

  const resetFileInput = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  const handleButtonClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleDeleteClick = (onChange: (...event: any[]) => void) => {
    onChange(null);
    resetFileInput();
  };

  return (
    <Controller
      name={name}
      control={props.control}
      rules={props.rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <>
          <input
            type="file"
            multiple={false}
            ref={inputFileRef}
            className="hidden"
            onChange={(e) => handleFileChanged(e, onChange)}
            accept={Array.isArray(allowedFileExtensions) ? allowedFileExtensions.join(",") : ""}
          />
          <div className="flex flex-row gap-4">
            <DefaultButton {...props} onClick={handleButtonClick} />
            {value && <DeleteIconButton onClick={() => handleDeleteClick(onChange)} disabled={props.disabled} />}
          </div>
          {value && typeof value.fileName === "string" && value.fileName.length > 0 && (
            <Label className="h-22">{value.fileName + value.extension}</Label>
          )}
          {!value && <div className="h-8" />}
        </>
      )}
    />
  );
};

export default ControlledFileUploadButton;
