import { useResetRecoilState, useSetRecoilState } from "recoil";
import { userSearchState } from "../state/userSearchState";
import { ADUserSearchResult } from "../../services/contactReportsApi";

export default function useUserSearchDialog() {
  const setState = useSetRecoilState(userSearchState);
  const reset = useResetRecoilState(userSearchState);

  const openSearchDialog = () => {
    return new Promise<ADUserSearchResult>((resolve, reject) => {
      setState((s) => ({ ...s, isVisible: true, confirm: resolve, cancel: reject }));
    })
      .catch(() => null)
      .finally(reset);
  };

  return openSearchDialog;
}
