import {
  DatePicker,
  IButtonStyles,
  IDatePickerProps,
  IStyleFunctionOrObject,
  ITextFieldStyleProps,
  ITextFieldStyles,
  IconButton,
} from "@fluentui/react";
import { isDate } from "date-fns";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { ClearableFieldProps } from "../../model/clearableFieldProps";
import { HookFormProps } from "../../model/hookFormProps";
import { formatDate, removeTimezoneOffset } from "../../utilities";
import { cancelIcon } from "../Icons";

const datePickerTextStyles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = {
  suffix: {
    background: "transparent",
  },
};

const clearButtonStyles: IButtonStyles = {
  root: {
    color: "rgb(96, 94, 92)",
    "&:hover": {
      fontWeight: 800,
    },
  },
  iconHovered: { fontWeight: 800 },
  rootHovered: {
    color: "rgb(96, 94, 92)",
    background: "transparent",
  },
  rootPressed: {
    background: "transparent",
  },
};

export const ControlledDatePicker: FC<HookFormProps & IDatePickerProps & ClearableFieldProps> = (props) => {
  const renderClearButton = (value: any, onChange: (...event: any[]) => any) =>
    value !== undefined && value !== null && props.showClear !== false ? (
      <IconButton
        className="mr-5"
        iconProps={cancelIcon}
        disabled={props.disabled}
        onClick={() => onChange(props.defaultValue ?? null)}
        styles={clearButtonStyles}
      />
    ) : null;

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <DatePicker
          {...props}
          textField={{
            name: fieldName,
            errorMessage: error && error.message,
            inputClassName: "pr-4",
            onRenderSuffix: () => renderClearButton(value, onChange),
            styles: datePickerTextStyles,
          }}
          onSelectDate={(date) => {
            if (isDate(date) && date) {
              onChange(removeTimezoneOffset(date));
            } else {
              onChange(date);
            }
          }}
          value={value}
          onBlur={onBlur}
          defaultValue={undefined}
          formatDate={formatDate}
        />
      )}
    />
  );
};
