import styled from "styled-components";
import PageHeader from "../components/PageHeader";
import { useTranslator } from "../state/translatorState";
import { Icon } from "@fluentui/react";
import theme from "../../app/theme";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

const ErrorBox = styled.div`
  margin-bottom: 10rem;
  width: 50%;
  height: 5rem;
  padding: 1rem;

  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.1);
`;

const ErrorScreen = () => {
  const t = useTranslator();

  return (
    <Container>
      <ErrorBox>
        <div className="flex flex-row gap-x-4">
          <Icon iconName="incidenttriangle" style={{ fontSize: "2rem", color: theme.palette.themePrimary }} />
          <div className="flex flex-col gap-y-2">
            <PageHeader>{t.ErrorPage.UnknownError.Header}</PageHeader>
            <p>{t.ErrorPage.UnknownError.Content}</p>
          </div>
        </div>
      </ErrorBox>
    </Container>
  );
};

export default ErrorScreen;
