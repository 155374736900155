import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, IModalProps, PrimaryButton } from "@fluentui/react";
import { useMemo } from "react";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";

const modalProps: IModalProps = {
  isBlocking: true,
};

export default function ConfirmationDialog() {
  const { title, body, isOpen, cancel, confirm, confirmButtonText, cancelButtonText, hasCancel } = useConfirmationDialog();
  const dialogProps = useMemo<IDialogContentProps>(
    () => ({ type: DialogType.normal, title: title, isMultiline: true, showCloseButton: false, titleProps: { className: "pb-0" } }),
    [title]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog hidden={!isOpen} onDismiss={cancel} dialogContentProps={dialogProps} modalProps={modalProps}>
      <p className="mb-8">{body}</p>
      <DialogFooter>
        <PrimaryButton onClick={confirm} text={confirmButtonText} />
        {hasCancel && <DefaultButton onClick={cancel} text={cancelButtonText} />}
      </DialogFooter>
    </Dialog>
  );
}
