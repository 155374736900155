import { DetailsListLayoutMode, IColumn, IconButton, SelectionMode } from "@fluentui/react";
import ExtendedShimmeredDetailsList from "../../../common/components/ExtendedShimmeredDetailsList";
import { ApproverJurisdictionInfoDTO } from "../../../services/contactReportsApi";
import { useCallback, useMemo } from "react";
import { useTranslator } from "../../../common/state/translatorState";
import { deleteIcon } from "../../../common/components/Icons";
import { useConfirmationDialog } from "../../../common/hooks/useConfirmationDialog";
import { ApproverListRole, useApproverListRoles } from "../userHooks";
import UserRoleContextCell from "./UserRoleContextCell";
import NewUserRole from "./NewUserRole";

type Props = {
  roles: ApproverJurisdictionInfoDTO[];
  disabled: boolean;
  canceled: boolean;
  onJurisdictionAdding: (isAdding: boolean) => void;
  onJurisdictionAdded: (jurisdiction: ApproverJurisdictionInfoDTO) => any;
  onJurisdictionRemoved: (jurisdiction: ApproverJurisdictionInfoDTO) => any;
  onAdministratorChanged: (isAdministrator: boolean) => any;
} & React.HTMLAttributes<HTMLDivElement>;

const UserRoleList = ({ roles, disabled, canceled: canceled, onJurisdictionAdding, onJurisdictionAdded, onJurisdictionRemoved, onAdministratorChanged, ...divProps }: Props) => {
  const t = useTranslator();
  const { isConfirmed } = useConfirmationDialog();
  const listRoles = useApproverListRoles(roles);

  const handleRoleDeleteClick = useCallback(
    async (role: ApproverListRole) => {
      if (
        await isConfirmed(
          t.SystemUserDetail.ConfirmationDialog.DeleteRole.Title,
          t.SystemUserDetail.ConfirmationDialog.DeleteRole.Description
        )
      ) {
        if (Array.isArray(roles)) {
          const foundJurisdiction = roles.find((j) => j.location?.id === role.location?.id && j.organisation?.id === role.organization?.id);
          if (foundJurisdiction) {
            onJurisdictionRemoved(foundJurisdiction);
          }
        }
      }
    },
    [t, roles, isConfirmed, onJurisdictionRemoved]
  );

  const columns: IColumn[] = useMemo(
    (): IColumn[] => [
      {
        key: "roleName",
        fieldName: "roleName",
        name: t.SystemUserDetail.GridColumn.SystemRole.Label,
        minWidth: 200,
        isSorted: false,
        headerClassName: "non-sortable",
      },
      {
        key: "organization",
        name: t.SystemUserDetail.GridColumn.Organisation.Label,
        minWidth: 200,
        maxWidth: 200,
        onRender: (item: ApproverListRole) => <UserRoleContextCell context={item.organization} />,
        isSorted: false,
        headerClassName: "non-sortable",
      },
      {
        key: "location",
        name: t.SystemUserDetail.GridColumn.Location.Label,
        minWidth: 200,
        maxWidth: 200,
        onRender: (item: ApproverListRole) => <UserRoleContextCell context={item.location} />,
        isSorted: false,
        headerClassName: "non-sortable",
      },
      {
        key: "action",
        name: t.SystemUserDetail.GridColumn.Action.Label,
        minWidth: 60,
        maxWidth: 60,
        onRender: (item: ApproverListRole) => (
          <IconButton iconProps={deleteIcon} onClick={() => handleRoleDeleteClick(item)} disabled={disabled} />
        ),
        isSorted: false,
        headerClassName: "non-sortable",
      },
    ],
    [t, disabled, handleRoleDeleteClick]
  );

  return (
    <div {...divProps}>
      <ExtendedShimmeredDetailsList
        items={listRoles}
        compact={true}
        columns={columns}
        selectionMode={SelectionMode.none}
        getKey={(item: ApproverListRole) => item.key}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        noDataText={t.SystemUserDetail.NoApproverRoles.Text}
        onRenderDetailsFooter={() => <NewUserRole onRoleAdding={onJurisdictionAdding} onRoleAdded={onJurisdictionAdded} disabled={disabled} canceled={canceled} />}
      />
    </div>
  );
};

export default UserRoleList;
