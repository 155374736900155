import { z } from "zod";
import { useMemo } from "react";
import { useTranslator } from "../../common/state/translatorState";

export const LocationTypeSchema = z.union([z.literal("region"), z.literal("subRegion"), z.literal("country")]);

export function useLocationSchema() {
  const t = useTranslator();

  return useMemo(() => {
    return z.object({
      id: z.number().optional(),
      parentId: z.number().optional(),
      name: z
        .string()
        .trim()
        .nonempty(t.RegionCreateUpdateDTO.Region.Field.IsRequired)
        .max(500, t.RegionCreateUpdateDTO.Region.Field.MaxLengthExceeded("", "500")),
      type: LocationTypeSchema,
      isEdit: z.boolean(),
    });
  }, [t]);
}

export type LocationType = z.infer<typeof LocationTypeSchema>;
export type Location = z.infer<ReturnType<typeof useLocationSchema>>;
