import { Toggle, IToggleProps } from "@fluentui/react";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { HookFormProps } from "../../model/hookFormProps";

export const ControlledToggle: FC<HookFormProps & IToggleProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || false}
      render={({ field: { onChange, onBlur, value } }) => (
        <Toggle
          {...props}
          checked={value}
          onChange={(_, checked) => {
            if (checked !== undefined) {
              onChange(checked);
            }
          }}
          onBlur={onBlur}
          defaultValue={undefined}
        />
      )}
    />
  );
};
