import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "react-query";
import { ContactTypeCreateUpdateDTO, ContactTypeDetailDTO, ContactTypeInfoDTO, ContactTypesClient } from "../../services/contactReportsApi";
import useErrorHandler from "../../common/hooks/useErrorHandler";
import useToastNotification from "../../common/hooks/useToastNotification";
import { translationQueryKeys } from "../../common/queries/translationQueries";

const contactTypesClient = new ContactTypesClient();

export const contactTypesQueryKeys = {
  contactTypes: ["contactTypes"] as const,
  active: () => [...contactTypesQueryKeys.contactTypes, "active"] as const,
  all: () => [...contactTypesQueryKeys.contactTypes, "all"] as const,
  detail: (id: number) => [...contactTypesQueryKeys.all(), id] as const,
};

export const useActiveContactTypesQuery = (
  options?: Omit<
    UseQueryOptions<ContactTypeInfoDTO[], unknown, ContactTypeInfoDTO[], readonly ["contactTypes", "active"]>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(contactTypesQueryKeys.active(), () => contactTypesClient.getAllActiveKeywords(), options);
};

export const useAllContactTypesQuery = (
  options?: Omit<
    UseQueryOptions<ContactTypeDetailDTO[], unknown, ContactTypeDetailDTO[], readonly ["contactTypes", "all"]>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(contactTypesQueryKeys.all(), () => contactTypesClient.getAll(), options);
};

export const useContactTypeDetailQuery = (
  id: number,
  options?: Omit<
    UseQueryOptions<ContactTypeDetailDTO, unknown, ContactTypeDetailDTO, readonly ["contactTypes", "all", number]>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(contactTypesQueryKeys.detail(id), () => contactTypesClient.getById(id), options);
};

export const useCreateContactType = () => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  return useMutation((contactType: ContactTypeCreateUpdateDTO) => contactTypesClient.createContactType(contactType), {
    onSuccess: () => {
      queryClient.invalidateQueries(contactTypesQueryKeys.contactTypes);
      queryClient.invalidateQueries(translationQueryKeys.translations());
    },
    onError: errorHandler,
  });
};

export const useUpdateContactType = () => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  return useMutation(
    (data: { id: number; contactType: ContactTypeCreateUpdateDTO }) => contactTypesClient.updateContactType(data.id, data.contactType),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(contactTypesQueryKeys.contactTypes);
        queryClient.invalidateQueries(translationQueryKeys.translations());
      },
      onError: errorHandler,
    }
  );
};

export const useArchiveContactType = () => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();

  return useMutation((contactTypeId: number) => contactTypesClient.archiveContactType(contactTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries(contactTypesQueryKeys.contactTypes);
      queryClient.invalidateQueries(translationQueryKeys.translations());
    },
    onError: errorHandler,
  });
};

export const useUnArchiveContactType = () => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  return useMutation((contactTypeId: number) => contactTypesClient.unarchiveContactType(contactTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries(contactTypesQueryKeys.contactTypes);
      queryClient.invalidateQueries(translationQueryKeys.translations());
    },
    onError: errorHandler,
  });
};
