import { Outlet, RouteObject } from "react-router-dom";
import ProtectedRoute from "../../common/components/ProtectedRoute";
import { RoleCache } from "../../common/hooks/useAuthorization";
import UserListScreen from "./screens/UserListScreen";
import UserDetailsScreen from "./screens/UserDetailsScreen";
import UserBaseScreen from "./screens/UserBaseScreen";

export const userRoutes: RouteObject[] = [
  {
    id: "users",
    path: "/users",
    element: (
      <ProtectedRoute allowedRoles={RoleCache.AdminArray}>
        <UserBaseScreen />
      </ProtectedRoute>
    ),
    children: [
      {
        id: "userList",
        path: "/users/list",
        element: <UserListScreen />,
      },
      {
        id: "userDetail",
        path: "/users/:id",
        element: <UserDetailsScreen />,
      },
    ],
  },
];
