import { useSetRecoilState } from "recoil";
import {
  ToastNotification,
  ToastNotificationType,
  ToastNotificatonPosition,
  toastNotificationState,
} from "../state/toastNotificationState";

const DEFAULT_DURATION = 3000;
const DEFAULT_POSITION: ToastNotificatonPosition = "top-right";

function useToastNotification() {
  const setState = useSetRecoilState(toastNotificationState);

  const addNotification = (
    title: string,
    type: ToastNotificationType,
    description?: string,
    duration?: number,
    position?: ToastNotificatonPosition
  ) => {
    const actualDuration = duration ?? DEFAULT_DURATION;
    const notification: ToastNotification = {
      title: title,
      description: description,
      duration: actualDuration,
      position: position ?? DEFAULT_POSITION,
      type: type,
    };
    setState((s) => ({
      ...s,
      notifications: [...s.notifications, notification],
    }));

    setTimeout(() => setState((s) => ({ ...s, notifications: s.notifications.filter((n) => n !== notification) })), actualDuration);
  };

  const showSuccess = (title: string, description?: string, duration?: number, position?: ToastNotificatonPosition) =>
    addNotification(title, "success", description, duration, position);

  const showError = (title: string, description?: string, duration?: number, position?: ToastNotificatonPosition) =>
    addNotification(title, "error", description, duration, position);

  return { showSuccess, showError };
}

export type Toast = ReturnType<typeof useToastNotification>;

export default useToastNotification;
