import { zodResolver } from "@hookform/resolvers/zod";
import { ContactReportQueryFilter, ContactReportQueryFilterSchema, ContactReportQueryType } from "../contactReportSchemas";
import { useForm } from "react-hook-form";
import { ControlledDatePicker } from "../../../common/components/form/ControlledDatePicker";
import { downloadFile, nameof } from "../../../common/utilities";
import { useTranslator } from "../../../common/state/translatorState";
import { ControlledDropdown } from "../../../common/components/form/ControlledDropdown";
import { DefaultButton, IDropdownOption, PrimaryButton } from "@fluentui/react";
import { ADUserSearchResult, ContactReportStatus } from "../../../services/contactReportsApi";
import React, { useEffect, useMemo, useRef } from "react";
import { useActiveOrganizationsQuery } from "../../organizations/organizationQueries";
import { toGroupedOrganizationDropdownOptions } from "../../organizations/organizationUtilities";
import { ControlledTextField } from "../../../common/components/form/ControlledTextField";
import { ControlledToggle } from "../../../common/components/form/ControlledToggle";
import FormUserSearchBox, { FormUserSearchBoxRefProps } from "../../../common/components/form/FormUserSearchBox";
import { ControlledCombobox } from "../../../common/components/form/ControlledComboBox";
import { useContactReportQueryFilter } from "../contactReportState";
import usePrevious from "../../../common/hooks/usePrevious";
import { clearFilterIcon, excelIcon } from "../../../common/components/Icons";
import { useDownloadAllContactReports } from "../contactReportQueries";

type Props = {
  type: ContactReportQueryType;
} & React.HTMLAttributes<HTMLDivElement>;

const ContactReportListFilter = ({ type, className, ...otherDivProps }: Props) => {
  const t = useTranslator();
  const userSearchRef = useRef<FormUserSearchBoxRefProps>(null);
  const { data: organizations } = useActiveOrganizationsQuery();
  const { filter, defaultFilter, setFilter } = useContactReportQueryFilter();
  const { mutate: exportAll, isLoading: isExporting } = useDownloadAllContactReports();
  const previousType = usePrevious(type);
  const contactReportStatusDropdownOptions: IDropdownOption[] = useMemo(
    () => [
      {
        key: ContactReportStatus.Pending,
        text: t.EnumValueFullName.ContactReportStatus.Pending,
      },
      {
        key: ContactReportStatus.AdditionalInfoRequested,
        text: t.EnumValueFullName.ContactReportStatus.AdditionalInfoRequested,
      },
      {
        key: ContactReportStatus.ReviewedNotApproved,
        text: t.EnumValueFullName.ContactReportStatus.ReviewedNotApproved,
      },
      {
        key: ContactReportStatus.Approved,
        text: t.EnumValueFullName.ContactReportStatus.Approved,
      },
    ],
    [t]
  );
  const companiesOptions: IDropdownOption[] = useMemo(
    () => (organizations ? toGroupedOrganizationDropdownOptions(organizations) : []),
    [organizations]
  );

  const { control, setValue, watch, reset } = useForm<ContactReportQueryFilter>({
    defaultValues: filter,
    resolver: zodResolver(ContactReportQueryFilterSchema),
  });

  const handleUserSelectionChanged = (user?: ADUserSearchResult) => {
    setValue("attendee", user ? user : null, { shouldTouch: true });
  };

  const handleExportAll = () => {
    exportAll(undefined, {
      onSuccess: (data) => {
        downloadFile(data.data, data.fileName ?? "contact-reports.csv");
      },
    });
  };

  const clearFilters = () => {
    reset(ContactReportQueryFilterSchema.parse({}));
    if (userSearchRef.current) {
      userSearchRef.current.clearInput();
    }
  };

  useEffect(() => {
    //type of filter has changed so we reset filter values
    if (previousType && type !== previousType) {
      reset(ContactReportQueryFilterSchema.parse({}));
    }
  }, [type, previousType, defaultFilter, reset]);

  useEffect(() => {
    const subscription = watch((newFilter) => {
      setFilter(newFilter as ContactReportQueryFilter);
    });
    return () => subscription.unsubscribe();
  }, [setFilter, watch]);

  return (
    <div className={"grid grid-cols-12 grid-rows-12 gap-x-4 gap-y-2 w-full " + className} {...otherDivProps}>
      <div className="col-span-12  md:col-span-4">
        <ControlledDatePicker
          control={control}
          name={nameof<ContactReportQueryFilter>("meetingDateFrom")}
          label={t.ContactReportsList.Filter.MeetingDateFrom.Label}
        />
      </div>
      <div className="col-span-12 md:col-span-4">
        <ControlledDatePicker
          control={control}
          name={nameof<ContactReportQueryFilter>("meetingDateTo")}
          label={t.ContactReportsList.Filter.MeetingDateTo.Label}
          showCloseButton
        />
      </div>

      {(type === "my" || type === "to-be-reviewed") && <div className="col-span-12 md:col-span-4" />}

      {type !== "to-be-reviewed" && (
        <div className="col-span-12 md:col-span-4">
          <ControlledDropdown
            control={control}
            name={nameof<ContactReportQueryFilter>("status")}
            options={contactReportStatusDropdownOptions}
            label={t.ContactReportsList.Filter.Status.Label}
            defaultValue={undefined}
          />
        </div>
      )}

      {type !== "my" && (
        <div className="col-span-12 lg:col-span-4">
          <FormUserSearchBox
            ref={userSearchRef}
            label={t.ContactReportsList.Filter.Attendee.Label}
            onChange={handleUserSelectionChanged}
            selectedUser={filter.attendee}
          />
        </div>
      )}

      <div className="col-span-12 lg:col-span-4">
        <ControlledCombobox
          control={control}
          name={nameof<ContactReportQueryFilter>("attendeeCompanyId")}
          options={companiesOptions}
          label={t.ContactReportsList.Filter.AttendeeCompany.Label}
          allowFreeInput
          autoComplete="on"
          defaultValue={undefined}
        />
      </div>

      <div className="col-span-12 lg:col-span-4">
        <ControlledToggle
          control={control}
          name={nameof<ContactReportQueryFilter>("hasAttachment")}
          label={t.ContactReportsList.Filter.HasAttachment.Label}
        />
      </div>

      <div className="col-span-12 lg:col-span-6 xl:col-span-8">
        <ControlledTextField
          control={control}
          name={nameof<ContactReportQueryFilter>("search")}
          label={t.ContactReportsList.Filter.FullTextSearch.Label}
        />
      </div>

      <div className="col-span-12 lg:col-span-6 xl:col-span-4">
        <div className="flex flex-row items-end justify-between">
          <ControlledToggle
            control={control}
            name={nameof<ContactReportQueryFilter>("includingAttachments")}
            label={t.ContactReportsList.Filter.IncludingAttachments.Label}
          />

          <div className="flex flex-col gap-2">
            <DefaultButton iconProps={clearFilterIcon} disabled={isExporting} onClick={clearFilters}>
              {t.ContactReportsList.Button.Clear.Label}
            </DefaultButton>
            {type === "all" && (
              <PrimaryButton iconProps={excelIcon} disabled={isExporting} onClick={handleExportAll}>
                {t.ContactReportsList.Button.Export.Label}
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactReportListFilter;
