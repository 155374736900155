import { ProgressIndicator, Stack } from "@fluentui/react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import ConfirmationDialog from "./common/components/dialog/ConfirmationDialog";
import Navbar from "./common/components/Navbar";
import NavigationMenu from "./common/components/NavigationMenu";
import useInitialization from "./common/hooks/useInitialization";
import { useTranslator } from "./common/state/translatorState";
import ToastNotificationList from "./common/components/toastNotification/ToastNotificationList";
import UserSearchDialog from "./common/components/dialog/UserSearchDialog";
import ErrorDialog from "./common/components/dialog/ErrorDialog";
import useTranslatedWindowTitle from "./common/hooks/useTranslatedWindowTitle";
import ErrorScreen from "./common/screens/ErrorScreen";
import { useEffect } from "react";

const Container = styled.div`
  height: calc(100% - 4.25rem);
`;

const InitializationProgressContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 10rem;

  & .ms-ProgressIndicator {
    width: 70%;
  }
`;

const OutletContainer = styled.div`
  width: 100%;
  background-color: rgb(231 231 231);
  padding: 1rem;
  height: calc(100% - 1rem);
  overflow: auto;

  @media print {
    overflow: initial;
  }
`;

const OutletPanel = styled.div`
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;

  @media not all and (min-width: 640px) {
    width: calc(100% - 2rem);
  }

  @media (min-width: 640px) {
    max-width: 1000px;
  }
`;

function App() {
  const { isInitializing, succeeded, failed } = useInitialization();
  const t = useTranslator();
  useTranslatedWindowTitle();

  useEffect(() => {
    const loader = document.querySelector('.sc-splash-screen');
    if(loader && isInitializing)
      loader.classList.add('sc-splash-screen--hide');
  }, [isInitializing]);

  return (
    <div style={{ backgroundColor: succeeded || failed ? "rgb(231 231 231)" : "#ffffff", height: "100%" }}>
      {isInitializing && (
        <InitializationProgressContainer>
          <ProgressIndicator label={t.Layout.Initialization.Title} description={t.Layout.Initialization.SubTitle} />
        </InitializationProgressContainer>
      )}
      {succeeded && (
        <>
          <Navbar />
          <Container>
            <Stack horizontal className="h-full">
              <NavigationMenu />
              <OutletContainer>
                <OutletPanel>
                  <Outlet />
                </OutletPanel>
              </OutletContainer>
            </Stack>
          </Container>
        </>
      )}
      {failed && <ErrorScreen />}
      <ConfirmationDialog />
      <UserSearchDialog />
      <ErrorDialog />
      <ToastNotificationList />
    </div>
  );
}

export default App;
