import { Outlet, RouteObject } from "react-router-dom";
import ProtectedRoute from "../../common/components/ProtectedRoute";
import NewContactTypeScreen from "./screens/NewContactTypeScreen";
import UpdateContactTypeScreen from "./screens/UpdateContactTypeScreen";
import ContactTypesListScreen from "./screens/ContactTypeListScreen";
import { RoleCache } from "../../common/hooks/useAuthorization";

export const contactTypesRoutes: RouteObject[] = [
  {
    id: "contactTypes",
    path: "/contact-types",
    element: (
      <ProtectedRoute allowedRoles={RoleCache.AdminArray}>
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        id: "contactTypeList",
        path: "/contact-types",
        element: <ContactTypesListScreen />,
      },
      {
        id: "contactTypeDetail",
        path: "/contact-types/:id",
        element: <UpdateContactTypeScreen />,
      },
      {
        id: "contactTypeNew",
        path: "/contact-types/new",
        element: <NewContactTypeScreen />,
      },
    ],
  },
];
