import { Translator, useTranslator } from "../../common/state/translatorState";
import { ContactSystemType, ContactTypeInfoDTO } from "../../services/contactReportsApi";

export function useContactSystemTypeName() {
  const t = useTranslator();
  return (type: ContactSystemType) => {
    switch (type) {
      case ContactSystemType.InPerson:
        return t.EnumValueFullName.ContactSystemType.InPerson;
      case ContactSystemType.Remote:
        return t.EnumValueFullName.ContactSystemType.Remote;
      default:
        return "";
    }
  };
}

export function useContactTypeName() {
  const t = useTranslator();
  return (contactType: ContactTypeInfoDTO) => {
    return t.getTranslatedValue("ContactType", `ContactType.${contactType.id}.Name`, contactType.name);
  };
}