import { ITextFieldProps } from "@fluentui/react";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { HookFormProps } from "../../model/hookFormProps";
import { RequiredFieldProps } from "../../model/requiredFieldProps";
import FormRequiredLabel from "./FormRequiredLabel";
import FormTextField from "./FormTextField";

export const ControlledTextField: FC<HookFormProps & Omit<ITextFieldProps, "theme" | "as" | "required"> & RequiredFieldProps> = ({required, ...props}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <FormTextField
          {...props}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          name={fieldName}
          errorMessage={error && error.message}
          defaultValue={undefined}
          onRenderLabel={
            props.onRenderLabel
              ? props.onRenderLabel
              : (textFieldProps) => <FormRequiredLabel label={textFieldProps?.label} required={required} />
          }
        />
      )}
    />
  );
};
