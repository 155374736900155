import EditDialog from "../../../common/components/dialog/EditDialog";
import { useTranslator } from "../../../common/state/translatorState";
import { DivisionDetailDTO } from "../../../services/contactReportsApi";
import { Organization, OrganizationType } from "../organizationSchemas";
import OrganizationEditForm from "./OrganizationEditForm";

type Props = {
  isOpen: boolean;
  onCancel: () => any;
  organization: Organization;
  parents?: DivisionDetailDTO[];
};

export default function OrganizationEditDialog({ isOpen, organization, parents, onCancel }: Props) {
  const t = useTranslator();
  const isEdit = organization && typeof organization.id === "number" && organization.id > 0;

  const resolveTitle = (type: OrganizationType) => {
    switch (type) {
      case "division":
        return isEdit ? t.OrganisationsEditor.EditDialog.EditDivision.Title : t.OrganisationsEditor.EditDialog.CreateDivision.Title;
      case "company":
        return isEdit ? t.OrganisationsEditor.EditDialog.EditCompany.Title : t.OrganisationsEditor.EditDialog.CreateCompany.Title;
      default:
        return "";
    }
  };

  return (
    <EditDialog isOpen={isOpen} title={resolveTitle(organization.type)} onCancel={onCancel}>
      <OrganizationEditForm organization={organization} parents={parents} onCancel={onCancel} />
    </EditDialog>
  );
}
