import { useSetRecoilState } from "recoil";
import { errorDialogState } from "../state/errorDialogState";
import { ApiException } from "../../services/contactReportsApi";
import { ProblemDetailsError, ProblemDetailsSchema } from "../model/problemDetailsSchema";
import useToastNotification from "./useToastNotification";
import { Translator, useTranslator } from "../state/translatorState";

type ExtractedErrors = {
  title: string;
  errors: string[];
};

const isProblemDetailsError = (error: unknown): error is ProblemDetailsError => ProblemDetailsSchema.safeParse(error).success;

const extractUnknownGenericErrors = (error: unknown) => {
  const result: string[] = [];

  if (typeof error === "string") {
    result.push(error);
  } else if (Array.isArray(error)) {
    for (const item of error) {
      if (typeof item === "string") {
        result.push(item);
      }
    }
  } else if (typeof error === "object") {
    const anyError = error as any;
    if (typeof anyError.error === "string") {
      result.push(anyError.error);
    } else if (Array.isArray(anyError.errors)) {
      for (const item of anyError.errors) {
        if (typeof item === "string") {
          result.push(item);
        }
      }
    }
  }

  return result;
};

const extractProblemDetails = (problemDetails: ProblemDetailsError): ExtractedErrors => {
  const result: ExtractedErrors = { errors: [], title: "" };
  result.title = problemDetails.title;
  for (const key of Object.keys(problemDetails.errors)) {
    if (Array.isArray(problemDetails.errors[key]) && problemDetails.errors[key].length > 0) {
      result.errors.push(...problemDetails.errors[key]);
    }
  }

  return result;
};

const extractErrors = (error: unknown, t: Translator): ExtractedErrors => {
  const result: ExtractedErrors = { errors: [], title: "" };

  if (ApiException.isApiException(error)) {
    if (isProblemDetailsError(error.result)) {
      return extractProblemDetails(error.result);
    } else if (error.status === 404) {
      result.title = t.GenericErrors.NotFound;
    } else if (error.status === 403 || error.status === 401) {
      result.title = t.GenericErrors.Unauthorized;
    } else {
      result.title = t.GenericErrors.UnexpectedError;
    }
  } else if (isProblemDetailsError(error)) {
    return extractProblemDetails(error);
  } else {
    result.title = t.GenericErrors.UnexpectedError;
    result.errors.push(...extractUnknownGenericErrors(error));
  }

  return result;
};

export function useErrorDialog() {
  const setErrorState = useSetRecoilState(errorDialogState);
  const showErrors = (title: string, errors: string[]) => {
    setErrorState((s) => ({ ...s, isVisible: true, title, errors }));
  };

  return {
    showErrors,
  };
}

export default function useErrorHandler() {
  const t = useTranslator();
  const toast = useToastNotification();
  const setErrorState = useSetRecoilState(errorDialogState);

  return (error: unknown) => {
    const extractedErrors = extractErrors(error, t);
    if (extractedErrors.title.length > 0 && extractedErrors.errors.length > 0) {
      setErrorState((s) => ({ ...s, isVisible: true, errors: extractedErrors.errors, title: extractedErrors.title }));
    } else if (extractedErrors.title.length > 0) {
      toast.showError(extractedErrors.title);
    }
  };
}
