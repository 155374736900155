import { useNavigate } from "react-router-dom";
import useNumberRouteParams from "../../../common/hooks/useNumberRouteParams";
import { useContactTypeDetailQuery, useUpdateContactType } from "../contactTypeQueries";
import ContactTypeDetailForm from "../components/ContactTypeForm";
import { ContactTypeCreateUpdateDTO, ContactTypeTranslationDTO } from "../../../services/contactReportsApi";
import { useDefaultLanguageQuery, useLanguagesQuery } from "../../../common/queries/languageQueries";
import PageHeader from "../../../common/components/PageHeader";
import { useTranslator } from "../../../common/state/translatorState";
import useToastNotification from "../../../common/hooks/useToastNotification";

export default function UpdateContactTypeScreen() {
  const { id } = useNumberRouteParams("id");
  const t = useTranslator();
  const navigate = useNavigate();
  const toast = useToastNotification();
  const { mutate: updateContactType, isLoading: isUpdating } = useUpdateContactType();

  const { data: existingContactType } = useContactTypeDetailQuery(id ?? 0, {
    enabled: Number.isInteger(id),
    onError: () => {
      navigate("/contact-types", { replace: true });
    },
  });
  const { data: languages } = useLanguagesQuery();
  const { data: defaultLanguage } = useDefaultLanguageQuery();

  if (!Number.isInteger(id)) {
    navigate("/contact-types", { replace: true });
    return null;
  }

  if (!existingContactType || !languages || !defaultLanguage) {
    //TODO: return skeleton form?
    return null;
  }

  const onCancel = () => navigate(-1);
  const onSubmit = (item: ContactTypeCreateUpdateDTO) => {
    item.nameTranslations = item.nameTranslations.filter((t) => t.text && t.text.length > 0);
    item.nameTranslations.push({ languageId: defaultLanguage.id, text: item.name } as ContactTypeTranslationDTO);
    if (id !== undefined) {
      updateContactType({ id, contactType: item }, { 
        onSuccess: () => { 
          navigate(-1);
          toast.showSuccess(t.Components.ToastMessage.RecordUpdated.Title);
        }
      });
    }
  };
  const mapToCreateUpdateDTO = (): ContactTypeCreateUpdateDTO => {
    const nameTranslations: ContactTypeTranslationDTO[] = [];
    for (const language of languages.sort((a, b) => a.defaultName.localeCompare(b.defaultName))) {
      if (language.id === defaultLanguage.id) {
        continue;
      }
      const foundExisting = existingContactType.nameTranslations.find((t) => t.language.id === language.id);
      nameTranslations.push({ languageId: language.id, text: foundExisting ? foundExisting.text : "" });
    }
    return {
      name: existingContactType.name,
      contactSystemType: existingContactType.contactSystemType,
      nameTranslations,
    };
  };

  return (
    <>
      <PageHeader className="mb-2">{t.ContactTypesList.Header.ContactTypes.Title}</PageHeader>
      <ContactTypeDetailForm
        contactType={mapToCreateUpdateDTO()}
        languages={languages}
        defaultLanguage={defaultLanguage}
        onSubmit={onSubmit}
        onCancel={onCancel}
        disabled={isUpdating}
        editable={existingContactType.archivedBy ? false : true}
      />
    </>
  );
}
