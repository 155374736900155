import { ZodType, z } from "zod";
import { QueryMetadata } from "../../common/hooks/useQueryMetadata";
import { ApproverJurisdictionInfoDTO, SystemRole, SystemUserListQueryDTO } from "../../services/contactReportsApi";
import HierarchyInfoSchema from "../../common/model/hierarchyInfoSchema";

export const UserQueryFilterSchema = z.object({
  firstName: z.string().default(""),
  lastName: z.string().default(""),
  systemRole: z.nativeEnum(SystemRole).nullable().default(null),
});

const ApproverJurisdictionSchema: ZodType<ApproverJurisdictionInfoDTO> = z.object({
  location: HierarchyInfoSchema.optional().nullable(),
  organisation: HierarchyInfoSchema.optional().nullable(),
});

export const UserFormSchema = z.object({
  isAdministrator: z.boolean(),
  approverJurisdictions: z.array(ApproverJurisdictionSchema),
});

export type UserQueryFilter = z.infer<typeof UserQueryFilterSchema>;
export type UserQueryMetadata = QueryMetadata<SystemUserListQueryDTO, UserQueryFilter>;
export type UserFormType = z.infer<typeof UserFormSchema>;
