import { DefaultButton, PrimaryButton } from "@fluentui/react";
import FormSectionHeader from "../../../common/components/form/FormSectionHeader";
import { useTranslator } from "../../../common/state/translatorState";
import { ApproverJurisdictionInfoDTO, SystemRole, SystemUserDetailDTO } from "../../../services/contactReportsApi";
import DisplayValue from "../../contactReports/components/DisplayValue";
import UserRoleList from "./UserRoleList";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { hasRoleFlag } from "../../../common/hooks/useAuthorization";
import { zodResolver } from "@hookform/resolvers/zod";
import { UserFormSchema, UserFormType } from "../userSchemas";
import { areJurisdictionsEqual } from "../userUtilities";
import { ControlledToggle } from "../../../common/components/form/ControlledToggle";
import { nameof } from "../../../common/utilities";
import { useUpdateUser } from "../userQueries";
import useErrorHandler from "../../../common/hooks/useErrorHandler";
import useToastNotification from "../../../common/hooks/useToastNotification";

const createInitialFormValues = (user: SystemUserDetailDTO): UserFormType => ({
  isAdministrator: hasRoleFlag(user.systemRoles, SystemRole.Administrator),
  approverJurisdictions: user.approverJurisdictions ?? [],
});

type Props = {
  user: SystemUserDetailDTO;
};

const UserForm = ({ user }: Props) => {
  const t = useTranslator();
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();
  const toast = useToastNotification();
  const { mutate: updateUser, isLoading } = useUpdateUser();
  const [canceled, setCanceled] = useState(false);
  const [jurisdictionAdding, setJurisdictionAdding] = useState(false);

  const { getValues, setValue, watch, reset, control, formState, handleSubmit } = useForm<UserFormType>({
    defaultValues: createInitialFormValues(user),
    resolver: zodResolver(UserFormSchema),
  });

  const handleJurisdictionAdding = useCallback((isAdding: boolean) => {
   setJurisdictionAdding(isAdding);
  }, [] );

  const handleJurisdictionAdded = useCallback(
    (jurisdiction: ApproverJurisdictionInfoDTO) => {
      //no need to validate here
      setValue("approverJurisdictions", [...getValues("approverJurisdictions"), jurisdiction], { shouldDirty: true, shouldTouch: true });
    },
    [setValue, getValues]
  );

  const handleJurisdictionRemoved = useCallback(
    (jurisdiction: ApproverJurisdictionInfoDTO) => {
      const filtered = getValues("approverJurisdictions").filter((j) => !areJurisdictionsEqual(jurisdiction, j));
      setValue("approverJurisdictions", filtered, { shouldDirty: true, shouldTouch: true });
    },
    [setValue, getValues]
  );

  const handleAdministratorChanged = useCallback(
    (isAdministrator: boolean) => {
      setValue("isAdministrator", isAdministrator);
    },
    [setValue]
  );

  const handleSaveUserClick = handleSubmit((form) => {
    setCanceled(true);
    updateUser(
      {
        userId: user.id,
        update: {
          isAdmin: form.isAdministrator,
          approverJurisdictions: form.approverJurisdictions.map((j) => ({
            locationId: j.location?.id,
            organisationId: j.organisation?.id,
          })),
        },
      },
      {
        onSuccess: (newUser) => {
          toast.showSuccess(t.Components.ToastMessage.RecordUpdated.Title);
          reset(createInitialFormValues(newUser));
          setCanceled(false);
          navigate(-1);
        },
        onError: errorHandler,
      }
    );
  });

  const handleCancel = () => {
    setCanceled(true); 
    navigate(-1);
    setCanceled(false); 
  }

  return (
    <div className="flex flex-col flex-1 mt-4">
      <FormSectionHeader>{t.SystemUserDetail.Section.UserDetails.Title}</FormSectionHeader>
      <div className="grid grid-cols-12 grid-rows-12 gap-x-4">
        <div className="col-span-12 md:col-span-4">
          <DisplayValue label={t.SystemUserDetail.Field.LastName.Label}>{user.lastName}</DisplayValue>
        </div>
        <div className="col-span-12 md:col-span-4">
          <DisplayValue label={t.SystemUserDetail.Field.FirstName.Label}>{user.firstName}</DisplayValue>
        </div>
        <div className="col-span-12 md:col-span-4">
          <DisplayValue label={t.SystemUserDetail.Field.Email.Label}>{user.email}</DisplayValue>
        </div>
      </div>
      <FormSectionHeader className="mt-4">{t.SystemUserDetail.Section.Roles.Title}</FormSectionHeader>
      <div>
        <ControlledToggle
          control={control}
          name={nameof<UserFormType>("isAdministrator")}
          label={t.SystemUserDetail.Field.Administrator.Label}
          className="flex flex-row items-center gap-4"
          disabled={isLoading}
        />
      </div>
      <UserRoleList
        roles={watch("approverJurisdictions")}
        disabled={isLoading}
        canceled={canceled}
        className="flex flex-1 flex-col"
        onJurisdictionAdding={handleJurisdictionAdding}
        onJurisdictionAdded={handleJurisdictionAdded}
        onJurisdictionRemoved={handleJurisdictionRemoved}
        onAdministratorChanged={handleAdministratorChanged}
      />

      <div className="flex flex-row justify-end align-center gap-4 mt-4">
        {formState.isDirty && (
          <PrimaryButton onClick={handleSaveUserClick} disabled={isLoading || jurisdictionAdding}>
            {t.Components.Button.Save.Label}
          </PrimaryButton>
        )}
        <DefaultButton onClick={handleCancel} disabled={isLoading}>
          {t.Components.Button.Cancel.Label}
        </DefaultButton>
      </div>
    </div>
  );
};

export default UserForm;
