import { useRecoilValue } from "recoil";
import PageHeader from "../../../common/components/PageHeader";
import { useTranslator } from "../../../common/state/translatorState";
import UserList from "../components/UserList";
import UserListFilter from "../components/UserListFilter";
import { userQueryMetadataState } from "../userState";

const UserListScreen = () => {
  const t = useTranslator();
  const metadata = useRecoilValue(userQueryMetadataState);

  return (
    <>
      <PageHeader className="mb-2">{t.SystemUsersList.Header.SystemUsers.Title}</PageHeader>
      <UserListFilter className="mb-4" />
      <UserList metadata={metadata} />
    </>
  );
};

export default UserListScreen;
