import { DetailsRow, IColumn, IDetailsRowProps, IconButton, SelectionMode } from "@fluentui/react";
import { useTranslator } from "../../../common/state/translatorState";
import { useCallback, useMemo } from "react";
import { ContactReportListQueryDTO, QueryResultMetaDataOfContactReportListQueryDTO } from "../../../services/contactReportsApi";
import { formatDate, nameof } from "../../../common/utilities";
import { useContactReportStatusName } from "../contactReportHooks";
import { searchIcon } from "../../../common/components/Icons";
import { useNavigate } from "react-router-dom";
import { useContactReportListQuery } from "../contactReportQueries";
import { ContactReportQueryMetadata } from "../contactReportSchemas";
import styled from "styled-components";
import { useDebouncedValue } from "../../../common/hooks/useDebounce";
import useAuthorization from "../../../common/hooks/useAuthorization";
import ExtendedShimmeredDetailsList from "../../../common/components/ExtendedShimmeredDetailsList";
import { useContactReportQuerySort } from "../contactReportState";
import ContactReportFullTextResult from "./ContactReportFullTextResult";
import theme from "../../../app/theme";
import useThrottle from "../../../common/hooks/useThrottle";

const RowWithHighlightsContainer = styled.div`
  &:hover {
    background-color: ${theme.semanticColors.listItemBackgroundHovered};

    div[role="row"] {
      background-color: ${theme.semanticColors.listItemBackgroundHovered};
    }
  }
`;

type Props = {
  metadata: ContactReportQueryMetadata;
};

const ContactReportList = ({ metadata }: Props) => {
  const t = useTranslator();
  const contactReportStatusName = useContactReportStatusName();
  const navigate = useNavigate();
  const { features } = useAuthorization();
  const { toggleFieldSort, isSortedDescending, isSortedByField } = useContactReportQuerySort();

  const {
    data: contactReports,
    isFetching,
    isFetched,
    fetchNextPage,
    hasNextPage,
  } = useContactReportListQuery(useDebouncedValue(metadata, 250));

  const onDetailButtonClick = useCallback(
    (contactReport: ContactReportListQueryDTO) => {
      if (features.contactReports.canEdit(contactReport)) {
        navigate(`/contact-reports/edit/${contactReport.id}`);
      } else {
        navigate(`/contact-reports/${contactReport.id}`);
      }
    },
    [navigate, features]
  );

  const columns = useMemo(
    (): IColumn[] => [
      {
        key: "refNumber",
        name: t.ContactReportsList.GridColumn.ReferenceNumber.Label,
        minWidth: 80,
        fieldName: nameof<ContactReportListQueryDTO>("referenceNumber"),
        onColumnClick: (_, column) => toggleFieldSort(column.fieldName ?? ""),
        isSorted: isSortedByField("referenceNumber"),
        isSortedDescending: isSortedDescending("referenceNumber"),
      },
      {
        key: "status",
        name: t.ContactReportsList.GridColumn.ContactReportStatus.Label,
        minWidth: 75,
        onRender: (item: ContactReportListQueryDTO) => (
          <span title={contactReportStatusName(item.contactReportStatus)}>{contactReportStatusName(item.contactReportStatus, true)}</span>
        ),
        fieldName: nameof<ContactReportListQueryDTO>("contactReportStatus"),
        onColumnClick: (_, column) => toggleFieldSort(column.fieldName ?? ""),
        isSorted: isSortedByField("contactReportStatus"),
        isSortedDescending: isSortedDescending("contactReportStatus"),
      },
      {
        key: "company",
        name: t.ContactReportsList.GridColumn.AttendeeCompany.Label,
        minWidth: 100,
        onRender: (item: ContactReportListQueryDTO) => <span>{item.attendeeCompany.name}</span>,
        fieldName: nameof<ContactReportListQueryDTO>("attendeeCompany.name"),
        onColumnClick: (_, column) => toggleFieldSort(column.fieldName ?? ""),
        isSorted: isSortedByField("attendeeCompany.name"),
        isSortedDescending: isSortedDescending("attendeeCompany.name"),
      },
      {
        key: "meetingDate",
        name: t.ContactReportsList.GridColumn.MeetingDate.Label,
        minWidth: 100,
        onRender: (item: ContactReportListQueryDTO) => <span>{formatDate(item.meetingDate)}</span>,
        fieldName: nameof<ContactReportListQueryDTO>("meetingDate"),
        onColumnClick: (_, column) => toggleFieldSort(column.fieldName ?? ""),
        isSorted: isSortedByField("meetingDate"),
        isSortedDescending: isSortedDescending("meetingDate"),
      },
      {
        key: "subject",
        name: t.ContactReportsList.GridColumn.Subject.Label,
        minWidth: 160,
        fieldName: nameof<ContactReportListQueryDTO>("subject"),
        isSorted: false,
        headerClassName: "non-sortable",
      },
      {
        key: "submitter",
        name: t.ContactReportsList.GridColumn.CreatedBy.Label,
        minWidth: 120,
        onRender: (item: ContactReportListQueryDTO) => <span>{item.createdBy.displayName}</span>,
        fieldName: nameof<ContactReportListQueryDTO>("createdBy.displayName"),
        onColumnClick: (_, column) => toggleFieldSort(column.fieldName ?? ""),
        isSorted: isSortedByField("createdBy.displayName"),
        isSortedDescending: isSortedDescending("createdBy.displayName"),
      },
      {
        key: "submitted",
        name: t.ContactReportsList.GridColumn.CreatedOn.Label,
        minWidth: 100,
        onRender: (item: ContactReportListQueryDTO) => <span>{formatDate(item.createdOn)}</span>,
        fieldName: nameof<ContactReportListQueryDTO>("createdOn"),
        onColumnClick: (_, column) => toggleFieldSort(column.fieldName ?? ""),
        isSorted: isSortedByField("createdOn"),
        isSortedDescending: isSortedDescending("createdOn"),
      },
      {
        key: "action",
        name: t.ContactReportsList.GridColumn.Action.Label,
        minWidth: 60,
        maxWidth: 60,
        onRender: (item: ContactReportListQueryDTO) => <IconButton iconProps={searchIcon} onClick={() => onDetailButtonClick(item)} />,
        isSorted: false,
        headerClassName: "non-sortable",
      },
    ],
    [t, isSortedDescending, toggleFieldSort, isSortedByField, contactReportStatusName, onDetailButtonClick]
  );

  const renderRow = (rowProps?: IDetailsRowProps): JSX.Element | null => {
    if (!rowProps) {
      return null;
    }

    const contactReport = rowProps.item as ContactReportListQueryDTO;
    if (!contactReport || !contactReport.fullTextSearchResult) {
      return <DetailsRow {...rowProps} />;
    }

    return (
      <RowWithHighlightsContainer>
        <DetailsRow {...rowProps} />
        <ContactReportFullTextResult contactReportId={contactReport.id} fullTextResult={contactReport.fullTextSearchResult} />
      </RowWithHighlightsContainer>
    );
  };

  const handleScrolledToBottom = useThrottle(
    () => {
      if (hasNextPage) {
        fetchNextPage();
      }
    },
    250,
    [hasNextPage, fetchNextPage]
  );

  const totalRows = contactReports && contactReports.pages.length > 0 ? contactReports.pages[0].metaData?.totalRowCount ?? 0 : 0;
  const listItems = contactReports ? contactReports.pages.flat().flatMap((x) => x.data ?? []) : [];
  return (
    <>
      <ExtendedShimmeredDetailsList
        columns={columns}
        items={listItems}
        compact
        selectionMode={SelectionMode.none}
        enableShimmer={isFetching && listItems.length === 0}
        onRenderRow={renderRow}
        onScrolledToBottom={handleScrolledToBottom}
        noDataText={isFetched && !isFetching ? t.ContactReportsList.List.NoContactReports : ""}
        totalRowCount={totalRows}
      />
    </>
  );
};

export default ContactReportList;
