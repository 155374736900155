import { IIconProps, Icon } from "@fluentui/react";
import styled from "styled-components";

const Container = styled.div`
  align-items: center;
  display: inline-flex;
  border-radius: 16px;
  padding: 0.25rem 0.5rem;
`;

const ChipText = styled.span``;

type Props = {
  backgroundColor?: string;
  textColor?: string;
  text?: string;
  icon?: string;
  IconComponent?: React.FunctionComponent<IIconProps>;
};

const Chip = ({ text, icon, IconComponent, textColor, backgroundColor }: Props) => {
  return (
    <Container style={{ color: textColor ?? "#323130", backgroundColor: backgroundColor ?? "#edebe9" }}>
      {icon && typeof icon === "string" && icon.length > 0 && <Icon iconName={icon} />}
      {IconComponent && <IconComponent />}
      {text && text.length > 0 && <ChipText>{text}</ChipText>}
    </Container>
  );
};

export default Chip;
