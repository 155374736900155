import styled from "styled-components";
import { ToastNotification as ToastNotificationType } from "../../state/toastNotificationState";
import theme from "../../../app/theme";

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  background: #fff;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  padding: 0.75rem;
  width: 20rem;
  min-height: 1.5rem;
  max-height: 3rem;
  border-radius: 3px;
  box-shadow: 0 0 6px #999;
  color: #000;
  opacity: 0.97;
  background-position: 15px;
  background-repeat: no-repeat;

  &.top-right,
  &.bottom-right {
    border-left-width: 4px;
    border-left-style: solid;
  }

  &.bottom-left,
  &.top-left {
    border-right-width: 4px;
    border-right-style: solid;
  }

  &.error {
    border-color: ${theme.palette.red};
    background-color: ${theme.semanticColors.errorBackground};
  }

  &.success {
    border-color: ${theme.palette.green};
    background-color: ${theme.semanticColors.successBackground};
  }
`;

const Title = styled.div`
  font-size: 0.95rem;
  font-weight: 400;
`;

const Description = styled.div`
  font-size: 0.7rem;
  margin-top: 0.25rem;
  max-height: 1.5rem;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

type Props = {
  notification: ToastNotificationType;
};

const ToastNotification = ({ notification }: Props) => {
  return (
    <NotificationContainer className={`toast-notification ${notification.position} ${notification.type}`}>
      <Title>{notification.title}</Title>
      {notification.description && notification.description.length > 0 && <Description>{notification.description}</Description>}
    </NotificationContainer>
  );
};

export default ToastNotification;
