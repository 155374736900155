import { useForm } from "react-hook-form";
import { useTranslator } from "../../../common/state/translatorState";
import { UserQueryFilter, UserQueryFilterSchema } from "../userSchemas";
import { useUserQueryFilter } from "../userState";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledTextField } from "../../../common/components/form/ControlledTextField";
import { nameof } from "../../../common/utilities";
import { ControlledDropdown } from "../../../common/components/form/ControlledDropdown";
import { IDropdownOption, PrimaryButton } from "@fluentui/react";
import { useEffect, useMemo } from "react";
import { SystemRole } from "../../../services/contactReportsApi";
import useUserSearchDialog from "../../../common/hooks/useUserSearchDialog";
import { useAddUserFromAd } from "../userQueries";
import useErrorHandler from "../../../common/hooks/useErrorHandler";
import { useNavigate } from "react-router-dom";

type Props = {} & React.HTMLAttributes<HTMLDivElement>;

const UserListFilter = ({ className, ...otherDivProps }: Props) => {
  const t = useTranslator();
  const userSearchDialog = useUserSearchDialog();
  const errorHandler = useErrorHandler();
  const navigate = useNavigate();

  const { mutate: addUserFromAd } = useAddUserFromAd();
  const { filter, setFilter } = useUserQueryFilter();
  const { control, watch } = useForm<UserQueryFilter>({
    defaultValues: filter,
    resolver: zodResolver(UserQueryFilterSchema),
  });

  const userRolesDropdownOptions: IDropdownOption[] = useMemo(
    () => [
      {
        key: SystemRole.Submitter,
        text: t.EnumValueFullName.SystemRole.Submitter,
      },
      {
        key: SystemRole.Approver,
        text: t.EnumValueFullName.SystemRole.Approver,
      },
      {
        key: SystemRole.Administrator,
        text: t.EnumValueFullName.SystemRole.Administrator,
      },
    ],
    [t]
  );

  useEffect(() => {
    const subscription = watch((newFilter) => {
      setFilter(newFilter as UserQueryFilter);
    });
    return () => subscription.unsubscribe();
  }, [setFilter, watch]);

  const handleAddFromAdClick = async () => {
    const user = await userSearchDialog();
    if (user) {
      addUserFromAd(
        { uniqueId: user.uniqueId },
        {
          onSuccess: (data) => {
            navigate("/users/" + data.id);
          },
          onError: errorHandler,
        }
      );
    }
  };

  return (
    <div className={"grid grid-cols-12 grid-rows-12 gap-x-4 gap-y-2 w-full " + className} {...otherDivProps}>
      <div className="col-span-12  md:col-span-3">
        <ControlledTextField control={control} name={nameof<UserQueryFilter>("lastName")} label={t.SystemUsersList.Filter.LastName.Label} />
      </div>
      <div className="col-span-12  md:col-span-3">
        <ControlledTextField
          control={control}
          name={nameof<UserQueryFilter>("firstName")}
          label={t.SystemUsersList.Filter.FirstName.Label}
        />
      </div>
      <div className="col-span-12  md:col-span-3">
        <ControlledDropdown
          control={control}
          name={nameof<UserQueryFilter>("systemRole")}
          label={t.SystemUsersList.Filter.SystemRole.Label}
          options={userRolesDropdownOptions}
          defaultValue={undefined}
        />
      </div>
      <div className="col-span-12  md:col-span-3 flex flex-row justify-end items-end">
        <PrimaryButton onClick={handleAddFromAdClick}>{t.SystemUsersList.Button.AddSystemUser.Label}</PrimaryButton>
      </div>
    </div>
  );
};

export default UserListFilter;
