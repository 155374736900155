import { ITextFieldProps, TextField } from "@fluentui/react";
import styled from "styled-components";
import theme from "../../../app/theme";

const StyledTextField = styled(TextField)`
  input[type="text"]:read-only {
    cursor: default;
    background-color: ${theme.semanticColors.disabledBackground};
  }
  textarea:read-only {
    cursor: default;
    background-color: ${theme.semanticColors.disabledBackground};
  }
`;

type Props = Omit<ITextFieldProps, "theme" | "as">;

const FormTextField = (props: Props) => <StyledTextField {...props} />;

export default FormTextField;
