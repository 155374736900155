import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useResetContactReportQueryFilter } from "../contactReportState";

//base page without UI just to handle some state between various contact report pages
const ContactReportBaseScreen = () => {
  const resetFilterState = useResetContactReportQueryFilter();

  useEffect(() => {
    //reset filter state when we leave contact reports
    return () => {
      resetFilterState();
    };
  }, [resetFilterState]);

  return <Outlet />;
};

export default ContactReportBaseScreen;
