import EditDialog from "../../../common/components/dialog/EditDialog";
import { useTranslator } from "../../../common/state/translatorState";
import { RegionDetailDTO, SubRegionDetailDTO } from "../../../services/contactReportsApi";
import { Location, LocationType } from "../locationSchemas";
import LocationEditForm from "./LocationEditForm";

type Props = {
  isOpen: boolean;
  onCancel: () => any;
  location: Location;
  parents?: RegionDetailDTO[] | SubRegionDetailDTO[];
};

export default function LoactionEditDialog({ isOpen, location, parents, onCancel }: Props) {
  const t = useTranslator();
  const isEdit = location && typeof location.id === "number" && location.id > 0;

  const resolveTitle = (type: LocationType) => {
    const editDialog = t.LocationsEditor.EditDialog;
    switch (type) {
      case "region":
        return isEdit ? editDialog.EditRegion.Title : editDialog.CreateRegion.Title;
      case "subRegion":
        return isEdit ? editDialog.EditSubRegion.Title : editDialog.CreateSubRegion.Title;
      case "country":
        return isEdit ? editDialog.EditCountry.Title : editDialog.CreateCountry.Title;
      default:
        return "";
    }
  };

  return (
    <EditDialog isOpen={isOpen} title={resolveTitle(location.type)} onCancel={onCancel}>
      <LocationEditForm location={location} parents={parents} onCancel={onCancel} />
    </EditDialog>
  );
}
