type Props = {
  label: string;
  children: JSX.Element | string | undefined | null;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const formatStringValue = (value: string | undefined | null) => {
  if (value === undefined || value === null || value.length === 0) {
    return <>&nbsp;</>;
  }

  return value;
};

const DisplayValue = ({ label, children, ...divProps }: Props) => (
  <div {...divProps}>
    <p className="m-0 font-semibold text-base form-label">{label}</p>
    {typeof children === "string" && (
      <p className="mt-1 mb-0 font-normal break-all form-value display-linebreak">{formatStringValue(children)}</p>
    )}
    {typeof children !== "string" && children}
  </div>
);

export default DisplayValue;
