import { z } from "zod";

const ProblemDetailsSchema = z.object({
  customType: z.string(),
  errors: z.record(z.string(), z.array(z.string())),
  status: z.number(),
  title: z.string(),
  traceId: z.string(),
  type: z.string(),
});

export type ProblemDetailsError = z.infer<typeof ProblemDetailsSchema>;

export { ProblemDetailsSchema };
