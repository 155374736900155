import {
  DetailsRow,
  GroupedList,
  IColumn,
  IGroup,
  IGroupHeaderProps,
  SelectionMode,
  GroupHeader,
  Stack,
  IconButton,
} from "@fluentui/react";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { addIcon, archivelIcon, editIcon, unArchivelIcon } from "../../../common/components/Icons";
import { CountryDetailDTO, RegionDetailDTO, SubRegionDetailDTO } from "../../../services/contactReportsApi";
import { Location, LocationType } from "../locationSchemas";
import { collectAllSubRegions, findParentId, mapToGroups, mapToItems } from "../locationUtilities";
import { useTranslator } from "../../../common/state/translatorState";

const columns: IColumn[] = [
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 600,
  },
  {
    key: "buttons",
    name: "",
    minWidth: 100,
  },
];

const ItemHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
`;

const GroupName = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 12rem;
`;

type Props = {
  regions: RegionDetailDTO[];
  onCreate: (type: LocationType, parentId?: number) => any;
  onEdit: (location: Location, parents?: RegionDetailDTO[] | SubRegionDetailDTO[]) => any;
  onArchive: (locationId: number) => any;
  onUnArchive: (locationId: number) => any;
};

export default function LocationList({ regions, onCreate, onEdit, onArchive, onUnArchive }: Props) {
  const t = useTranslator();
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});
  const groups = useMemo(() => mapToGroups(regions, expandedGroups), [regions, expandedGroups]);
  const items = useMemo(() => mapToItems(regions), [regions]);

  const onGroupCollapseChanged = (groupKey: string, isCollapsed: boolean) => {
    setExpandedGroups((g) => ({ ...g, [groupKey]: isCollapsed }));
  };

  const onRenderItemColumn = (item?: CountryDetailDTO, _index?: number, column?: IColumn) => {
    if (!item || !column) {
      return null;
    }
    if (column.key === "buttons") {
      const location: Location = {
        id: item.id,
        parentId: findParentId(item.id, regions),
        name: item.name,
        type: "country",
        isEdit: true,
      };
      return (
        <Stack horizontal tokens={{ childrenGap: "0.25rem" }}>
          <IconButton iconProps={editIcon} onClick={() => onEdit(location, collectAllSubRegions(regions))} className="ml-1" />
          <IconButton
            iconProps={item.isActive === true ? archivelIcon : unArchivelIcon}
            onClick={() => (item.isActive === true ? onArchive(item.id) : onUnArchive(item.id))}
            title={item.isActive === true ? t.Components.Button.Archive.Label : t.Components.Button.Unarchive.Label}
          />
        </Stack>
      );
    }

    return <GroupName className={item.isActive === true ? "" : "line-through"}>{item.name}</GroupName>;
  };

  const onRenderCell = (nestingDepth?: number, item?: CountryDetailDTO, itemIndex?: number, group?: IGroup): React.ReactNode => {
    return item && typeof itemIndex === "number" && itemIndex > -1 ? (
      <DetailsRow
        columns={columns}
        groupNestingDepth={nestingDepth}
        item={item}
        itemIndex={itemIndex}
        selectionMode={SelectionMode.none}
        compact={false}
        group={group}
        onRenderItemColumn={onRenderItemColumn}
      />
    ) : null;
  };

  const onGroupRenderTitle = (props: IGroupHeaderProps) => {
    const item = props.group?.data as RegionDetailDTO | SubRegionDetailDTO;
    if (!item) {
      return null;
    }

    const isRegion = props.groupLevel === 0;
    const parentId = isRegion ? undefined : findParentId(item.id, regions);
    const location: Location = { id: item.id, parentId, name: item.name, type: isRegion ? "region" : "subRegion", isEdit: true };
    const childrenCount =
      props.groupLevel === 0 ? props.group?.children?.length ?? 0 : (item as SubRegionDetailDTO)?.countries?.length ?? 0;
    return (
      <ItemHeaderContainer>
        <GroupName className={item.isActive === true ? "" : "line-through"}>
          {item.name} ({childrenCount})
        </GroupName>
        <Stack horizontal tokens={{ childrenGap: "0.25rem" }}>
          <IconButton iconProps={addIcon} onClick={() => onCreate(isRegion ? "subRegion" : "country", item.id)} />
          <IconButton iconProps={editIcon} onClick={() => onEdit(location, isRegion ? undefined : regions)} />
          <IconButton
            iconProps={item.isActive === true ? archivelIcon : unArchivelIcon}
            onClick={() => (item.isActive === true ? onArchive(item.id) : onUnArchive(item.id))}
            title={item.isActive === true ? t.Components.Button.Archive.Label : t.Components.Button.Unarchive.Label}
          />
        </Stack>
      </ItemHeaderContainer>
    );
  };

  const onRenderHeader = (
    onGroupCollapseChanged: (groupKey: string, isCollapsed: boolean) => any,
    props?: IGroupHeaderProps
  ): JSX.Element | null => {
    if (!props || !props.group) {
      return null;
    }
    return (
      <GroupHeader
        {...props}
        onRenderTitle={() => onGroupRenderTitle(props)}
        onToggleCollapse={(e) => onGroupCollapseChanged(e.key ?? "", e.isCollapsed === true)}
      />
    );
  };

  return (
    <GroupedList
      className="w-full"
      items={items}
      onRenderCell={onRenderCell}
      selectionMode={SelectionMode.none}
      groupProps={{ onRenderHeader: (headerProps) => onRenderHeader(onGroupCollapseChanged, headerProps), showEmptyGroups: true }}
      groups={groups}
      compact={true}
    />
  );
}
