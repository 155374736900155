import { InteractionType } from "@azure/msal-browser";
import { useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { useActiveLocationsQuery } from "../../features/locations/locationsQueries";
import { useCurrentUserQuery } from "../../features/users/userQueries";
import { useTranslationsQuery } from "../queries/translationQueries";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { initializationState } from "../state/initializationState";
import { useLanguagesQuery } from "../queries/languageQueries";
import { useActiveContactTypesQuery } from "../../features/contactTypes/contactTypeQueries";
import { useActiveOrganizationsQuery } from "../../features/organizations/organizationQueries";

export default function useInitialization() {
  const { error: loginError } = useMsalAuthentication(InteractionType.Redirect);
  const isAuthenticated = useIsAuthenticated();
  const [state, setState] = useRecoilState(initializationState);

  //we want queries to be disabled until initialization is completed
  //first time they will be run manually here during intiailization
  const { refetch: getCurrentUser } = useCurrentUserQuery({ enabled: state.succeeded });
  const { refetch: getTranslations } = useTranslationsQuery({ enabled: state.succeeded });
  const { refetch: getActiveLocations } = useActiveLocationsQuery({ enabled: state.succeeded });
  const { refetch: getActiveOrganizations } = useActiveOrganizationsQuery({ enabled: state.succeeded });
  const { refetch: getActiveContactTypes } = useActiveContactTypesQuery({ enabled: state.succeeded });
  const { refetch: getLanguages } = useLanguagesQuery({ enabled: state.succeeded });

  const setInitializationFailed = useCallback(() => setState({ isInitializing: false, failed: true, succeeded: false }), [setState]);
  const setInitializationSucceeded = useCallback(() => setState({ isInitializing: false, failed: false, succeeded: true }), [setState]);
  const setInitializing = useCallback(() => setState({ isInitializing: true, failed: false, succeeded: false }), [setState]);

  const runInitialization = useCallback(async () => {
    try {
      console.log("Starting initialization.");
      //load translations first because we need them during initialization screen already
      await getTranslations();
      setInitializing();

      await Promise.all([getCurrentUser(), getActiveLocations(), getActiveOrganizations(), getActiveContactTypes(), getLanguages()]);

      setInitializationSucceeded();
      console.log("Initialization finished.");
    } catch (error) {
      console.error("Initialization failed.", error);
      setInitializationFailed();
    }
  }, []);

  useEffect(() => {
    const hasNotRunYet = !state.failed && !state.succeeded && !state.isInitializing;
    if (hasNotRunYet && isAuthenticated) {
      runInitialization();
    }
  }, [isAuthenticated, state, runInitialization]);

  useEffect(() => {
    if (loginError) {
      setInitializationFailed();
    }
  }, [loginError, setInitializationFailed]);

  return state;
}
