import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "react-query";
import { DivisionDetailDTO, DivisionInfoDTO, OrganisationsClient } from "../../services/contactReportsApi";
import useErrorHandler from "../../common/hooks/useErrorHandler";
import { Organization } from "./organizationSchemas";

const organizationsClient = new OrganisationsClient();

export const organizationsQueryKeys = {
  organizations: ["organizations"] as const,
  active: () => [...organizationsQueryKeys.organizations, "active"] as const,
  all: () => [...organizationsQueryKeys.organizations, "all"] as const,
};

export const useActiveOrganizationsQuery = (
  options?: Omit<
    UseQueryOptions<DivisionInfoDTO[], unknown, DivisionInfoDTO[], readonly ["organizations", "active"]>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(organizationsQueryKeys.active(), () => organizationsClient.getAllActiveKeywords(), options);
};

export const useAllOrganizationsQuery = (
  options?: Omit<
    UseQueryOptions<DivisionDetailDTO[], unknown, DivisionDetailDTO[], readonly ["organizations", "all"]>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(organizationsQueryKeys.all(), () => organizationsClient.getAll(), options);
};

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  return useMutation(
    async (data: { organization: Organization }) => {
      switch (data.organization.type) {
        case "division": {
          await organizationsClient.createDivision({ name: data.organization.name });
          break;
        }
        case "company": {
          await organizationsClient.createCompany({ name: data.organization.name, divisionId: data.organization.parentId ?? 0 });
          break;
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(organizationsQueryKeys.organizations);
      },
      onError: errorHandler,
    }
  );
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  return useMutation(
    async (data: { organization: Organization }) => {
      switch (data.organization.type) {
        case "division": {
          await organizationsClient.updateDivision(data.organization.id ?? 0, { name: data.organization.name });
          break;
        }
        case "company": {
          await organizationsClient.updateCompany(data.organization.id ?? 0, {
            name: data.organization.name,
            divisionId: data.organization.parentId ?? 0,
          });
          break;
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(organizationsQueryKeys.organizations);
      },
      onError: errorHandler,
    }
  );
};

export const useArchiveOrganization = () => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  return useMutation((organizationId: number) => organizationsClient.archiveOrganisation(organizationId), {
    onSuccess: () => {
      queryClient.invalidateQueries(organizationsQueryKeys.organizations);
    },
    onError: errorHandler,
  });
};

export const useUnArchiveOrganization = () => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  return useMutation((organizationId: number) => organizationsClient.unarchiveOrganisation(organizationId), {
    onSuccess: () => {
      queryClient.invalidateQueries(organizationsQueryKeys.organizations);
    },
    onError: errorHandler,
  });
};
