import {
  ConstrainMode,
  IDetailsHeaderProps,
  IRenderFunction,
  IScrollablePaneContext,
  IShimmeredDetailsListProps,
  ScrollablePane,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
} from "@fluentui/react";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import useThrottle from "../hooks/useThrottle";
import { useTranslator } from "../state/translatorState";

//trigger scrolled to bottom when scroll is X% before it reaches the bottom
const PERCENTAGE_SCROLL_TO_BOTTOM_OFFSET = 80;

const FixedHeightContainer = styled.div`
  flex: 1;
  position: relative;
`;

const StyledShimmeredDetailsList = styled(ShimmeredDetailsList)`
  .ms-List-page {
    & div[role="gridcell"] {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
    }
  }

  div[role="columnheader"]:not(.non-sortable) {
    cursor: pointer;
  }
`;

type Props = {
  onScrolledToBottom?: () => any;
  noDataText?: string;
  totalRowCount?: number;
} & Omit<IShimmeredDetailsListProps, "theme" | "as">;

const ExtendedShimmeredDetailsList = ({ onScrolledToBottom, noDataText, totalRowCount, onRenderDetailsFooter, ...listProps }: Props) => {
  const t = useTranslator();
  const stickyRef = useRef<Sticky>(null);
  const bottomScrollHandlerRef = useRef(onScrolledToBottom);

  const renderStickyHeader = (props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>) =>
    !props || !defaultRender ? null : (
      <Sticky ref={stickyRef} stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender({
          ...props,
          styles: { root: { paddingTop: "0px" } },
        })}
      </Sticky>
    );

  const handleOnScroll = useThrottle(
    (element: HTMLElement) => {
      const { scrollTop, scrollHeight, clientHeight } = element;
      if (scrollTop + clientHeight >= scrollHeight * (PERCENTAGE_SCROLL_TO_BOTTOM_OFFSET / 100)) {
        if (bottomScrollHandlerRef.current) {
          bottomScrollHandlerRef.current();
        }
      }
    },
    250,
    []
  );

  useEffect(() => {
    if (stickyRef.current) {
      const stickyRefValue = stickyRef.current;
      const stickyContext = stickyRefValue.context as IScrollablePaneContext;
      if (stickyContext && stickyContext.scrollablePane) {
        stickyContext.scrollablePane.subscribe(handleOnScroll);
      }
      return () => {
        const stickyContext = stickyRefValue.context as IScrollablePaneContext;
        if (stickyContext && stickyContext.scrollablePane) {
          stickyContext.scrollablePane.unsubscribe(handleOnScroll);
        }
      };
    }
  }, [handleOnScroll]);

  useEffect(() => {
    bottomScrollHandlerRef.current = onScrolledToBottom;
  }, [onScrolledToBottom]);

  return (
    <>
      <FixedHeightContainer>
        <ScrollablePane>
          <StyledShimmeredDetailsList
            constrainMode={ConstrainMode.unconstrained}
            onRenderDetailsHeader={renderStickyHeader}
            onRenderDetailsFooter={(footerProps, defaultFooterRender) => (
              <>
                {noDataText && noDataText.length > 0 && (!Array.isArray(listProps.items) || listProps.items.length === 0) && (
                  <p className="m-0 text-center">{noDataText}</p>
                )}
                {onRenderDetailsFooter && onRenderDetailsFooter(footerProps, defaultFooterRender)}
              </>
            )}
            {...listProps}
          />
        </ScrollablePane>
      </FixedHeightContainer>
      {totalRowCount !== undefined && (
        <div className="flex flex-row justify-end items-center">
          <p className="text-sm subpixel-antialiased mb-0 mt-2">
            {t.Components.Grid.TotalRowCount.Label} {totalRowCount ?? 0}
          </p>
        </div>
      )}
    </>
  );
};

export default ExtendedShimmeredDetailsList;
