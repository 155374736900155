import { atom } from "recoil";

const initializationState = atom({
  key: "initialization",
  default: {
    isInitializing: false,
    failed: false,
    succeeded: false,
  },
});

export { initializationState };
