import { useForm } from "react-hook-form";
import { ControlledTextField } from "../../../common/components/form/ControlledTextField";
import FormSectionHeader from "../../../common/components/form/FormSectionHeader";
import { useTranslator } from "../../../common/state/translatorState";
import { formatDate, nameof } from "../../../common/utilities";
import { ContactReportDetailDTO, ContactSystemType } from "../../../services/contactReportsApi";
import ContactReportDetailTexts from "./ContactReportDetailTexts";
import DisplayValue from "./DisplayValue";
import StatusHistory from "./StatusHistory";
import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { useApproveContactReportMutation, useRequestAdditionalInfoMutation, useReviewContactReportMutation } from "../contactReportQueries";
import useToastNotification from "../../../common/hooks/useToastNotification";
import { ReviewerNotes, ReviewerNotesSchema } from "../contactReportSchemas";
import useAuthorization from "../../../common/hooks/useAuthorization";
import { useContactTypeName } from "../../contactTypes/contactTypeHooks";
import useCanGoBack from "../../../common/hooks/useCanGoBack";

type Props = {
  contactReport: ContactReportDetailDTO;
};

const ContactReportDetail = ({ contactReport }: Props) => {
  const t = useTranslator();
  const navigate = useNavigate();
  const canGoBack = useCanGoBack();
  const toast = useToastNotification();
  const contactTypeName = useContactTypeName();
  const {
    roles,
    features: { contactReports: contactReportsFeatures },
  } = useAuthorization();

  const { mutate: requestAdditionalInfo, isLoading: isRequestingAdditionalInfo } = useRequestAdditionalInfoMutation();
  const { mutate: reviewContactReport, isLoading: isReviewing } = useReviewContactReportMutation();
  const { mutate: approveContactReport, isLoading: isApproving } = useApproveContactReportMutation();

  const isBusy = isRequestingAdditionalInfo || isReviewing || isApproving;

  const { control, handleSubmit, setError, clearErrors } = useForm<ReviewerNotes>({
    defaultValues: { reviewerNotes: "" },
    resolver: zodResolver(ReviewerNotesSchema),
  });

  const goBackToList = () => {
    if (canGoBack) {
      navigate(-1);
    } else {
      if (roles.isAdmin || roles.isApprover) {
        navigate("/contact-reports/list/all", { replace: true });
      } else {
        navigate("/contact-reports/list/my", { replace: true });
      }
    }
  };

  const handleRequestAdditionalInfo = handleSubmit((data) => {
    if (!data.reviewerNotes || data.reviewerNotes.trim().length === 0) {
      setError("reviewerNotes", { message: t.ContactReportStatusChangeDTO.ContactReport.Field.IsRequired });
    } else {
      clearErrors("reviewerNotes");
      requestAdditionalInfo(
        { id: contactReport.id, reviewerNote: data.reviewerNotes ?? "" },
        {
          onSuccess: () => {
            toast.showSuccess(t.ContactReportDetail.ToastMessage.RequestedAdditionalInfo.Title);
            goBackToList();
          },
        }
      );
    }
  });

  const handleReviewContactReport = handleSubmit((data) => {
    reviewContactReport(
      { id: contactReport.id, reviewerNote: data.reviewerNotes ?? "" },
      {
        onSuccess: () => {
          toast.showSuccess(t.ContactReportDetail.ToastMessage.ReviewedNotApproved.Title);
          goBackToList();
        },
      }
    );
  });

  const handleApproveContactReport = handleSubmit((data) => {
    approveContactReport(
      { id: contactReport.id, reviewerNote: data.reviewerNotes ?? "" },
      {
        onSuccess: () => {
          toast.showSuccess(t.ContactReportDetail.ToastMessage.Approved.Title);
        },
      }
    );
  });

  return (
    <div>
      <div className="grid grid-cols-12 grid-rows-12 gap-x-4">
        <div className="col-span-12 lg:col-span-6">
          <FormSectionHeader>{t.ContactReportDetail.Section.AttendeeDetails.Title}</FormSectionHeader>
          <div className="grid grid-cols-2">
            <div className="col-span-2 lg:col-span-1">
              <DisplayValue label={t.ContactReportDetail.Field.Attendee.Label}>{contactReport.attendee.displayName ?? ""}</DisplayValue>
              <DisplayValue className="mt-4" label={t.ContactReportDetail.Field.AttendeeCompany.Label}>
                {contactReport.attendeeCompany.name ?? ""}
              </DisplayValue>
            </div>
            <div className="col-span-2 lg:col-span-1">
              <DisplayValue label={t.ContactReportDetail.Field.AttendeeEmail.Label}>{contactReport.attendeeEmail ?? ""}</DisplayValue>
              <DisplayValue className="mt-4" label={t.ContactReportDetail.Field.AttendeePhone.Label}>
                {contactReport.attendeePhone ?? ""}
              </DisplayValue>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6 mt-8 lg:mt-0">
          <FormSectionHeader>{t.ContactReportDetail.Section.StatusHistory.Title}</FormSectionHeader>
          <StatusHistory historyRecords={contactReport.statusHistories ?? []} />
        </div>
      </div>
      <div className="grid grid-cols-12 grid-rows-12 gap-x-4">
        <div className="col-span-12 lg:col-span-3">
          <FormSectionHeader className="mt-8">{t.ContactReportDetail.Section.ContactDetails.Title}</FormSectionHeader>
          <DisplayValue label={t.ContactReportDetail.Field.CompetitorCompanyName.Label}>
            {contactReport.competitorCompanyName ?? ""}
          </DisplayValue>
          <DisplayValue className="mt-4" label={t.ContactReportDetail.Field.ContactType.Label}>
            {contactReport.contactType ? contactTypeName(contactReport.contactType) : ""}
          </DisplayValue>
          <DisplayValue className="mt-4" label={t.ContactReportDetail.Field.MeetingDate.Label}>
            {formatDate(contactReport.meetingDate)}
          </DisplayValue>
          {contactReport.contactType.contactSystemType === ContactSystemType.InPerson && (
            <DisplayValue className="mt-4" label={t.ContactReportDetail.Field.MeetingAddress.Label}>
              {contactReport.meetingAddress ?? ""}
            </DisplayValue>
          )}
          <DisplayValue className="mt-4" label={t.ContactReportDetail.Field.MeetingCountry.Label}>
            {contactReport.meetingCountry?.name ?? ""}
          </DisplayValue>
          <DisplayValue className="mt-4" label={t.ContactReportDetail.Field.Attendees.Label}>
            {contactReport.attendees ?? ""}
          </DisplayValue>
        </div>
        <div className="col-span-12 lg:col-span-9 page-break-before page-break-after">
          <ContactReportDetailTexts
            contactReportId={contactReport.id}
            status={contactReport.translationStatus}
            originalContent={contactReport.originalContent}
            translatedContents={contactReport.translatedContents ?? []}
            className="ml-2"
            style={{ marginTop: "18px" }}
          />
        </div>
      </div>
      <FormSectionHeader className="mt-4">{t.ContactReportDetail.Section.SubmitterDetails.Title}</FormSectionHeader>
      <div className="grid grid-cols-12 grid-rows-12 gap-x-4">
        <div className="col-span-12 lg:col-span-4">
          <DisplayValue label={t.ContactReportDetail.Field.SubmitterName.Label}>{contactReport.createdBy.displayName ?? ""}</DisplayValue>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <DisplayValue label={t.ContactReportDetail.Field.SubmitterEmail.Label}>{contactReport.submitterEmail ?? ""}</DisplayValue>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <DisplayValue label={t.ContactReportDetail.Field.SubmitterPhone.Label}>{contactReport.submitterPhone ?? ""}</DisplayValue>
        </div>
      </div>
      {contactReportsFeatures.canApprove(contactReport) && (
        <>
          <FormSectionHeader className="mt-4">{t.ContactReportDetail.Section.ReviewDetails.Title}</FormSectionHeader>
          <ControlledTextField
            control={control}
            name={nameof<ReviewerNotes>("reviewerNotes")}
            label={t.ContactReportDetail.Field.ReviewerNote.Label}
            multiline
            autoAdjustHeight
            max={2000}
          />
        </>
      )}
      {!contactReportsFeatures.canApprove(contactReport) && (
        <>
          <FormSectionHeader className="mt-4">{t.ContactReportDetail.Section.ReviewDetails.Title}</FormSectionHeader>
          <div className="grid grid-cols-12 grid-rows-12 gap-x-4">
            <div className="col-span-12 lg:col-span-4">
              <DisplayValue label={t.ContactReportDetail.Field.ReviewerName.Label}>{contactReport.reviewedBy?.displayName ?? ""}</DisplayValue>
            </div>
            <div className="col-span-12 lg:col-span-4">
              <DisplayValue label={t.ContactReportDetail.Field.ReviewerEmail.Label}>{contactReport.reviewedBy?.email ?? ""}</DisplayValue>
            </div>
            <div className="col-span-12 lg:col-span-4">
              <DisplayValue label={t.ContactReportDetail.Field.ReviewerPhone.Label}>
                {contactReport.reviewedBy?.mobilePhone ?? ""}
              </DisplayValue>
            </div>
            <div className="col-span-12 mt-4">
              <DisplayValue label={t.ContactReportDetail.Field.ReviewerNote.Label}>{contactReport.reviewerNote}</DisplayValue>
            </div>
          </div>
        </>
      )}
      <FormSectionHeader className="mt-4" />
      <div className="flex flex-row justify-end items-center flex-wrap gap-y-2 gap-2 mt-4 no-print">
        {contactReportsFeatures.canApprove(contactReport) && (
          <div className="flex gap-2 flex-wrap">
            <PrimaryButton disabled={isBusy} onClick={handleReviewContactReport}>
              {t.ContactReportDetail.Button.ReviewedNotApproved.Title}
            </PrimaryButton>
            <PrimaryButton disabled={isBusy} onClick={handleRequestAdditionalInfo}>
              {t.ContactReportDetail.Button.RequestAdditionalInfo.Title}
            </PrimaryButton>
            <PrimaryButton disabled={isBusy} onClick={handleApproveContactReport}>
              {t.ContactReportDetail.Button.Approve.Title}
            </PrimaryButton>
          </div>
        )}
        <div className="flex gap-2 flex-wrap">
          <DefaultButton onClick={() => window.print()}>{t.Components.Button.Print.Label}</DefaultButton>
          <DefaultButton onClick={goBackToList}>{t.Components.Button.Cancel.Label}</DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default ContactReportDetail;
