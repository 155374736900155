import { memo } from "react";
import { Navigate } from "react-router-dom";
import { SystemRole } from "../../services/contactReportsApi";
import useAuthorization from "../hooks/useAuthorization";

type Props = {
  allowedRoles?: SystemRole[];
  forbiddenRoles?: SystemRole[];
  unauthorizedRoute?: string;
  children: JSX.Element;
};

export default memo(({ allowedRoles, forbiddenRoles, unauthorizedRoute, children }: Props) => {
  const { utilities } = useAuthorization();

  if (
    (Array.isArray(allowedRoles) && allowedRoles.length > 0 && !utilities.hasAnyRole(allowedRoles)) ||
    (Array.isArray(forbiddenRoles) && forbiddenRoles.length > 0 && utilities.hasAnyRole(forbiddenRoles))
  ) {
    const redirectTo = unauthorizedRoute ?? "/unauthorized";
    console.warn(`User is not authorized to visit this page, redirecting to: '${redirectTo}'`);
    return <Navigate to={{ pathname: redirectTo }} replace />;
  }

  return children;
});
