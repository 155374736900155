
//  ---------   THIS FILE HAS BEEN AUTOMATICALLY GENERATED DO NOT MANUALLY EDIT IT  ---------   
//              Generated on: 09/26/2023 14:56:12


export interface TranslationProvider {
  getTranslatedValue(resourceGroup: string, resourceKey: string, defaultValue: string, valuesToReplace?: string[]): string;
}

export function createTranslator(provider: TranslationProvider) {
  return {
    getTranslatedValue: (resourceGroup: string, resourceKey: string, defaultValue: string, valuesToReplace?: string[]) =>
      provider.getTranslatedValue(resourceGroup, resourceKey, defaultValue, valuesToReplace),
   	CompanyCreateUpdateDTO: { 
		Company: { 
			Field: { 
MaxLengthExceeded: (__parameter1: string, parameter2: string) => provider.getTranslatedValue(`CompanyCreateUpdateDTO`, `Company.Field.MaxLengthExceeded`, `This field exceeded maximum length of {1} characters`, [__parameter1,parameter2]),IsRequired: provider.getTranslatedValue(`CompanyCreateUpdateDTO`, `Company.Field.IsRequired`, `This field is required`),
						}, 
					}, 
				}, 
	Components: { 
		UserSearchDialog: { 
Title: provider.getTranslatedValue(`Components`, `UserSearchDialog.Title`, `Search User`),			List: { 
NoUsers: provider.getTranslatedValue(`Components`, `UserSearchDialog.List.NoUsers`, `No users found`),
				}, 
			GridColumn: { 
				Name: { 
Label: provider.getTranslatedValue(`Components`, `UserSearchDialog.GridColumn.Name.Label`, `Name`),
					}, 
				LoginName: { 
Label: provider.getTranslatedValue(`Components`, `UserSearchDialog.GridColumn.LoginName.Label`, `Login Name`),
					}, 
				Email: { 
Label: provider.getTranslatedValue(`Components`, `UserSearchDialog.GridColumn.Email.Label`, `Email`),
						}, 
					}, 
			Filter: { 
				Query: { 
Placeholder: provider.getTranslatedValue(`Components`, `UserSearchDialog.Filter.Query.Placeholder`, `Search`),
							}, 
						}, 
					}, 
		ToastMessage: { 
			UnexpectedError: { 
Title: provider.getTranslatedValue(`Components`, `ToastMessage.UnexpectedError.Title`, `Unexpected error has occurred.`),
				}, 
			RecordUpdated: { 
Title: provider.getTranslatedValue(`Components`, `ToastMessage.RecordUpdated.Title`, `Record was successfully updated.`),
				}, 
			RecordUnarchived: { 
Title: provider.getTranslatedValue(`Components`, `ToastMessage.RecordUnarchived.Title`, `Record was successfully un-archived.`),
				}, 
			RecordCreated: { 
Title: provider.getTranslatedValue(`Components`, `ToastMessage.RecordCreated.Title`, `Record was successfully created.`),
				}, 
			RecordArchived: { 
Title: provider.getTranslatedValue(`Components`, `ToastMessage.RecordArchived.Title`, `Record was successfully archived.`),
				}, 
			ErrorLoadingData: { 
Title: provider.getTranslatedValue(`Components`, `ToastMessage.ErrorLoadingData.Title`, `Error occurred while loading data.`),
				}, 
			ErrorDataNotFound: { 
Title: provider.getTranslatedValue(`Components`, `ToastMessage.ErrorDataNotFound.Title`, `Could not find requested record. Please refresh the page and try again.`),
					}, 
				}, 
		ProgressIndicator: { 
			LoadingData: { 
Label: provider.getTranslatedValue(`Components`, `ProgressIndicator.LoadingData.Label`, `Loading data...`),
					}, 
				}, 
		Grid: { 
			TotalRowCount: { 
Label: provider.getTranslatedValue(`Components`, `Grid.TotalRowCount.Label`, `Total rows:`),
					}, 
				}, 
		ErrorDialog: { 
			ValidationError: { 
Title: provider.getTranslatedValue(`Components`, `ErrorDialog.ValidationError.Title`, `Validation Error`),
					}, 
				}, 
		DropDownItem: { 
PleaseChoose: provider.getTranslatedValue(`Components`, `DropDownItem.PleaseChoose`, `Please choose`),Any: provider.getTranslatedValue(`Components`, `DropDownItem.Any`, `Any`),
			}, 
		ConfirmationDialog: { 
			Unarchive: { 
Title: provider.getTranslatedValue(`Components`, `ConfirmationDialog.Unarchive.Title`, `Un-archive record`),Description: provider.getTranslatedValue(`Components`, `ConfirmationDialog.Unarchive.Description`, `Are you sure you want to un-archive this record?`),
				}, 
			Successful: { 
Title: provider.getTranslatedValue(`Components`, `ConfirmationDialog.Successful.Title`, `Success`),
				}, 
			Archive: { 
Title: provider.getTranslatedValue(`Components`, `ConfirmationDialog.Archive.Title`, `Archive record`),Description: provider.getTranslatedValue(`Components`, `ConfirmationDialog.Archive.Description`, `Are you sure you want to archive this record?`),
					}, 
				}, 
		Button: { 
			Yes: { 
Label: provider.getTranslatedValue(`Components`, `Button.Yes.Label`, `Yes`),
				}, 
			Unarchive: { 
Label: provider.getTranslatedValue(`Components`, `Button.Unarchive.Label`, `Unarchive`),
				}, 
			Submit: { 
Label: provider.getTranslatedValue(`Components`, `Button.Submit.Label`, `Submit`),
				}, 
			ShowMore: { 
Label: provider.getTranslatedValue(`Components`, `Button.ShowMore.Label`, `Show More`),
				}, 
			Save: { 
Label: provider.getTranslatedValue(`Components`, `Button.Save.Label`, `Save`),
				}, 
			Print: { 
Label: provider.getTranslatedValue(`Components`, `Button.Print.Label`, `Print`),
				}, 
			Ok: { 
Label: provider.getTranslatedValue(`Components`, `Button.Ok.Label`, `Ok`),
				}, 
			No: { 
Label: provider.getTranslatedValue(`Components`, `Button.No.Label`, `No`),
				}, 
			Confirm: { 
Label: provider.getTranslatedValue(`Components`, `Button.Confirm.Label`, `Confirm`),
				}, 
			Cancel: { 
Label: provider.getTranslatedValue(`Components`, `Button.Cancel.Label`, `Cancel`),
				}, 
			Archive: { 
Label: provider.getTranslatedValue(`Components`, `Button.Archive.Label`, `Archive`),
						}, 
					}, 
				}, 
	ContactReportCreateUpdateDTO: { 
		ContactReport: { 
			MeetingAddress: { 
IsRequired: provider.getTranslatedValue(`ContactReportCreateUpdateDTO`, `ContactReport.MeetingAddress.IsRequired`, `Meeting address is required when in-person contact type is selected`),
				}, 
			Field: { 
MaxLengthExceeded: (__parameter1: string, parameter2: string) => provider.getTranslatedValue(`ContactReportCreateUpdateDTO`, `ContactReport.Field.MaxLengthExceeded`, `This field exceeded maximum length of {1} characters`, [__parameter1,parameter2]),IsRequired: provider.getTranslatedValue(`ContactReportCreateUpdateDTO`, `ContactReport.Field.IsRequired`, `This field is required`),InvalidEmail: provider.getTranslatedValue(`ContactReportCreateUpdateDTO`, `ContactReport.Field.InvalidEmail`, `Invalid email address`),
						}, 
					}, 
				}, 
	ContactReportDetail: { 
		ToastMessage: { 
			Submitted: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `ToastMessage.Submitted.Title`, `Contact report has been submitted.`),
				}, 
			ReviewedNotApproved: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `ToastMessage.ReviewedNotApproved.Title`, `Contact report has been reviewed.`),
				}, 
			RequestedAdditionalInfo: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `ToastMessage.RequestedAdditionalInfo.Title`, `More info has been requested.`),
				}, 
			Rejected: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `ToastMessage.Rejected.Title`, `Contact report has been rejected.`),
				}, 
			Approved: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `ToastMessage.Approved.Title`, `Contact report has been approved.`),
					}, 
				}, 
		Section: { 
			SubmitterDetails: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Section.SubmitterDetails.Title`, `Submitter Details`),
				}, 
			StatusHistory: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Section.StatusHistory.Title`, `Status History`),
				}, 
			ReviewDetails: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Section.ReviewDetails.Title`, `Review Details`),
				}, 
			ContactDetails: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Section.ContactDetails.Title`, `Contact Details`),
				}, 
			AttendeeDetails: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Section.AttendeeDetails.Title`, `Attendee Details`),
					}, 
				}, 
		Header: { 
			ContactReportReviewedNotApproved: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Header.ContactReportReviewedNotApproved.Title`, `Contact Report Reviewed Not Approved`),
				}, 
			ContactReportPending: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Header.ContactReportPending.Title`, `Contact Report Review`),
				}, 
			ContactReportNew: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Header.ContactReportNew.Title`, `New Contact Report`),
				}, 
			ContactReportApproved: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Header.ContactReportApproved.Title`, `Contact Report Approved`),
				}, 
			ContactReportAdditionalInfoRequested: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Header.ContactReportAdditionalInfoRequested.Title`, `Contact Report Additional Info Requested`),
					}, 
				}, 
		Field: { 
			Summary: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.Summary.Label`, `Summarised Contact Details`),
				}, 
			SubmitterPhone: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.SubmitterPhone.Label`, `Submitter Phone Number`),
				}, 
			SubmitterName: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.SubmitterName.Label`, `Submitter Name`),
				}, 
			SubmitterEmail: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.SubmitterEmail.Label`, `Submitter Email Address`),
				}, 
			Subject: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.Subject.Label`, `Subject`),
				}, 
			ReviewerPhone: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.ReviewerPhone.Label`, `Reviewer Phone Number`),
				}, 
			ReviewerNote: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.ReviewerNote.Label`, `Reviewer Note`),
				}, 
			ReviewerName: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.ReviewerName.Label`, `Reviewer Name`),
				}, 
			ReviewerEmail: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.ReviewerEmail.Label`, `Reviewer Email`),
				}, 
			ReviewedOn: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.ReviewedOn.Label`, `Reviewed`),
				}, 
			ReferenceNumber: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.ReferenceNumber.Label`, `Reference Number`),
				}, 
			MeetingDate: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.MeetingDate.Label`, `Meeting Date`),
				}, 
			MeetingCountry: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.MeetingCountry.Label`, `Meeting Location Country`),
				}, 
			MeetingAddress: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.MeetingAddress.Label`, `Meeting Location Address`),
				}, 
			CreatedOn: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.CreatedOn.Label`, `Submitted`),
				}, 
			CreatedBy: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.CreatedBy.Label`, `Submitter`),
				}, 
			ContactType: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.ContactType.Label`, `Contact Type`),
				}, 
			ContactReportStatus: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.ContactReportStatus.Label`, `Status`),
				}, 
			CompetitorCompanyName: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.CompetitorCompanyName.Label`, `Competitor Company Name`),
				}, 
			Attendees: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.Attendees.Label`, `Attendees`),
				}, 
			AttendeePhone: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.AttendeePhone.Label`, `Attendee Phone Number`),
				}, 
			AttendeeEmail: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.AttendeeEmail.Label`, `Attendee Email Address`),
				}, 
			AttendeeCompany: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.AttendeeCompany.Label`, `Attendee Company`),
				}, 
			Attendee: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.Attendee.Label`, `Attendee Name`),
				}, 
			Attachment: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Field.Attachment.Label`, `Attachment`),
					}, 
				}, 
		Dialog: { 
			Submitted: { 
Body: provider.getTranslatedValue(`ContactReportDetail`, `Dialog.Submitted.Body`, `Your Contact Report has been submitted successfully.`),
					}, 
				}, 
		ContentTab: { 
			Translating: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `ContentTab.Translating.Title`, `Translating...`),
				}, 
			Original: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `ContentTab.Original.Title`, `Original`),
					}, 
				}, 
		Button: { 
			Upload: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Button.Upload.Title`, `Upload`),
				}, 
			Submit: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Button.Submit.Title`, `Submit Report for Review`),
				}, 
			ReviewedNotApproved: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Button.ReviewedNotApproved.Title`, `Reviewed Not Approved`),
				}, 
			RequestAdditionalInfo: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Button.RequestAdditionalInfo.Title`, `Request Additional Info`),
				}, 
			Remove: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Button.Remove.Title`, `Remove`),
				}, 
			Print: { 
Label: provider.getTranslatedValue(`ContactReportDetail`, `Button.Print.Label`, `Print`),
				}, 
			OnBehalfOf: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Button.OnBehalfOf.Title`, `On Behalf Of`),
				}, 
			Approve: { 
Title: provider.getTranslatedValue(`ContactReportDetail`, `Button.Approve.Title`, `Approve`),
						}, 
					}, 
				}, 
	ContactReportsList: { 
		List: { 
NoContactReports: provider.getTranslatedValue(`ContactReportsList`, `List.NoContactReports`, `No contact reports found`),
			}, 
		Header: { 
			ToBeReviewed: { 
Title: provider.getTranslatedValue(`ContactReportsList`, `Header.ToBeReviewed.Title`, `Contact Reports - To be Reviewed`),
				}, 
			OnBehalfOf: { 
Title: provider.getTranslatedValue(`ContactReportsList`, `Header.OnBehalfOf.Title`, `Contact Reports - On Behalf Of`),
				}, 
			MyReports: { 
Title: provider.getTranslatedValue(`ContactReportsList`, `Header.MyReports.Title`, `Contact Reports - My Reports`),
				}, 
			AllReports: { 
Title: provider.getTranslatedValue(`ContactReportsList`, `Header.AllReports.Title`, `Contact Reports - All Reports`),
					}, 
				}, 
		GridRow: { 
			TranslatedSummary: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridRow.TranslatedSummary.Label`, `Translated Summary`),
				}, 
			TranslatedSubject: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridRow.TranslatedSubject.Label`, `Translated Subject`),
				}, 
			TranslatedAttachement: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridRow.TranslatedAttachement.Label`, `Translated Attachment`),
				}, 
			OriginalSummary: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridRow.OriginalSummary.Label`, `Original Summary`),
				}, 
			OriginalSubject: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridRow.OriginalSubject.Label`, `Original Subject`),
				}, 
			OriginalAttachement: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridRow.OriginalAttachement.Label`, `Original Attachment`),
					}, 
				}, 
		GridColumn: { 
			Subject: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridColumn.Subject.Label`, `Subject`),
				}, 
			ReferenceNumber: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridColumn.ReferenceNumber.Label`, `Ref. Number`),
				}, 
			MeetingDate: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridColumn.MeetingDate.Label`, `Meeting Date`),
				}, 
			CreatedOn: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridColumn.CreatedOn.Label`, `Submitted`),
				}, 
			CreatedBy: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridColumn.CreatedBy.Label`, `Submitter`),
				}, 
			ContactReportStatus: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridColumn.ContactReportStatus.Label`, `Status`),
				}, 
			AttendeeCompany: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridColumn.AttendeeCompany.Label`, `Company`),
				}, 
			Attendee: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridColumn.Attendee.Label`, `Attendee`),
				}, 
			Action: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `GridColumn.Action.Label`, `Action`),
					}, 
				}, 
		Filter: { 
			Status: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `Filter.Status.Label`, `Status`),
				}, 
			MeetingDateTo: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `Filter.MeetingDateTo.Label`, `Meeting Date to`),
				}, 
			MeetingDateFrom: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `Filter.MeetingDateFrom.Label`, `Meeting Date from`),
				}, 
			IncludingAttachments: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `Filter.IncludingAttachments.Label`, `Search in Attachments`),
				}, 
			HasAttachment: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `Filter.HasAttachment.Label`, `Has Attachment`),
				}, 
			FullTextSearch: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `Filter.FullTextSearch.Label`, `Full-Text Search`),
				}, 
			AttendeeCompany: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `Filter.AttendeeCompany.Label`, `Attendee Company`),
				}, 
			Attendee: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `Filter.Attendee.Label`, `Attendee`),
					}, 
				}, 
		Button: { 
			Export: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `Button.Export.Label`, `Export`),
				}, 
			CreateNew: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `Button.CreateNew.Label`, `Create a New Report`),
				}, 
			Clear: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `Button.Clear.Label`, `Clear`),
				}, 
			ApplyFilter: { 
Label: provider.getTranslatedValue(`ContactReportsList`, `Button.ApplyFilter.Label`, `Apply Filter`),
						}, 
					}, 
				}, 
	ContactReportStatusChangeDTO: { 
		ContactReport: { 
			Field: { 
MaxLengthExceeded: (__parameter1: string, parameter2: string) => provider.getTranslatedValue(`ContactReportStatusChangeDTO`, `ContactReport.Field.MaxLengthExceeded`, `This field exceeded maximum length of {1} characters`, [__parameter1,parameter2]),IsRequired: provider.getTranslatedValue(`ContactReportStatusChangeDTO`, `ContactReport.Field.IsRequired`, `This field is required`),
						}, 
					}, 
				}, 
	ContactTypeCreateUpdateDTO: { 
		ContactType: { 
			Field: { 
MaxLengthExceeded: (__parameter1: string, parameter2: string) => provider.getTranslatedValue(`ContactTypeCreateUpdateDTO`, `ContactType.Field.MaxLengthExceeded`, `This field exceeded maximum length of {1} characters`, [__parameter1,parameter2]),IsRequired: provider.getTranslatedValue(`ContactTypeCreateUpdateDTO`, `ContactType.Field.IsRequired`, `This field is required`),
				}, 
			ContactSystemType: { 
EnumNotSupported: provider.getTranslatedValue(`ContactTypeCreateUpdateDTO`, `ContactType.ContactSystemType.EnumNotSupported`, `Contact type System type is not supported`),
						}, 
					}, 
				}, 
	ContactTypeDetail: { 
		NameTranslations: { 
Title: provider.getTranslatedValue(`ContactTypeDetail`, `NameTranslations.Title`, `Translations`),
			}, 
		Header: { 
			ContactTypeNew: { 
Title: provider.getTranslatedValue(`ContactTypeDetail`, `Header.ContactTypeNew.Title`, `New Contact Type`),
				}, 
			ContactTypeEdit: { 
Title: (parameter1: string) => provider.getTranslatedValue(`ContactTypeDetail`, `Header.ContactTypeEdit.Title`, `Contact Type {0}`, [parameter1]),
					}, 
				}, 
		Field: { 
			Name: { 
Label: provider.getTranslatedValue(`ContactTypeDetail`, `Field.Name.Label`, `Name`),
				}, 
			ContactSystemType: { 
Label: provider.getTranslatedValue(`ContactTypeDetail`, `Field.ContactSystemType.Label`, `Type`),
						}, 
					}, 
				}, 
	ContactTypesList: { 
		Header: { 
			ContactTypes: { 
Title: provider.getTranslatedValue(`ContactTypesList`, `Header.ContactTypes.Title`, `Contact Types`),
				}, 
			Button: { 
Add: provider.getTranslatedValue(`ContactTypesList`, `Header.Button.Add`, `Add Contact Type`),
					}, 
				}, 
		GridColumn: { 
			Name: { 
Label: provider.getTranslatedValue(`ContactTypesList`, `GridColumn.Name.Label`, `Name`),
				}, 
			ContactSystemType: { 
Label: provider.getTranslatedValue(`ContactTypesList`, `GridColumn.ContactSystemType.Label`, `Type`),
				}, 
			Action: { 
Label: provider.getTranslatedValue(`ContactTypesList`, `GridColumn.Action.Label`, `Action`),
					}, 
				}, 
		Button: { 
			CreateNew: { 
Label: provider.getTranslatedValue(`ContactTypesList`, `Button.CreateNew.Label`, `Create a New Contact Type`),
						}, 
					}, 
				}, 
	CountryCreateUpdateDTO: { 
		Country: { 
			Field: { 
MaxLengthExceeded: (__parameter1: string, parameter2: string) => provider.getTranslatedValue(`CountryCreateUpdateDTO`, `Country.Field.MaxLengthExceeded`, `This field exceeded maximum length of {1} characters`, [__parameter1,parameter2]),IsRequired: provider.getTranslatedValue(`CountryCreateUpdateDTO`, `Country.Field.IsRequired`, `This field is required`),
						}, 
					}, 
				}, 
	DivisionCreateUpdateDTO: { 
		Division: { 
			Field: { 
MaxLengthExceeded: (__parameter1: string, parameter2: string) => provider.getTranslatedValue(`DivisionCreateUpdateDTO`, `Division.Field.MaxLengthExceeded`, `This field exceeded maximum length of {1} characters`, [__parameter1,parameter2]),IsRequired: provider.getTranslatedValue(`DivisionCreateUpdateDTO`, `Division.Field.IsRequired`, `This field is required`),
						}, 
					}, 
				}, 
	EnumValueFullName: { 
		TranslationStatus: { 
Success: provider.getTranslatedValue(`EnumValueFullName`, `TranslationStatus.Success`, `Translation finished`),Pending: provider.getTranslatedValue(`EnumValueFullName`, `TranslationStatus.Pending`, `Waiting for Translation`),Fail: provider.getTranslatedValue(`EnumValueFullName`, `TranslationStatus.Fail`, `Translation failed`),
			}, 
		SystemRole: { 
Submitter: provider.getTranslatedValue(`EnumValueFullName`, `SystemRole.Submitter`, `Submitter`),Approver: provider.getTranslatedValue(`EnumValueFullName`, `SystemRole.Approver`, `Approver`),Administrator: provider.getTranslatedValue(`EnumValueFullName`, `SystemRole.Administrator`, `Administrator`),
			}, 
		ContactSystemType: { 
Remote: provider.getTranslatedValue(`EnumValueFullName`, `ContactSystemType.Remote`, `Remote`),InPerson: provider.getTranslatedValue(`EnumValueFullName`, `ContactSystemType.InPerson`, `In person`),
			}, 
		ContactReportStatus: { 
ReviewedNotApproved: provider.getTranslatedValue(`EnumValueFullName`, `ContactReportStatus.ReviewedNotApproved`, `Reviewed Not Approved`),Pending: provider.getTranslatedValue(`EnumValueFullName`, `ContactReportStatus.Pending`, `New`),Approved: provider.getTranslatedValue(`EnumValueFullName`, `ContactReportStatus.Approved`, `Approved`),AdditionalInfoRequested: provider.getTranslatedValue(`EnumValueFullName`, `ContactReportStatus.AdditionalInfoRequested`, `Additional Info Requested`),
				}, 
			}, 
	EnumValueShortName: { 
		TranslationStatus: { 
Success: provider.getTranslatedValue(`EnumValueShortName`, `TranslationStatus.Success`, `Finished`),Pending: provider.getTranslatedValue(`EnumValueShortName`, `TranslationStatus.Pending`, `Waiting`),Fail: provider.getTranslatedValue(`EnumValueShortName`, `TranslationStatus.Fail`, `Failed`),
			}, 
		SystemRole: { 
Submitter: provider.getTranslatedValue(`EnumValueShortName`, `SystemRole.Submitter`, `Submitter`),Approver: provider.getTranslatedValue(`EnumValueShortName`, `SystemRole.Approver`, `Approver`),Administrator: provider.getTranslatedValue(`EnumValueShortName`, `SystemRole.Administrator`, `Administrator`),
			}, 
		ContactSystemType: { 
Remote: provider.getTranslatedValue(`EnumValueShortName`, `ContactSystemType.Remote`, `Remote`),InPerson: provider.getTranslatedValue(`EnumValueShortName`, `ContactSystemType.InPerson`, `In person`),
			}, 
		ContactReportStatus: { 
ReviewedNotApproved: provider.getTranslatedValue(`EnumValueShortName`, `ContactReportStatus.ReviewedNotApproved`, `RNA`),Pending: provider.getTranslatedValue(`EnumValueShortName`, `ContactReportStatus.Pending`, `New`),Approved: provider.getTranslatedValue(`EnumValueShortName`, `ContactReportStatus.Approved`, `Approved`),AdditionalInfoRequested: provider.getTranslatedValue(`EnumValueShortName`, `ContactReportStatus.AdditionalInfoRequested`, `AIR`),
				}, 
			}, 
	ErrorPage: { 
		UnknownError: { 
Header: provider.getTranslatedValue(`ErrorPage`, `UnknownError.Header`, `Error Occurred`),Content: provider.getTranslatedValue(`ErrorPage`, `UnknownError.Content`, `Unknown Error occurred. Please refresh the page or try again later.`),
			}, 
		Unauthorized: { 
Header: provider.getTranslatedValue(`ErrorPage`, `Unauthorized.Header`, `Not Authorized`),Content: provider.getTranslatedValue(`ErrorPage`, `Unauthorized.Content`, `You are not authorized to access this page.`),
			}, 
		NotFound: { 
Header: provider.getTranslatedValue(`ErrorPage`, `NotFound.Header`, `Page Not Found`),Content: provider.getTranslatedValue(`ErrorPage`, `NotFound.Content`, `The page you requested does not exist. Please make sure you are using correct link.`),			Button: { 
				Home: { 
Label: provider.getTranslatedValue(`ErrorPage`, `NotFound.Button.Home.Label`, `Home`),
								}, 
							}, 
						}, 
					}, 
	GenericErrors: { 
ValueNotAvailable: provider.getTranslatedValue(`GenericErrors`, `ValueNotAvailable`, `N/A.`),UnexpectedError: provider.getTranslatedValue(`GenericErrors`, `UnexpectedError`, `Unknown server error occurred.`),Unauthorized: provider.getTranslatedValue(`GenericErrors`, `Unauthorized`, `You are not allowed to perform this action.`),NotFound: provider.getTranslatedValue(`GenericErrors`, `NotFound`, `Data you are trying to work with no longer exists. Please reload the page and try again.`),
		}, 
	HomePage: { 
		Header: { 
Title: provider.getTranslatedValue(`HomePage`, `Header.Title`, `What is a Contact Report?`),
			}, 
		Content: { 
Description: provider.getTranslatedValue(`HomePage`, `Content.Description`, `<p>
Every time you meet a competitor (whether it is in a business or a social setting and regardless of what is discussed) you <b>MUST</b> complete a Contact Report setting out the date, location, attendees and a brief description of any discussions that took place. If your contact report contains any sensitive commercial information, please speak to a member of the legal team. This will allow us to monitor the level of contact personnel have with competitors and provides us with a written record of discussions, should allegations be made at a later date.
</p>
<p>
Contact Reports should be submitted using the pro forma report, which can be accessed through the "Create a New Report” button below. Reports will be sent to the Compliance Team who will approve or reject each report submitted.
</p>
<p>
Please ensure you provide adequate information within the report to allow the Compliance Team to review it. We need a brief description of all subjects discussed in order to be able to confirm that the meeting was compliant. It is often easiest to attach meeting minutes, should they exist. If no commercial discussions took place, please confirm this in the report.
</p>
<p>
Please note that Contact Reports are not used as a way of obtaining legal approval for commercial negotiations/arrangements. Legal approval or sign off for a deal/contract/arrangement must be sought separately through direct contact with the ABF Legal Team.
</p>
<b>Adding attachment(s)</b>
<ul>
<li>If you wish to add an attachment to your Contact Report, simply create your report in the usual manner, then click on "browse" and select the document you wish to attach. Two documents can be attached in this way.</li>
<li>Once you have completed all sections and attached relevant documentation, click on "Submit".</li>
</ul>`),
			}, 
		Button: { 
CreateContactReport: provider.getTranslatedValue(`HomePage`, `Button.CreateContactReport`, `Create a New Report`),
				}, 
			}, 
	Layout: { 
		Initialization: { 
Title: provider.getTranslatedValue(`Layout`, `Initialization.Title`, `Initializing`),SubTitle: provider.getTranslatedValue(`Layout`, `Initialization.SubTitle`, `Loading necessary data...`),
			}, 
		Header: { 
Title: provider.getTranslatedValue(`Layout`, `Header.Title`, `Contact Reports`),
				}, 
			}, 
	LocationsEditor: { 
		Type: { 
			SubRegion: { 
Name: provider.getTranslatedValue(`LocationsEditor`, `Type.SubRegion.Name`, `Sub Region`),
				}, 
			Region: { 
Name: provider.getTranslatedValue(`LocationsEditor`, `Type.Region.Name`, `Region`),
				}, 
			Country: { 
Name: provider.getTranslatedValue(`LocationsEditor`, `Type.Country.Name`, `Country`),
					}, 
				}, 
		Header: { 
			Locations: { 
Title: provider.getTranslatedValue(`LocationsEditor`, `Header.Locations.Title`, `Locations`),
					}, 
				}, 
		Field: { 
			SubRegion: { 
Label: provider.getTranslatedValue(`LocationsEditor`, `Field.SubRegion.Label`, `Sub Region`),
				}, 
			Region: { 
Label: provider.getTranslatedValue(`LocationsEditor`, `Field.Region.Label`, `Region`),
				}, 
			Name: { 
Label: provider.getTranslatedValue(`LocationsEditor`, `Field.Name.Label`, `Name`),
					}, 
				}, 
		EditDialog: { 
			EditSubRegion: { 
Title: provider.getTranslatedValue(`LocationsEditor`, `EditDialog.EditSubRegion.Title`, `Edit Sub Region`),
				}, 
			EditRegion: { 
Title: provider.getTranslatedValue(`LocationsEditor`, `EditDialog.EditRegion.Title`, `Edit Region`),
				}, 
			EditCountry: { 
Title: provider.getTranslatedValue(`LocationsEditor`, `EditDialog.EditCountry.Title`, `Edit Country`),
				}, 
			CreateSubRegion: { 
Title: provider.getTranslatedValue(`LocationsEditor`, `EditDialog.CreateSubRegion.Title`, `Create Sub Region`),
				}, 
			CreateRegion: { 
Title: provider.getTranslatedValue(`LocationsEditor`, `EditDialog.CreateRegion.Title`, `Create Region`),
				}, 
			CreateCountry: { 
Title: provider.getTranslatedValue(`LocationsEditor`, `EditDialog.CreateCountry.Title`, `Create Country`),
					}, 
				}, 
		Button: { 
			AddNewSubRegion: { 
Label: provider.getTranslatedValue(`LocationsEditor`, `Button.AddNewSubRegion.Label`, `Add New Sub Region`),
				}, 
			AddNewRegion: { 
Label: provider.getTranslatedValue(`LocationsEditor`, `Button.AddNewRegion.Label`, `Add New Region`),
				}, 
			AddNewCountry: { 
Label: provider.getTranslatedValue(`LocationsEditor`, `Button.AddNewCountry.Label`, `Add New Country`),
						}, 
					}, 
				}, 
	Menu: { 
		MenuItem: { 
Users: provider.getTranslatedValue(`Menu`, `MenuItem.Users`, `Users`),ToBeReviewed: provider.getTranslatedValue(`Menu`, `MenuItem.ToBeReviewed`, `To be Reviewed`),Organisations: provider.getTranslatedValue(`Menu`, `MenuItem.Organisations`, `Companies`),OnBehalfOf: provider.getTranslatedValue(`Menu`, `MenuItem.OnBehalfOf`, `On Behalf Of`),MyReports: provider.getTranslatedValue(`Menu`, `MenuItem.MyReports`, `View My Reports`),Locations: provider.getTranslatedValue(`Menu`, `MenuItem.Locations`, `Countries`),Home: provider.getTranslatedValue(`Menu`, `MenuItem.Home`, `What is a Contact Report?`),CreateContactReport: provider.getTranslatedValue(`Menu`, `MenuItem.CreateContactReport`, `Create a New Report`),ContactTypes: provider.getTranslatedValue(`Menu`, `MenuItem.ContactTypes`, `Contact Types`),AllReports: provider.getTranslatedValue(`Menu`, `MenuItem.AllReports`, `View All Reports`),
				}, 
			}, 
	OrganisationsEditor: { 
		Type: { 
			Division: { 
Name: provider.getTranslatedValue(`OrganisationsEditor`, `Type.Division.Name`, `Division`),
				}, 
			Company: { 
Name: provider.getTranslatedValue(`OrganisationsEditor`, `Type.Company.Name`, `Company`),
					}, 
				}, 
		Header: { 
			Organisations: { 
Title: provider.getTranslatedValue(`OrganisationsEditor`, `Header.Organisations.Title`, `Business`),
					}, 
				}, 
		Field: { 
			Name: { 
Label: provider.getTranslatedValue(`OrganisationsEditor`, `Field.Name.Label`, `Name`),
				}, 
			Division: { 
Label: provider.getTranslatedValue(`OrganisationsEditor`, `Field.Division.Label`, `Division`),
					}, 
				}, 
		EditDialog: { 
			EditDivision: { 
Title: provider.getTranslatedValue(`OrganisationsEditor`, `EditDialog.EditDivision.Title`, `Edit Division`),
				}, 
			EditCompany: { 
Title: provider.getTranslatedValue(`OrganisationsEditor`, `EditDialog.EditCompany.Title`, `Edit Company`),
				}, 
			CreateDivision: { 
Title: provider.getTranslatedValue(`OrganisationsEditor`, `EditDialog.CreateDivision.Title`, `Create Division`),
				}, 
			CreateCompany: { 
Title: provider.getTranslatedValue(`OrganisationsEditor`, `EditDialog.CreateCompany.Title`, `Create Company`),
					}, 
				}, 
		Button: { 
			AddNewDivision: { 
Label: provider.getTranslatedValue(`OrganisationsEditor`, `Button.AddNewDivision.Label`, `Add New Division`),
				}, 
			AddNewCompany: { 
Label: provider.getTranslatedValue(`OrganisationsEditor`, `Button.AddNewCompany.Label`, `Add New Company`),
						}, 
					}, 
				}, 
	RegionCreateUpdateDTO: { 
		Region: { 
			Field: { 
MaxLengthExceeded: (__parameter1: string, parameter2: string) => provider.getTranslatedValue(`RegionCreateUpdateDTO`, `Region.Field.MaxLengthExceeded`, `This field maximum length of {1} characters`, [__parameter1,parameter2]),IsRequired: provider.getTranslatedValue(`RegionCreateUpdateDTO`, `Region.Field.IsRequired`, `This field is required`),
						}, 
					}, 
				}, 
	SubRegionCreateUpdateDTO: { 
		SubRegion: { 
			Field: { 
MaxLengthExceeded: (__parameter1: string, parameter2: string) => provider.getTranslatedValue(`SubRegionCreateUpdateDTO`, `SubRegion.Field.MaxLengthExceeded`, `This field exceeded maximum length of {1} characters`, [__parameter1,parameter2]),IsRequired: provider.getTranslatedValue(`SubRegionCreateUpdateDTO`, `SubRegion.Field.IsRequired`, `This field is required`),
						}, 
					}, 
				}, 
	SystemUserDetail: { 
		Section: { 
			UserDetails: { 
Title: provider.getTranslatedValue(`SystemUserDetail`, `Section.UserDetails.Title`, `User Details`),
				}, 
			Roles: { 
Title: provider.getTranslatedValue(`SystemUserDetail`, `Section.Roles.Title`, `Roles`),
					}, 
				}, 
		NoApproverRoles: { 
Text: provider.getTranslatedValue(`SystemUserDetail`, `NoApproverRoles.Text`, `No approver roles assigned`),
			}, 
		Header: { 
			SystemUserEdit: { 
Title: (parameter1: string) => provider.getTranslatedValue(`SystemUserDetail`, `Header.SystemUserEdit.Title`, `User {0}`, [parameter1]),
					}, 
				}, 
		GridColumn: { 
			SystemRole: { 
Label: provider.getTranslatedValue(`SystemUserDetail`, `GridColumn.SystemRole.Label`, `Role`),
				}, 
			Organisation: { 
Label: provider.getTranslatedValue(`SystemUserDetail`, `GridColumn.Organisation.Label`, `Business`),
				}, 
			Location: { 
Label: provider.getTranslatedValue(`SystemUserDetail`, `GridColumn.Location.Label`, `Location`),
				}, 
			Action: { 
Label: provider.getTranslatedValue(`SystemUserDetail`, `GridColumn.Action.Label`, `Action`),
					}, 
				}, 
		Field: { 
			LastName: { 
Label: provider.getTranslatedValue(`SystemUserDetail`, `Field.LastName.Label`, `Last Name`),
				}, 
			FirstName: { 
Label: provider.getTranslatedValue(`SystemUserDetail`, `Field.FirstName.Label`, `First Name`),
				}, 
			Email: { 
Label: provider.getTranslatedValue(`SystemUserDetail`, `Field.Email.Label`, `Email`),
				}, 
			Administrator: { 
Label: provider.getTranslatedValue(`SystemUserDetail`, `Field.Administrator.Label`, `Administrator`),
					}, 
				}, 
		ConfirmationDialog: { 
			DeleteRole: { 
Title: provider.getTranslatedValue(`SystemUserDetail`, `ConfirmationDialog.DeleteRole.Title`, `Delete Role`),Description: provider.getTranslatedValue(`SystemUserDetail`, `ConfirmationDialog.DeleteRole.Description`, `Are you sure you want to delete this role?`),
					}, 
				}, 
		Button: { 
			RemoveRole: { 
Label: provider.getTranslatedValue(`SystemUserDetail`, `Button.RemoveRole.Label`, `Remove Role`),
				}, 
			AddRole: { 
Label: provider.getTranslatedValue(`SystemUserDetail`, `Button.AddRole.Label`, `Add Role`),
						}, 
					}, 
				}, 
	SystemUsersList: { 
		List: { 
NoUsers: provider.getTranslatedValue(`SystemUsersList`, `List.NoUsers`, `No users found`),
			}, 
		Header: { 
			SystemUsers: { 
Title: provider.getTranslatedValue(`SystemUsersList`, `Header.SystemUsers.Title`, `Users`),
					}, 
				}, 
		GridColumn: { 
			SystemRoles: { 
Label: provider.getTranslatedValue(`SystemUsersList`, `GridColumn.SystemRoles.Label`, `Roles`),
				}, 
			LastName: { 
Label: provider.getTranslatedValue(`SystemUsersList`, `GridColumn.LastName.Label`, `Last Name`),
				}, 
			FirstName: { 
Label: provider.getTranslatedValue(`SystemUsersList`, `GridColumn.FirstName.Label`, `First Name`),
				}, 
			Email: { 
Label: provider.getTranslatedValue(`SystemUsersList`, `GridColumn.Email.Label`, `Email`),
				}, 
			Action: { 
Label: provider.getTranslatedValue(`SystemUsersList`, `GridColumn.Action.Label`, `Action`),
					}, 
				}, 
		Filter: { 
			SystemRole: { 
Label: provider.getTranslatedValue(`SystemUsersList`, `Filter.SystemRole.Label`, `Role`),
				}, 
			LastName: { 
Label: provider.getTranslatedValue(`SystemUsersList`, `Filter.LastName.Label`, `Last Name`),
				}, 
			FirstName: { 
Label: provider.getTranslatedValue(`SystemUsersList`, `Filter.FirstName.Label`, `First Name`),
					}, 
				}, 
		Button: { 
			ApplyFilter: { 
Label: provider.getTranslatedValue(`SystemUsersList`, `Button.ApplyFilter.Label`, `Apply Filter`),
				}, 
			AddSystemUser: { 
Label: provider.getTranslatedValue(`SystemUsersList`, `Button.AddSystemUser.Label`, `Add User from AD`),
						}, 
					}, 
				}, 
	UploadedFilesValidator: { 
		UploadedFile: { 
FileTypeIsNotSupported: (parameter1: string) => provider.getTranslatedValue(`UploadedFilesValidator`, `UploadedFile.FileTypeIsNotSupported`, `Type of selected file {0} is not supported. Please select supported file type.`, [parameter1]),FileIsTooBig: (parameter1: string, parameter2: string) => provider.getTranslatedValue(`UploadedFilesValidator`, `UploadedFile.FileIsTooBig`, `Selected file {0} is too big. Please upload files with maximum size of {1}.`, [parameter1,parameter2]),
				}, 
			}, 

    };
}