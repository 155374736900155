import {
  DetailsRow,
  GroupedList,
  IColumn,
  IGroup,
  IGroupHeaderProps,
  SelectionMode,
  GroupHeader,
  Stack,
  IconButton,
} from "@fluentui/react";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { addIcon, archivelIcon, editIcon, unArchivelIcon } from "../../../common/components/Icons";
import { Organization, OrganizationType } from "../organizationSchemas";
import { findParentId, mapToGroups, mapToItems } from "../organizationUtilities";
import { useTranslator } from "../../../common/state/translatorState";
import { OrganisationDetailDTO } from "../../../services/contactReportsApi";
import { CompanyDetailDTO } from "../../../services/contactReportsApi";
import { DivisionDetailDTO } from "../../../services/contactReportsApi";

const NonClickableIconButtonStyles: React.CSSProperties = { pointerEvents: "none" };

const columns: IColumn[] = [
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 600,
  },
  {
    key: "buttons",
    name: "",
    minWidth: 100,
  },
];

const ItemHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
`;

const GroupName = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 12rem;
`;

type Props = {
  organizations: DivisionDetailDTO[];
  onCreate: (type: OrganizationType, parentId?: number) => any;
  onEdit: (organization: Organization, parents?: DivisionDetailDTO[]) => any;
  onArchive: (organizationId: number) => any;
  onUnArchive: (organizationId: number) => any;
};

export default function OrganizationList({ organizations, onCreate, onEdit, onArchive, onUnArchive }: Props) {
  const t = useTranslator();
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});
  const groups = useMemo(() => mapToGroups(organizations, expandedGroups), [organizations, expandedGroups]);
  const items = useMemo(() => mapToItems(organizations), [organizations]);

  const onGroupCollapseChanged = (groupKey: string, isCollapsed: boolean) => {
    setExpandedGroups((g) => ({ ...g, [groupKey]: isCollapsed }));
  };

  const onRenderItemColumn = (item?: DivisionDetailDTO, _index?: number, column?: IColumn) => {
    if (!item || !column) {
      return null;
    }
    if (column.key === "buttons") {
      const location: Organization = {
        id: item.id,
        parentId: findParentId(item.id, organizations),
        name: item.name,
        type: "company",
        isEdit: true,
      };
      return (
        <Stack horizontal tokens={{ childrenGap: "0.25rem" }}>
          <IconButton
            iconProps={item.isActive === true ? editIcon : undefined}
            onClick={() => onEdit(location, organizations)}
            className="ml-1"
            style={item.isActive === true ? undefined : NonClickableIconButtonStyles}
          />

          <IconButton
            iconProps={item.isActive === true ? archivelIcon : unArchivelIcon}
            onClick={() => (item.isActive === true ? onArchive(item.id) : onUnArchive(item.id))}
            title={item.isActive === true ? t.Components.Button.Archive.Label : t.Components.Button.Unarchive.Label}
          />
        </Stack>
      );
    }

    return <GroupName className={item.isActive === true ? "" : "line-through"}>{item.name}</GroupName>;
  };

  const onRenderCell = (nestingDepth?: number, item?: CompanyDetailDTO, itemIndex?: number, group?: IGroup): React.ReactNode => {
    return item && typeof itemIndex === "number" && itemIndex > -1 ? (
      <DetailsRow
        columns={columns}
        groupNestingDepth={nestingDepth}
        item={item}
        itemIndex={itemIndex}
        selectionMode={SelectionMode.none}
        compact={false}
        group={group}
        onRenderItemColumn={onRenderItemColumn}
      />
    ) : null;
  };

  const onGroupRenderTitle = (props: IGroupHeaderProps) => {
    const item = props.group?.data as OrganisationDetailDTO | DivisionDetailDTO;
    if (!item) {
      return null;
    }

    const organization: Organization = {
      id: item.id,
      name: item.name,
      type: "division",
      isEdit: true,
    };
    const childrenCount = (item as DivisionDetailDTO)?.companies?.length ?? 0;
    return (
      <ItemHeaderContainer>
        <GroupName className={item.isActive === true ? "" : "line-through"}>
          {item.name} ({childrenCount})
        </GroupName>
        <Stack horizontal tokens={{ childrenGap: "0.25rem" }}>
          <IconButton iconProps={addIcon} onClick={() => onCreate("company", item.id)} />
          <IconButton
            iconProps={item.isActive === true ? editIcon : undefined}
            onClick={() => onEdit(organization, undefined)}
            style={item.isActive === true ? undefined : NonClickableIconButtonStyles}
          />
          <IconButton
            iconProps={item.isActive === true ? archivelIcon : unArchivelIcon}
            onClick={() => (item.isActive === true ? onArchive(item.id) : onUnArchive(item.id))}
            title={item.isActive === true ? t.Components.Button.Archive.Label : t.Components.Button.Unarchive.Label}
          />
        </Stack>
      </ItemHeaderContainer>
    );
  };

  const onRenderHeader = (
    onGroupCollapseChanged: (groupKey: string, isCollapsed: boolean) => any,
    props?: IGroupHeaderProps
  ): JSX.Element | null => {
    if (!props || !props.group) {
      return null;
    }
    return (
      <GroupHeader
        {...props}
        onRenderTitle={() => onGroupRenderTitle(props)}
        onToggleCollapse={(e) => onGroupCollapseChanged(e.key ?? "", e.isCollapsed === true)}
      />
    );
  };

  return (
    <GroupedList
      className="w-full"
      items={items}
      onRenderCell={onRenderCell}
      selectionMode={SelectionMode.none}
      groupProps={{ onRenderHeader: (headerProps) => onRenderHeader(onGroupCollapseChanged, headerProps), showEmptyGroups: true }}
      groups={groups}
      compact={true}
    />
  );
}
