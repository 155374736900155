import { DefaultButton, IButtonProps, IButtonStyles } from "@fluentui/react";
import { deleteIcon } from "../Icons";
import theme from "../../../app/theme";

const styles: IButtonStyles = {
  root: {
    width: "2rem",
    minWidth: "2rem",
    padding: "0.25rem",
    color: theme.semanticColors.errorText,
    background: theme.semanticColors.buttonBackground,
    borderColor: theme.semanticColors.errorText,
  },
  rootPressed: {
    color: theme.semanticColors.errorText,
    background: theme.semanticColors.errorBackground,
  },
  rootHovered: {
    color: theme.semanticColors.errorText,
    background: theme.semanticColors.errorBackground,
    borderColor: theme.semanticColors.errorText,
  },
};

type Props = Omit<IButtonProps, "styles">;

const DeleteIconButton = (props: Props) => <DefaultButton iconProps={deleteIcon} styles={styles} {...props} />;

export default DeleteIconButton;
