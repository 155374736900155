import { ActionButton } from "@fluentui/react";
import PageHeader from "../../../common/components/PageHeader";
import { addIcon } from "../../../common/components/Icons";
import { useTranslator } from "../../../common/state/translatorState";
import { useNavigate } from "react-router-dom";
import ContactTypeList from "../components/ContactTypeList";

const ContactTypesListScreen = () => {
  const t = useTranslator();
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        className="mb-2"
        rightElement={
          <ActionButton iconProps={addIcon} onClick={() => navigate("/contact-types/new")}>
            {t.ContactTypesList.Header.Button.Add}
          </ActionButton>
        }
      >
        {t.ContactTypesList.Header.ContactTypes.Title}
      </PageHeader>
      <ContactTypeList />
    </>
  );
};

export default ContactTypesListScreen;
