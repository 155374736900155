import { useNavigate } from "react-router-dom";
import useNumberRouteParams from "../../../common/hooks/useNumberRouteParams";
import { useContactReportDetail } from "../contactReportQueries";
import PageHeader from "../../../common/components/PageHeader";
import { useTranslator } from "../../../common/state/translatorState";
import { ProgressIndicator } from "@fluentui/react";
import ContactReportDetail from "../components/ContactReportDetail";
import DisplayValue from "../components/DisplayValue";
import { ContactReportStatus } from "../../../services/contactReportsApi";

const ContactReportDetailScreen = () => {
  const t = useTranslator();
  const navigate = useNavigate();
  const { id } = useNumberRouteParams("id");

  const { data: contactReport, isFetching } = useContactReportDetail(id ?? 0, {
    enabled: Number.isInteger(id),
    onError: () => {
      navigate("/contact-reports", { replace: true });
      //TODO: show some toast message?
    },
  });

  const renderReferenceNumber = () =>
    contactReport ? (
      <DisplayValue label={t.ContactReportDetail.Field.ReferenceNumber.Label}>
        <p className="mt-0 mb-1">{contactReport.referenceNumber}</p>
      </DisplayValue>
    ) : undefined;

  return (
    <>
      <PageHeader className="mb-4" rightElement={renderReferenceNumber()}>
        {!contactReport ? t.ContactReportDetail.Header.ContactReportNew.Title
          : contactReport.contactReportStatus === ContactReportStatus.Pending ? t.ContactReportDetail.Header.ContactReportPending.Title 
          : contactReport.contactReportStatus === ContactReportStatus.AdditionalInfoRequested ? t.ContactReportDetail.Header.ContactReportAdditionalInfoRequested.Title
          : contactReport.contactReportStatus === ContactReportStatus.Approved ? t.ContactReportDetail.Header.ContactReportApproved.Title
          : t.ContactReportDetail.Header.ContactReportReviewedNotApproved.Title
        }
      </PageHeader>
      {isFetching && !contactReport && (
        <div className="text-center my-8">
          <ProgressIndicator label={t.Components.ProgressIndicator.LoadingData.Label} />
        </div>
      )}
      {contactReport && <ContactReportDetail contactReport={contactReport} />}
    </>
  );
};

export default ContactReportDetailScreen;
