import { useNavigate } from "react-router-dom";
import PageHeader from "../../../common/components/PageHeader";
import { useTranslator } from "../../../common/state/translatorState";
import { useActiveContactTypesQuery } from "../../contactTypes/contactTypeQueries";
import { useActiveLocationsQuery } from "../../locations/locationsQueries";
import { useActiveOrganizationsQuery } from "../../organizations/organizationQueries";
import ContactReportForm from "../components/ContactReportForm";
import { useContactReportDetail } from "../contactReportQueries";
import { ContactReportSchema } from "../contactReportSchemas";
import useToastNotification from "../../../common/hooks/useToastNotification";
import useNumberRouteParams from "../../../common/hooks/useNumberRouteParams";

const EditContactReportScreen = () => {
  const t = useTranslator();
  const { id } = useNumberRouteParams("id");
  const toast = useToastNotification();
  const navigate = useNavigate();

  const { data: detail, isError: detailFailed } = useContactReportDetail(id, {
    onError: () => toast.showError(t.Components.ToastMessage.ErrorDataNotFound.Title),
  });
  const { data: organizations } = useActiveOrganizationsQuery();
  const { data: contactTypes } = useActiveContactTypesQuery();
  const { data: locations } = useActiveLocationsQuery();

  if (!organizations || !contactTypes || !locations || !detail) {
    if (detailFailed) {
      navigate("/contact-reports/list", { replace: true });
    }

    return null;
  }

  const contactReport: ContactReportSchema = {
    attendee: {
      name: detail.attendee.displayName ?? "",
      email: detail.attendeeEmail ?? "",
      phoneNumber: detail.attendeePhone ?? "",
      uniqueId: detail.attendee.uniqueId ?? "",
    },
    attendeeCompanyId: detail.attendeeCompany.id,
    contactTypeId: detail.contactType.id,
    meetingCountryId: detail.meetingCountry.id,
    attendees: detail.attendees,
    competitorCompanyName: detail.competitorCompanyName,
    meetingDate: new Date(detail.meetingDate),
    subject: detail.originalContent.subject,
    submitterName: detail.createdBy.displayName ?? "",
    submitterEmail: detail.submitterEmail ?? "",
    submitterPhone: detail.submitterPhone ?? "",
    summary: detail.originalContent.summary,
    meetingAddress: detail.meetingAddress ?? "",
  };

  if (Array.isArray(detail.originalContent.fileAttachments)) {
    if (detail.originalContent.fileAttachments.length > 0) {
      contactReport.attachment1 = detail.originalContent.fileAttachments[0];
    }
    if (detail.originalContent.fileAttachments.length > 1) {
      contactReport.attachment2 = detail.originalContent.fileAttachments[1];
    }
  }

  return (
    <>
      <PageHeader className="mb-2">
        {!contactReport
          ? t.ContactReportDetail.Header.ContactReportNew.Title
          : t.ContactReportDetail.Header.ContactReportAdditionalInfoRequested.Title}
      </PageHeader>
      <ContactReportForm
        editedContactReport={detail}
        contactReport={contactReport}
        contactTypes={contactTypes}
        locations={locations}
        organizations={organizations}
      />
    </>
  );
};

export default EditContactReportScreen;
