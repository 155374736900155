import { IComboBoxOption, IComboBoxOptionStyles } from "@fluentui/react";
import { CountryInfoDTO, RegionInfoDTO, SubRegionInfoDTO } from "../../services/contactReportsApi";
import { LocationType } from "./locationSchemas";
import { useActiveLocationsQuery } from "./locationsQueries";
import { useMemo } from "react";
import { HierarchyInfo } from "../../common/model/hierarchyInfoSchema";

type LocationComboBoxOption = {
  type: LocationType;
  data: RegionInfoDTO | SubRegionInfoDTO | CountryInfoDTO;
} & Omit<IComboBoxOption, "data">;

export function useLocationComboBoxOptions(
  regionStyles?: Partial<IComboBoxOptionStyles>,
  subRegionStyles?: Partial<IComboBoxOptionStyles>,
  countryStyles?: Partial<IComboBoxOptionStyles>
): LocationComboBoxOption[] {
  const { data: locations } = useActiveLocationsQuery();

  return useMemo(() => {
    if (!Array.isArray(locations)) {
      return [];
    }

    const result: LocationComboBoxOption[] = [];
    for (const region of locations) {
      result.push({
        key: region.id,
        text: region.name,
        type: "region",
        data: region,
        styles: regionStyles,
      });
      for (const subRegion of region.subRegions) {
        result.push({
          key: subRegion.id,
          text: `${subRegion.name} (${region.name})`,
          type: "subRegion",
          data: subRegion,
          styles: subRegionStyles,
        });

        for (const country of subRegion.countries) {
          result.push({
            key: country.id,
            text: `${country.name} (${region.name} -> ${subRegion.name})`,
            type: "country",
            data: country,
            styles: countryStyles,
          });
        }
      }
    }

    return result;
  }, [locations]);
}

export type LocationHierarchyInfo = {
  type: LocationType;
} & HierarchyInfo;

export function useLocationHierarchy(): LocationHierarchyInfo[] {
  const { data: locations } = useActiveLocationsQuery();

  return useMemo(() => {
    if (!Array.isArray(locations)) {
      return [];
    }

    const result: LocationHierarchyInfo[] = [];

    for (const region of locations) {
      const mappedRegion: LocationHierarchyInfo = {
        id: region.id,
        isActive: region.isActive,
        name: region.name,
        parent: null,
        isActiveComputed: region.isActive,
        type: "region",
      };
      result.push(mappedRegion);

      for (const subRegion of region.subRegions) {
        const mappedSubRegion: LocationHierarchyInfo = {
          id: subRegion.id,
          isActive: subRegion.isActive,
          name: subRegion.name,
          isActiveComputed: region.isActive && subRegion.isActive,
          parent: mappedRegion,
          type: "subRegion",
        };
        result.push(mappedSubRegion);

        for (const country of subRegion.countries) {
          const mappedCountry: LocationHierarchyInfo = {
            id: country.id,
            isActive: country.isActive,
            name: country.name,
            isActiveComputed: region.isActive && subRegion.isActive && country.isActive,
            parent: mappedSubRegion,
            type: "country",
          };
          result.push(mappedCountry);
        }
      }
    }

    return result;
  }, [locations]);
}

export type LocationHierarchyComboBoxOption = {
  type: LocationType;
  data: LocationHierarchyInfo;
} & Omit<IComboBoxOption, "data">;

export function useLocationHierarchyComboBoxOptions(
  regionStyles?: Partial<IComboBoxOptionStyles>,
  subRegionStyles?: Partial<IComboBoxOptionStyles>,
  countryStyles?: Partial<IComboBoxOptionStyles>
): LocationHierarchyComboBoxOption[] {
  const hierarchy = useLocationHierarchy();
  return hierarchy.map((h) => ({
    key: h.id,
    text:
      h.type === "region"
        ? h.name
        : h.type === "subRegion"
        ? `${h.name} (${h.parent?.name})`
        : `${h.name} (${h.parent?.parent?.name} -> ${h.parent?.name})`,
    type: h.type,
    data: h,
    styles: h.type === "region" ? regionStyles : h.type === "subRegion" ? subRegionStyles : countryStyles,
  }));
}
