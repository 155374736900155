import { ActionButton } from "@fluentui/react";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { addIcon } from "../../../common/components/Icons";
import { useConfirmationDialog } from "../../../common/hooks/useConfirmationDialog";
import { RegionDetailDTO, SubRegionDetailDTO } from "../../../services/contactReportsApi";
import LocationEditDialog from "../components/LocationEditDialog";
import LocationList from "../components/LocationList";
import { useAllLocationsQuery, useArchiveLocation, useUnArchiveLocation } from "../locationsQueries";
import { Location, LocationType } from "../locationSchemas";
import { useTranslator } from "../../../common/state/translatorState";
import PageHeader from "../../../common/components/PageHeader";
import useToastNotification from "../../../common/hooks/useToastNotification";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 24rem;
`;

type DialogState = {
  isOpen: boolean;
  location: Location;
  parents?: RegionDetailDTO[] | SubRegionDetailDTO[];
};

export default function LocationsScreen() {
  const t = useTranslator();
  const toast = useToastNotification();
  const { data: regions } = useAllLocationsQuery();
  const { mutate: archiveLocation } = useArchiveLocation();
  const { mutate: unArchiveLocation } = useUnArchiveLocation();
  const { isConfirmed } = useConfirmationDialog();

  const [dialogState, setDialogState] = useState<DialogState>({ isOpen: false, location: { name: "", type: "region", isEdit: false } });

  const openCreateDialog = useCallback(
    (type: LocationType, parentId?: number) => {
      setDialogState((state) => ({ ...state, isOpen: true, location: { name: "", type, parentId, isEdit: false }, parents: undefined }));
    },
    [setDialogState]
  );

  const openEditDialog = useCallback(
    (location: Location, parents?: RegionDetailDTO[] | SubRegionDetailDTO[]) => {
      setDialogState((state) => ({ ...state, isOpen: true, location, parents }));
    },
    [setDialogState]
  );

  const closeDialog = useCallback(() => setDialogState((state) => ({ ...state, isOpen: false, parents: undefined })), [setDialogState]);

  const handleArchive = useCallback(
    async (locationId: number) => {
      if (await isConfirmed(t.Components.ConfirmationDialog.Archive.Title, t.Components.ConfirmationDialog.Archive.Description)) {
        archiveLocation(locationId, { onSuccess: () => toast.showSuccess(t.Components.ToastMessage.RecordArchived.Title) });
      }
    },
    [t, isConfirmed, archiveLocation]
  );

  const handleUnArchive = useCallback(
    async (locationId: number) => {
      if (await isConfirmed(t.Components.ConfirmationDialog.Unarchive.Title, t.Components.ConfirmationDialog.Unarchive.Description)) {
        unArchiveLocation(locationId, { onSuccess: () => toast.showSuccess(t.Components.ToastMessage.RecordUnarchived.Title) });
      }
    },
    [t, isConfirmed, unArchiveLocation]
  );

  return (
    <>
      <PageHeader>{t.Menu.MenuItem.Locations}</PageHeader>
      <Container>
        <ActionButton iconProps={addIcon} onClick={() => openCreateDialog("region")} className="mb-1">
          {t.LocationsEditor.Button.AddNewRegion.Label}
        </ActionButton>
        <LocationList
          regions={regions ?? []}
          onCreate={openCreateDialog}
          onEdit={openEditDialog}
          onArchive={handleArchive}
          onUnArchive={handleUnArchive}
        />
      </Container>
      <LocationEditDialog
        isOpen={dialogState.isOpen}
        onCancel={closeDialog}
        location={dialogState.location}
        parents={dialogState.parents}
      />
    </>
  );
}
