import "./assets/styles/all.scss";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { initializeIcons, ThemeProvider } from "@fluentui/react";
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import msalConfig from "./app/msalConfig";
import theme from "./app/theme";
import environment from "./app/environment";
import { baseRoute } from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from "./reportWebVitals";
import { ConfirmationDialogContextProvider } from "./common/hooks/useConfirmationDialog";
import { RecoilRoot } from "recoil";

const router = createBrowserRouter(baseRoute, { basename: environment.basename });
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1, //only one retry by default
      staleTime: 30 * 1000, //default 30 second stale time to avoid unnecessary request calls
    },
  },
});

export const msalInstance = new PublicClientApplication(msalConfig);
initializeIcons();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme} className="h-full">
        <MsalProvider instance={msalInstance}>
          <RecoilRoot>
            <ConfirmationDialogContextProvider>
              <RouterProvider router={router} />
            </ConfirmationDialogContextProvider>
          </RecoilRoot>
        </MsalProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

//@ts-ignore
if (module.hot && process.env.NODE_ENV !== "production") {
  //@ts-ignore
  module.hot.accept();
}
