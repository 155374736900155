import { createQueryMetadataState } from "../../common/hooks/useQueryMetadata";
import { nameof } from "../../common/utilities";
import { SystemUserListQueryDTO } from "../../services/contactReportsApi";
import { UserQueryFilter, UserQueryFilterSchema, UserQueryMetadata } from "./userSchemas";

const defaultMetadata: UserQueryMetadata = {
  filter: UserQueryFilterSchema.parse({}),
  sort: { fields: { [nameof<SystemUserListQueryDTO>("lastName")]: true, [nameof<SystemUserListQueryDTO>("firstName")]: true } },
  page: { offset: 0, limit: 50 },
};

const { metadataState, useQueryFilter, useQueryPaging, useQuerySort } = createQueryMetadataState<
  SystemUserListQueryDTO,
  UserQueryFilter,
  UserQueryMetadata
>("userQueryMetadata", defaultMetadata);

export {
  metadataState as userQueryMetadataState,
  useQuerySort as useUserQuerySort,
  useQueryFilter as useUserQueryFilter,
  useQueryPaging as useUserQueryPaging,
};
