import { ActionButton, IButtonStyles, Link } from "@fluentui/react";
import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { expandIcon } from "../../../common/components/Icons";
import useToastNotification, { Toast } from "../../../common/hooks/useToastNotification";
import { Translator, useTranslator } from "../../../common/state/translatorState";
import { downloadFile } from "../../../common/utilities";
import {
  ContactReportAttachmentIndexModel,
  ContactReportFullTextSearchResultDTO,
  ContactReportsClient
} from "../../../services/contactReportsApi";

const client = new ContactReportsClient();

const HighlightText = styled.p`
  em {
    font-weight: 600;
    background-color: rgb(255 215 0 / 0.8);
    padding: 0 0.2rem;
  }
`;

const ShowMoreButtonStyle: IButtonStyles = {
  root: { fontSize: 12, height: "24px", paddingLeft: 0 },
  icon: { fontSize: 12, marginLeft: 0 },
};

type FullTextHighlightProps = {
  contactReportId: number;
  fieldName: string;
  attachment?: ContactReportAttachmentIndexModel;
  highlightedValues: string[];
  translator: Translator;
  toast: Toast;
};

const FullTextHighlight = ({ contactReportId, fieldName, attachment, highlightedValues, translator, toast }: FullTextHighlightProps) => {
  const handleAttachmentDownloadClick = async () => {
    try {
      if (attachment && attachment.id !== undefined) {
        const downloadedAttachment = await client.downloadAttachment(contactReportId, attachment.id);
        downloadFile(
          downloadedAttachment.data,
          downloadedAttachment.fileName ?? `${translator.ContactReportDetail.Field.Attachment.Label} - 1`
        );
      }
    } catch (error) {
      toast.showError(translator.Components.ToastMessage.UnexpectedError.Title);
    }
  };

  return (
    <div className="flex flex-col gap-1 mb-2">
      <p className="m-0 text-xs font-semibold break-keep">
        {fieldName}
        {attachment && " ("}
        {attachment && (
          <Link onClick={handleAttachmentDownloadClick}>
            {attachment.fileName}
            {attachment.extension}
          </Link>
        )}
        {attachment && ")"}:
      </p>
      <div className="flex flex-col gap-y-1 ml-2">
        {highlightedValues.map((v, i) => (
          <HighlightText
            key={`${fieldName}_${i}`}
            className="m-0 text-xs"
            dangerouslySetInnerHTML={{ __html: v && v.length > 0 ? v : translator.GenericErrors.ValueNotAvailable }}
          ></HighlightText>
        ))}
      </div>
    </div>
  );
};

type Props = {
  contactReportId: number;
  fullTextResult: ContactReportFullTextSearchResultDTO;
};

const ContactReportFullTextResult = memo(({ contactReportId, fullTextResult }: Props) => {
  const t = useTranslator();
  const toast = useToastNotification();
  const [maxRenderItemCount, setMaxRenderItemCount] = useState(3);
  useEffect(() => setMaxRenderItemCount(3), [contactReportId]);
  if (!Array.isArray(fullTextResult.highlights) || fullTextResult.highlights.length === 0) {
    return null;
  }
  const totalItemCount = fullTextResult.highlights?.map((h) => h.values.length).reduce((total, current) => (total += current), 0) ?? 0;

  const handleShowMoreClick = () => {
    setMaxRenderItemCount((c) => c + 3);
  };

  let remainingItemRenderCount = maxRenderItemCount;
  return (
    <div className="ml-3 flex flex-col gap-y-2 pb-2">
      {fullTextResult.highlights
        .filter((h) => h.fieldName.length > 0)
        .map((h, i) => {
          if (remainingItemRenderCount === 0) {
            return null;
          }
          let values = remainingItemRenderCount <= h.values.length ? h.values.slice(0, remainingItemRenderCount) : h.values;
          remainingItemRenderCount -= values.length;
          return (
            <FullTextHighlight
              contactReportId={contactReportId}
              key={h.fieldName}
              fieldName={h.fieldName}
              attachment={i === 0 ? fullTextResult.attachment ?? undefined : undefined}
              highlightedValues={values}
              translator={t}
              toast={toast}
            />
          );
        })}

      {totalItemCount > maxRenderItemCount && (
        <ActionButton iconProps={expandIcon} onClick={handleShowMoreClick} styles={ShowMoreButtonStyle}>
          {t.Components.Button.ShowMore.Label}
        </ActionButton>
      )}
    </div>
  );
});

export default ContactReportFullTextResult;
