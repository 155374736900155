import { IDropdownOption, IGroup, SelectableOptionMenuItemType } from "@fluentui/react";
import { CountryDetailDTO, CountryInfoDTO, RegionDetailDTO, RegionInfoDTO, SubRegionDetailDTO, SubRegionInfoDTO } from "../../services/contactReportsApi";

export const mapToItems = (regions: RegionDetailDTO[]): CountryDetailDTO[] => {
  const result: CountryDetailDTO[] = [];
  for (const region of regions) {
    for (const subRegion of region.subRegions) {
      result.push(...subRegion.countries);
    }
  }
  return result;
};

export const mapToGroups = (regions: RegionDetailDTO[], expanded: Record<string, boolean>): IGroup[] => {
  const result: IGroup[] = [];
  let currentIndex = 0;

  for (let i = 0; i < regions.length; i++) {
    const region = regions[i];
    const key = `region_` + region.id;
    const mappedRegion: IGroup = {
      key,
      name: region.name,
      count: region.subRegions.length,
      data: region,
      startIndex: 0,
      isCollapsed: expanded[key] !== true,
      children: [],
      level: 0,
    };

    for (let j = 0; j < region.subRegions.length; j++) {
      const subRegion = region.subRegions[j];
      const key = "subregion_" + subRegion.id;
      const mappedSubRegion: IGroup = {
        key,
        name: subRegion.name,
        count: subRegion.countries.length,
        data: subRegion,
        startIndex: currentIndex,
        isCollapsed: expanded[key] !== true,
        children: [],
        level: 1,
      };

      mappedRegion.children?.push(mappedSubRegion);
      currentIndex += subRegion.countries.length;
    }
    result.push(mappedRegion);
  }

  return result;
};

export const findParentId = (locationId: number, regions: RegionDetailDTO[]): number | undefined => {
  for (const region of regions) {
    if (region.id === locationId) {
      return;
    }

    for (const subRegion of region.subRegions) {
      if (subRegion.id === locationId) {
        return region.id;
      }

      for (const country of subRegion.countries) {
        if (country.id === locationId) {
          return subRegion.id;
        }
      }
    }
  }
};

export const collectAllSubRegions = (regions: RegionDetailDTO[]): SubRegionDetailDTO[] => {
  const result: SubRegionDetailDTO[] = [];
  for (const region of regions) {
    result.push(...region.subRegions);
  }
  return result;
};

export function toGroupedCountryDropdownOptions(regions: RegionInfoDTO[]): IDropdownOption<CountryInfoDTO>[] {
  const result: IDropdownOption<CountryInfoDTO>[] = [];
  const subRegions = regions
    .filter(region => Array.isArray(region.subRegions) && region.subRegions.length > 0)
    .reduce((previous, currentRegion) => previous.concat(currentRegion.subRegions), [] as SubRegionInfoDTO[])
    .sort((a, b) => a.name.localeCompare(b.name));
  for (const subRegion of subRegions) {
    if (!Array.isArray(subRegion.countries) || subRegion.countries.length === 0) {
      continue;
    }

    result.push({ key: `divider_subregion_${subRegion.name}`, text: subRegion.name, itemType: SelectableOptionMenuItemType.Header });
    for (const country of subRegion.countries) {
      result.push({ key: country.id, text: country.name, data: country });
    }
  }

  // for (const region of regions) {
  //   if (!Array.isArray(region.subRegions) || region.subRegions.length === 0) {
  //     continue;
  //   }

  //   for (const subRegion of region.subRegions) {
  //     if (!Array.isArray(subRegion.countries) || subRegion.countries.length === 0) {
  //       continue;
  //     }

  //     result.push({ key: `divider_subregion_${subRegion.name}`, text: subRegion.name, itemType: SelectableOptionMenuItemType.Header });
  //     for (const country of subRegion.countries) {
  //       result.push({ key: country.id, text: country.name, data: country });
  //     }
  //   }
  // }

  return result;
}
