import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from "@fluentui/react";
import { useAllContactTypesQuery, useArchiveContactType, useUnArchiveContactType } from "../contactTypeQueries";
import { useMemo } from "react";
import { useTranslator } from "../../../common/state/translatorState";
import { ContactTypeDetailDTO } from "../../../services/contactReportsApi";
import { useNavigate } from "react-router-dom";
import { useConfirmationDialog } from "../../../common/hooks/useConfirmationDialog";
import ContactTypeActionCell from "./ContactTypeActionCell";
import useToastNotification from "../../../common/hooks/useToastNotification";
import { useContactSystemTypeName } from "../contactTypeHooks";
import useErrorHandler from "../../../common/hooks/useErrorHandler";
import ExtendedShimmeredDetailsList from "../../../common/components/ExtendedShimmeredDetailsList";

export default function ContactTypeList() {
  const t = useTranslator();
  const navigate = useNavigate();
  const confirmationDialog = useConfirmationDialog();
  const toast = useToastNotification();
  const errorHandler = useErrorHandler();
  const contactSystemTypeName = useContactSystemTypeName();
  const { data: contactTypes } = useAllContactTypesQuery();
  const { mutate: archiveContactType } = useArchiveContactType();
  const { mutate: unarchiveContactType } = useUnArchiveContactType();

  const columns: IColumn[] = useMemo(
    (): IColumn[] => [
      {
        key: "name",
        fieldName: "name",
        name: t.ContactTypesList.GridColumn.Name.Label,
        minWidth: 200,
        onRender: (item: ContactTypeDetailDTO) => <span className={item.isActive === true ? "" : "line-through"}>{item.name}</span>,
        headerClassName: "non-sortable",
      },
      {
        key: "contactSystemType",
        fieldName: "contactSystemType",
        name: t.ContactTypesList.GridColumn.ContactSystemType.Label,
        minWidth: 200,
        maxWidth: 200,
        onRender: (item: ContactTypeDetailDTO) => <span>{contactSystemTypeName(item.contactSystemType)} </span>,
        headerClassName: "non-sortable",
      },
      {
        key: "actions",
        name: t.ContactTypesList.GridColumn.Action.Label,
        minWidth: 100,
        maxWidth: 100,
        onRender: (item: ContactTypeDetailDTO) => (
          <ContactTypeActionCell
            contactType={item}
            archive={(id) =>
              archiveContactType(id, {
                onSuccess: () => toast.showSuccess(t.Components.ToastMessage.RecordArchived.Title),
                onError: errorHandler,
              })
            }
            unarchive={(id) =>
              unarchiveContactType(id, {
                onSuccess: () => toast.showSuccess(t.Components.ToastMessage.RecordUnarchived.Title),
                onError: errorHandler,
              })
            }
            navigate={navigate}
            confirmationDialog={confirmationDialog}
          />
        ),
        headerClassName: "non-sortable",
      },
    ],
    [t, toast, confirmationDialog, archiveContactType, unarchiveContactType, navigate, contactSystemTypeName, errorHandler]
  );

  return (
    <ExtendedShimmeredDetailsList
      items={contactTypes ?? []}
      compact={true}
      columns={columns}
      selectionMode={SelectionMode.none}
      getKey={(item: ContactTypeDetailDTO) => item.id.toString()}
      setKey="none"
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible={true}
    />
  );
}
