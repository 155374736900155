import { PrimaryButton } from "@fluentui/react";
import PageHeader from "../../../common/components/PageHeader";
import { useTranslator } from "../../../common/state/translatorState";
import { useNavigate } from "react-router-dom";

const ContactReportWelcomeScreen = () => {
  const t = useTranslator();
  const navigate = useNavigate();

  return (
    <>
      <PageHeader>{t.HomePage.Header.Title}</PageHeader>
      <div dangerouslySetInnerHTML={{ __html: t.HomePage.Content.Description }}></div>
      <div className="flex flex-row justify-center align-center">
        <PrimaryButton onClick={() => navigate("/contact-reports/new")}>{t.HomePage.Button.CreateContactReport}</PrimaryButton>
      </div>
    </>
  );
};

export default ContactReportWelcomeScreen;
