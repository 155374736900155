import { createContext, useContext, useState } from "react";
import { useTranslator } from "../state/translatorState";

type ConfirmationDialogState = {
  title: string;
  body: string;
  type: ConfirmationDialogType;
  isOpen: boolean;
  hasCancel: boolean;
  confirm: (_: unknown) => any;
  cancel: (_: unknown) => any;
  confirmButtonText: string;
  cancelButtonText: string;
};

type ConfirmationDialogType = "info" | "warning" | "danger";

const initialState: ConfirmationDialogState = {
  title: "",
  body: "",
  type: "danger",
  isOpen: false,
  hasCancel: true,
  confirm: () => {},
  cancel: () => {},
  confirmButtonText: "",
  cancelButtonText: "",
};

export const ConfirmationContext = createContext([initialState, (_: ConfirmationDialogState) => {}] as const);

export const ConfirmationDialogContextProvider: React.FC = ({ children }) => {
  const [confirm, setConfirm] = useState(initialState);

  return <ConfirmationContext.Provider value={[confirm, setConfirm]}>{children}</ConfirmationContext.Provider>;
};

export function useConfirmationDialog() {
  const [confirm, setConfirm] = useContext(ConfirmationContext);
  const t = useTranslator();

  const isConfirmed = (
    title: string,
    body: string,
    confirmButtonText?: string,
    cancelButtonText?: string,
    type?: ConfirmationDialogType
  ) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        ...confirm,
        title,
        body,
        type: type ?? "danger",
        isOpen: true,
        hasCancel: true,
        confirm: resolve,
        cancel: reject,
        confirmButtonText: confirmButtonText ?? t.Components.Button.Yes.Label,
        cancelButtonText: cancelButtonText ?? t.Components.Button.No.Label,
      });
    });

    return promise.then(
      () => {
        setConfirm(initialState);
        return true;
      },
      () => {
        setConfirm(initialState);
        return false;
      }
    );
  };

  const showDialog = (title: string, body: string, confirmButtonText?: string, type?: ConfirmationDialogType) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        ...confirm,
        title,
        body,
        type: type ?? "info",
        isOpen: true,
        hasCancel: false,
        confirm: resolve,
        cancel: reject,
        confirmButtonText: confirmButtonText ?? t.Components.Button.Yes.Label,
        cancelButtonText: "",
      });
    });

    return promise.then(
      () => {
        setConfirm(initialState);
        return true;
      },
      () => {
        setConfirm(initialState);
        return false;
      }
    );
  };

  return {
    ...confirm,
    isConfirmed,
    showDialog,
  };
}

export type ConfirmationDialogActions = Pick<ReturnType<typeof useConfirmationDialog>, "isConfirmed">;
