import { IPivotStyleProps, IPivotStyles, IStyleFunctionOrObject, Link, Pivot, PivotItem, Spinner, SpinnerSize } from "@fluentui/react";
import styled from "styled-components";
import useErrorHandler from "../../../common/hooks/useErrorHandler";
import { Translator, useTranslator } from "../../../common/state/translatorState";
import { downloadFile } from "../../../common/utilities";
import {
  ContactReportContentDetailDTO,
  ContactReportOriginalContentDetailDTO,
  ContactReportTranslatedContentDetailDTO,
  ContactReportsClient,
  TranslationStatus,
  UploadedFileDetailDTO,
} from "../../../services/contactReportsApi";
import { useLanguageDefaultName } from "../../languages/languageHooks";
import { useContactReportDetail } from "../contactReportQueries";

const client = new ContactReportsClient();

const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
  text: { fontSize: "16px", fontWeight: "normal" },
};
const StyledPivot = styled(Pivot)`
  button[type="button"][role="tab"] {
    &::before {
      height: 1px;
    }
  }

  button[type="button"][role="tab"]:has(div.translating) {
    &:hover {
      background-color: transparent;
      cursor: initial;
    }
  }
`;

const TabContent = ({
  contactReportId,
  content,
  t,
  className,
  ...divProps
}: { contactReportId: number; content: ContactReportContentDetailDTO; t: Translator } & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  const errorHandler = useErrorHandler();

  const handleAttachmentDownloadClick = async (file: UploadedFileDetailDTO) => {
    try {
      const attachment = await client.downloadAttachment(contactReportId, file.id);
      downloadFile(attachment.data, attachment.fileName ?? `${t.ContactReportDetail.Field.Attachment.Label} - 1`);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className={`${className} flex flex-col ml-2`} {...divProps}>
      <div>
        <p className="text-base font-semibold mt-4 mb-2 form-label">{t.ContactReportDetail.Field.Subject.Label}</p>
        <p className="mt-0 form-value">{content.subject}</p>
      </div>

      <div className="flex-1">
        <p className="text-base font-semibold form-label mb-2">{t.ContactReportDetail.Field.Summary.Label}</p>
        <p className="mt-0 form-value display-linebreak">{content.summary}</p>
      </div>

      <div className="grid grid-cols-12 grid-rows-12 gap-x-4 mt-4">
        {content.fileAttachments?.map((a, index) => (
          <div className="col-span-12 md:col-span-6 xl:col-span-4" key={a.id}>
            <p className="mb-2">
              {t.ContactReportDetail.Field.Attachment.Label} #{index + 1}
            </p>
            <Link onClick={() => handleAttachmentDownloadClick(a)}>
              {a.fileName}
              {a.extension}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

type Props = {
  contactReportId: number;
  status: TranslationStatus;
  originalContent: ContactReportOriginalContentDetailDTO;
  translatedContents: ContactReportTranslatedContentDetailDTO[];
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ContactReportDetailTexts = ({ contactReportId, status, originalContent, translatedContents, className, ...divProps }: Props) => {
  const t = useTranslator();
  const languageDefaultName = useLanguageDefaultName();
  const isTranslationPending = status === TranslationStatus.Pending && translatedContents.length === 0;

  //refresh this detail every 5 seconds while translation is still pending
  useContactReportDetail(contactReportId, { enabled: isTranslationPending, refetchInterval: 5000, staleTime: 5000 });

  const renderPendingTranslationPivot = () => (
    <PivotItem
      headerText={t.ContactReportDetail.ContentTab.Translating.Title}
      headerButtonProps={{
        disabled: true,
        style: { color: "grey" },
      }}
      onRenderItemLink={(itemProps, defaultRenderer) =>
        itemProps && defaultRenderer ? (
          <div className="flex flex-row items-center gap-x-2 translating">
            <Spinner size={SpinnerSize.xSmall} />
            {defaultRenderer(itemProps)}
          </div>
        ) : null
      }
    ></PivotItem>
  );

  return (
    <>
      <div className={`${className} no-print`} {...divProps}>
        <StyledPivot styles={pivotStyles}>
          <PivotItem key={originalContent.id} headerText={t.ContactReportDetail.ContentTab.Original.Title}>
            <TabContent contactReportId={contactReportId} content={originalContent} t={t} />
          </PivotItem>
          {isTranslationPending && renderPendingTranslationPivot()}
          {translatedContents.map((c) => (
            <PivotItem key={c.id} headerText={c.language ? languageDefaultName(c.language) : ""}>
              <TabContent contactReportId={contactReportId} content={c} t={t} />
            </PivotItem>
          ))}
        </StyledPivot>
      </div>
      <div className="flex flex-col only-print">
        <p className="text-lg mt-4">{t.ContactReportDetail.ContentTab.Original.Title}</p>
        <TabContent key={originalContent.id} contactReportId={contactReportId} content={originalContent} t={t} />
        {translatedContents.map((c) => (
          <>
            <p className="text-lg mt-4">{c.language ? languageDefaultName(c.language) : ""}</p>
            <TabContent contactReportId={contactReportId} key={c.id} content={c} t={t} />
          </>
        ))}
      </div>
    </>
  );
};

export default ContactReportDetailTexts;
