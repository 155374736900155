import { zodResolver } from "@hookform/resolvers/zod";
import { FieldArrayWithId, useFieldArray, useForm } from "react-hook-form";
import { ContactSystemType, ContactTypeCreateUpdateDTO, LanguageInfoDTO } from "../../../services/contactReportsApi";
import { useContactTypeSchema } from "../contactTypeSchemas";
import { ControlledTextField } from "../../../common/components/form/ControlledTextField";
import { nameof } from "../../../common/utilities";
import { useTranslator } from "../../../common/state/translatorState";
import { DefaultButton, IDropdownOption, Label, PrimaryButton, Stack } from "@fluentui/react";
import FlagIcon from "../../../common/components/FlagIcon";
import { ControlledDropdown } from "../../../common/components/form/ControlledDropdown";
import { useMemo } from "react";
import { useContactSystemTypeName } from "../contactTypeHooks";

type FlagLabelProps = {
  label: string;
  countryCode: string;
};

const FlagLabel = ({ label, countryCode }: FlagLabelProps) => (
  <Label>
    <FlagIcon className="mr-1" countryCode={countryCode} /> {label}
  </Label>
);

type Props = {
  contactType: ContactTypeCreateUpdateDTO;
  languages: LanguageInfoDTO[];
  defaultLanguage: LanguageInfoDTO;
  disabled: boolean;
  editable: boolean;
  onCancel: () => any;
  onSubmit: (item: ContactTypeCreateUpdateDTO) => any;
};

const ContactTypeDetailForm = ({ contactType, languages, defaultLanguage, disabled, editable, onSubmit, onCancel }: Props) => {
  const t = useTranslator();
  const contactTypeSchema = useContactTypeSchema();
  const contactSystemTypeName = useContactSystemTypeName();
  const contactSystemTypeOptions: IDropdownOption[] = useMemo(
    () => [
      { key: ContactSystemType.InPerson, text: contactSystemTypeName(ContactSystemType.InPerson) },
      { key: ContactSystemType.Remote, text: contactSystemTypeName(ContactSystemType.Remote) },
    ],
    [contactSystemTypeName]
  );

  const { control, handleSubmit } = useForm<ContactTypeCreateUpdateDTO>({
    defaultValues: contactType,
    resolver: zodResolver(contactTypeSchema),
  });
  const { fields } = useFieldArray({ control, name: "nameTranslations" });

  const onSubmitInner = handleSubmit((data) => onSubmit(data));

  const renderLanguageField = (field: FieldArrayWithId<ContactTypeCreateUpdateDTO, "nameTranslations", "id">, index: number) => {
    const language = languages.find((l) => l.id === field.languageId);
    if (!language) {
      return null;
    }

    return (
      <div key={`language_${field.languageId}`}>
        <ControlledTextField
          name={`${nameof<ContactTypeCreateUpdateDTO>("nameTranslations")}[${index}].text`}
          control={control}
          onRenderLabel={() => <FlagLabel label={language.defaultName} countryCode={language.icon} />}
          disabled={disabled}
          readOnly={!editable}
        />
      </div>
    );
  };

  return (
    <div>
      <ControlledTextField
        name={nameof<ContactTypeCreateUpdateDTO>("name")}
        control={control}
        onRenderLabel={() => (
          <FlagLabel
            label={`${t.ContactTypeDetail.Field.Name.Label} (${defaultLanguage.defaultName})`}
            countryCode={defaultLanguage.icon}
          />
        )}
        disabled={disabled}
        readOnly={!editable}
        autoFocus
      />
      <ControlledDropdown
        name={nameof<ContactTypeCreateUpdateDTO>("contactSystemType")}
        control={control}
        options={contactSystemTypeOptions}
        label={t.ContactTypeDetail.Field.ContactSystemType.Label}
      />
      <h3>{t.ContactTypeDetail.NameTranslations.Title}</h3>
      {fields.map(renderLanguageField)}
      <Stack horizontal className="mt-4 gap-4 flex-row-reverse">
        <DefaultButton text={t.Components.Button.Cancel.Label} onClick={onCancel} disabled={disabled && editable} />
        {editable && <PrimaryButton text={t.Components.Button.Save.Label} onClick={onSubmitInner} disabled={disabled} />}
      </Stack>
    </div>
  );
};

export default ContactTypeDetailForm;
