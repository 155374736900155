import { Dropdown, IDropdownProps, IIconStyles, Icon, Stack } from "@fluentui/react";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { HookFormProps } from "../../model/hookFormProps";
import { RequiredFieldProps } from "../../model/requiredFieldProps";
import FormRequiredLabel from "./FormRequiredLabel";
import { ClearableFieldProps } from "../../model/clearableFieldProps";

const dropdownIconStyles: IIconStyles = {
  root: {
    color: "rgb(96, 94, 92)",
    "&:hover": {
      fontWeight: 800,
    },
  },
};

export const ControlledDropdown: FC<HookFormProps & ClearableFieldProps & Omit<IDropdownProps, "required"> & RequiredFieldProps> = ({required, ...props}) => {
  const renderCaretDownWithClear = (value: any, onChange: (...event: any[]) => any) => (
    <Stack horizontal verticalAlign={"center"}>
      {value !== props.defaultValue && value !== null && value !== undefined && props.showClear !== false && (
        <Icon
          iconName={"Cancel"}
          className="mr-2"
          styles={dropdownIconStyles}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onChange(props.defaultValue);
          }}
        />
      )}
      <Icon iconName={"ChevronDown"} styles={dropdownIconStyles} />
    </Stack>
  );

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        return (
          <Dropdown
            {...props}
            selectedKey={value ?? null}
            onChange={(_e, option) => {
              if (option) {
                onChange(option.key);
              }
            }}
            onBlur={onBlur}
            errorMessage={error && error.message}
            defaultValue={undefined}
            onRenderLabel={(dropdownFieldProps) => <FormRequiredLabel label={dropdownFieldProps?.label} required={required} />}
            onRenderCaretDown={() => renderCaretDownWithClear(value, onChange)}
          />
        );
      }}
    />
  );
};
