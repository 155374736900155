import { z } from "zod";
import { HierarchyInfoDTO } from "../../services/contactReportsApi";

const HierarchyInfoSchema: z.ZodType<HierarchyInfoDTO> = z.lazy(() =>
  z.object({
    id: z.number(),
    name: z.string(),
    isActive: z.boolean(),
    isActiveComputed: z.boolean(),
    parent: HierarchyInfoSchema.optional().nullable(),
  })
);

export type HierarchyInfo = z.infer<typeof HierarchyInfoSchema>;

export default HierarchyInfoSchema;
