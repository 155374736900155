import { z } from "zod";
import { useTranslator } from "../../common/state/translatorState";
import { useMemo } from "react";
import { UploadedFileDetailSchema } from "../../common/model/uploadedFileDetailSchema";
import { ContactReportListQueryDTO, ContactReportStatus, ContactSystemType, ContactTypeInfoDTO } from "../../services/contactReportsApi";
import { QueryMetadata } from "../../common/hooks/useQueryMetadata";
import AdUserSearchResultSchema from "../../common/model/adUserSearchResultSchema";

function useContactReportAttendeeSchema() {
  const t = useTranslator();

  return useMemo(
    () =>
      z.object({
        uniqueId: z.string(),
        name: z.string().nonempty(t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired),
        phoneNumber: z
          .string()
          .nonempty(t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired)
          .max(20, t.ContactReportCreateUpdateDTO.ContactReport.Field.MaxLengthExceeded("", "20")),
        email: z
          .string()
          .nonempty(t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired)
          .email(t.ContactReportCreateUpdateDTO.ContactReport.Field.InvalidEmail)
          .max(100, t.ContactReportCreateUpdateDTO.ContactReport.Field.MaxLengthExceeded("", "100")),
      }),
    [t]
  );
}

export function useContactReportSchema(contactTypes: ContactTypeInfoDTO[]) {
  const t = useTranslator();
  const attendeeSchema = useContactReportAttendeeSchema();

  return useMemo(() => {
    return z
      .object({
        contactTypeId: z.number().min(1, t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired),
        attendee: attendeeSchema,
        attendeeCompanyId: z.number().min(1, t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired),
        submitterName: z.string(),
        submitterEmail: z
          .string()
          .nonempty(t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired)
          .email(t.ContactReportCreateUpdateDTO.ContactReport.Field.InvalidEmail)
          .max(100, t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired),
        submitterPhone: z
          .string()
          .nonempty(t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired)
          .max(20, t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired),
        meetingDate: z.date({ required_error: t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired }),
        meetingAddress: z.string().max(100, t.ContactReportCreateUpdateDTO.ContactReport.Field.MaxLengthExceeded("", "100")),
        meetingCountryId: z.number().min(1, t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired),
        competitorCompanyName: z
          .string()
          .nonempty(t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired)
          .max(100, t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired),
        attendees: z
          .string()
          .nonempty(t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired)
          .max(500, t.ContactReportCreateUpdateDTO.ContactReport.Field.MaxLengthExceeded("", "500")),
        subject: z
          .string()
          .nonempty(t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired)
          .max(500, t.ContactReportCreateUpdateDTO.ContactReport.Field.MaxLengthExceeded("", "500")),
        summary: z.string().nonempty(t.ContactReportCreateUpdateDTO.ContactReport.Field.IsRequired),
        attachment1: UploadedFileDetailSchema.nullable().optional(),
        attachment2: UploadedFileDetailSchema.nullable().optional(),
      })
      .refine(
        (data) => {
          const isMeetingAddressValid =
            data.contactTypeId <= 0 ||
            data.meetingAddress.length > 0 ||
            contactTypes.some((ct) => ct.id === data.contactTypeId && ct.contactSystemType === ContactSystemType.Remote);
          return isMeetingAddressValid;
        },
        { path: ["meetingAddress"], message: t.ContactReportCreateUpdateDTO.ContactReport.MeetingAddress.IsRequired }
      );
  }, [t, attendeeSchema, contactTypes]);
}

const ContactReportQueryTypeSchema = z.union([z.literal("my"), z.literal("all"), z.literal("on-behalf-of"), z.literal("to-be-reviewed")]);
export const ContactReportQueryFilterSchema = z.object({
  meetingDateFrom: z.date().nullable().default(null),
  meetingDateTo: z.date().nullable().default(null),
  status: z.nativeEnum(ContactReportStatus).nullable().default(null),
  attendee: AdUserSearchResultSchema.nullable().default(null),
  attendeeCompanyId: z.number().nullable().default(null),
  search: z.string().default(""),
  includingAttachments: z.boolean().default(false),
  hasAttachment: z.boolean().default(false)
});

export const ReviewerNotesSchema = z.object({
  reviewerNotes: z.string().optional(), //conditional validation has to be done in form itself
});

//TODO: should we call types with Schema suffix or not?
export type ContactReportSchema = z.infer<ReturnType<typeof useContactReportSchema>>;
export type ContactReportAttendeeSchema = z.infer<ReturnType<typeof useContactReportAttendeeSchema>>;
export type ReviewerNotes = z.infer<typeof ReviewerNotesSchema>;
export type ContactReportQueryType = z.infer<typeof ContactReportQueryTypeSchema>;
export type ContactReportQueryFilter = z.infer<typeof ContactReportQueryFilterSchema>;
export type ContactReportQueryMetadata = QueryMetadata<ContactReportListQueryDTO, ContactReportQueryFilter> & {
  type: ContactReportQueryType;
};
