import {
  ContactReportQueryFilter,
  ContactReportQueryFilterSchema,
  ContactReportQueryMetadata,
  ContactReportQueryType,
} from "./contactReportSchemas";
import { useCallback, useEffect } from "react";
import { ContactReportListQueryDTO } from "../../services/contactReportsApi";
import { useRecoilState, useResetRecoilState } from "recoil";
import { createQueryMetadataState } from "../../common/hooks/useQueryMetadata";

const defaultMetadata: ContactReportQueryMetadata = {
  type: "my",
  filter: ContactReportQueryFilterSchema.parse({}),
  sort: { fields: {} },
  page: { offset: 0, limit: 50 },
};

const { metadataState, useQueryFilter, useQueryPaging, useQuerySort } = createQueryMetadataState<
  ContactReportListQueryDTO,
  ContactReportQueryFilter,
  ContactReportQueryMetadata
>("contactReportQueryMetadata", defaultMetadata);

function useContactReportQueryMetadata(type: ContactReportQueryType) {
  const [metadata, setMetadata] = useRecoilState(metadataState);

  const resetToType = useCallback(
    (type: ContactReportQueryType) => {
      setMetadata({ ...defaultMetadata, type });
    },
    [setMetadata]
  );

  //when type of list changes we reset type & filter to default values
  useEffect(() => {
    if (metadata.type !== type) {
      resetToType(type);
    }
  }, [metadata.type, type, resetToType]);

  return metadata;
}

const useResetContactReportQueryFilter = () => useResetRecoilState(metadataState);

export {
  metadataState as contactreportQueryMetadataState,
  useContactReportQueryMetadata,
  useResetContactReportQueryFilter,
  useQuerySort as useContactReportQuerySort,
  useQueryFilter as useContactReportQueryFilter,
  useQueryPaging as useContactReportQueryPaging,
};
