import { useQuery, UseQueryOptions } from "react-query";
import { LocalizedString, SystemsClient } from "../../services/contactReportsApi";
import { useSetTranslator } from "../state/translatorState";

const client = new SystemsClient();

export const translationQueryKeys = {
  all: ["system"] as const,
  translations: () => [...translationQueryKeys.all, "translations"] as const,
  translationKey: (key: string) => [...translationQueryKeys.translations(), key] as const,
};

export type AllLocalizedStrings = {
  [key: string]: {
    [key: string]: LocalizedString;
  };
};

export type SingleLocalizedString = {
  [key: string]: LocalizedString;
};

export const useTranslationsQuery = (
  options?: Omit<
    UseQueryOptions<AllLocalizedStrings, unknown, AllLocalizedStrings, readonly ["system", "translations"]>,
    "queryKey" | "queryFn" | "onSuccess"
  >
) => {
  const setTranslator = useSetTranslator();
  return useQuery(translationQueryKeys.translations(), () => client.getAllLocalizedStrings(), {
    ...options,
    onSuccess: (data) => {
      setTranslator(data);
    },
  });
};

export const useTranslationKeyQuery = (
  resourceKey: string,
  options?: Omit<
    UseQueryOptions<SingleLocalizedString, unknown, SingleLocalizedString, readonly ["system", "translations", string]>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(translationQueryKeys.translationKey(resourceKey), () => client.getLocalizedStrings(resourceKey), options);
};
