import { useNavigate } from "react-router-dom";
import useNumberRouteParams from "../../../common/hooks/useNumberRouteParams";
import PageHeader from "../../../common/components/PageHeader";
import { useTranslator } from "../../../common/state/translatorState";
import { useUserDetailsQuery } from "../userQueries";
import UserForm from "../components/UserForm";

const UserDetailsScreen = () => {
  const { id } = useNumberRouteParams("id");
  const t = useTranslator();
  const navigate = useNavigate();

  const { data: user, isError: detailFailed } = useUserDetailsQuery(id, { enabled: Number.isInteger(id) && id > 0 });

  if (!Number.isInteger(id)) {
    navigate("/users", { replace: true });
    return null;
  }

  if (!user) {
    if (detailFailed) {
      navigate("/users/list", { replace: true });
    }

    return null;
  }

  return (
    <>
      <PageHeader className="mb-2">{t.SystemUserDetail.Header.SystemUserEdit.Title(user.displayName ?? "")}</PageHeader>
      <UserForm user={user} />
    </>
  );
};

export default UserDetailsScreen;
