import { atom } from "recoil";

export const errorDialogState = atom({
  key: "errorDialog",
  default: {
    isVisible: false,
    title: "",
    errors: new Array<string>(),
  },
});
