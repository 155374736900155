import { IListProps, List } from "@fluentui/react";
import { ContactReportStatusHistoryDetailDTO } from "../../../services/contactReportsApi";
import { formatDateTime } from "../../../common/utilities";
import { useContactReportStatusName } from "../contactReportHooks";
import { useCallback, useMemo } from "react";

type TranslatedHistoryRecord = {
  statusName: string;
} & ContactReportStatusHistoryDetailDTO;

type Props = {
  historyRecords: ContactReportStatusHistoryDetailDTO[];
} & IListProps;

const StatusHistory = ({ historyRecords, ...listProps }: Props) => {
  const getStatusName = useContactReportStatusName();

  //to force re-render of list becuase it does so only when items collection changes
  const translatedHistoryRecords: TranslatedHistoryRecord[] = useMemo(
    () => historyRecords.map((h) => ({ ...h, statusName: getStatusName(h.status) })),
    [getStatusName, historyRecords]
  );

  const onRenderCell = useCallback((item?: TranslatedHistoryRecord): JSX.Element => {
    if (!item) {
      return <></>;
    }
    return (
      <div className="my-2">
        {formatDateTime(item.changedOn)} - {item.statusName}{" "}
        {item.changedBy && item.changedBy.displayName ? `(${item.changedBy.displayName})` : ""}
      </div>
    );
  }, []);

  return <List items={translatedHistoryRecords} onRenderCell={onRenderCell} {...listProps} />;
};

export default StatusHistory;
