import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import { userQueryMetadataState } from "../userState";

const UserBaseScreen = () => {
  const resetMetadata = useResetRecoilState(userQueryMetadataState);

  useEffect(() => {
    return () => {
      resetMetadata();
    };
  }, []);

  return <Outlet />;
};

export default UserBaseScreen;
