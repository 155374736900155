import { RouteObject } from "react-router-dom";
import RouterErrorScreen from "../common/screens/RouterErrorScreen";

export const globalRoutes: RouteObject[] = [
  {
    id: "unauthorized",
    path: "unauthorized",
    element: <RouterErrorScreen type="unauthorized" />,
  },
  {
    id: "error",
    path: "*",
    element: <RouterErrorScreen type="not-found" />,
  },
];
