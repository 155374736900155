import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { TranslationProvider, createTranslator } from "../../app/createTranslator";
import { AllLocalizedStrings } from "../queries/translationQueries";

const replaceTranslationParameters = (input: string, valuesToReplace: string[]): string => {
  for (let i = 0; i < valuesToReplace.length; i++) {
    input = input.replace(new RegExp(`{(${i})}`), valuesToReplace[i]);
  }
  return input;
};

class DefaultTransationProvider implements TranslationProvider {
  constructor(private showKeys: boolean) {}

  getTranslatedValue(resourceGroup: string, resourceKey: string, defaultValue: string, valuesToReplace?: string[] | undefined): string {
    let replacedValue =
      Array.isArray(valuesToReplace) && valuesToReplace.length > 0
        ? replaceTranslationParameters(defaultValue, valuesToReplace)
        : defaultValue;
    if (this.showKeys) {
      replacedValue += ` [${resourceGroup}.${resourceKey}]`;
    }
    return replacedValue;
  }
}

class LocalizedStringsTranslationProvider implements TranslationProvider {
  constructor(private localizedStrings: AllLocalizedStrings, private showKeys: boolean) {}

  getTranslatedValue(resourceGroup: string, resourceKey: string, defaultValue: string, valuesToReplace?: string[] | undefined): string {
    const value = this.localizedStrings[resourceGroup]?.[resourceKey]?.value ?? defaultValue;
    let replacedValue =
      Array.isArray(valuesToReplace) && valuesToReplace.length > 0 ? replaceTranslationParameters(value, valuesToReplace) : value;
    if (this.showKeys) {
      replacedValue += ` [${resourceGroup}.${resourceKey}]`;
    }
    return replacedValue;
  }
}

const translatorState = atom({
  key: "translator",
  default: {
    showKeys: false,
    translator: createTranslator(new DefaultTransationProvider(false)),
  },
});

function useSetTranslator() {
  const setState = useSetRecoilState(translatorState);
  return (localizedStrings: AllLocalizedStrings) => {
    console.log("Setting translator");
    setState((state) => ({
      ...state,
      translator: createTranslator(new LocalizedStringsTranslationProvider(localizedStrings, state.showKeys)),
    }));
  };
}

function useTranslator() {
  return useRecoilValue(translatorState).translator;
}

export type Translator = ReturnType<typeof useTranslator>;

export { translatorState, useTranslator, useSetTranslator };
