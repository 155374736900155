import { useMemo } from "react";
import { Params, useParams } from "react-router-dom";

const parseNumberParam = (params: Readonly<Params<string>>, paramName: string): number => {
  let value = params[paramName];
  if (value) {
    return parseInt(value, 10);
  }

  return Number.NaN;
};

export default function useNumberRouteParams(param1: string, param2?: string, param3?: string, param4?: string): Record<string, number> {
  const params = useParams();
  return useMemo(() => {
    const result: Record<string, number> = {};
    let parsed: number;

    if (param1 && !Number.isNaN((parsed = parseNumberParam(params, param1)))) {
      result[param1] = parsed;
    }
    if (param2 && !Number.isNaN((parsed = parseNumberParam(params, param2)))) {
      result[param2] = parsed;
    }
    if (param3 && !Number.isNaN((parsed = parseNumberParam(params, param3)))) {
      result[param3] = parsed;
    }
    if (param4 && !Number.isNaN((parsed = parseNumberParam(params, param4)))) {
      result[param4] = parsed;
    }

    return result;
  }, [params]);
}
