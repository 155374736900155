import { TooltipHost } from "@fluentui/react";
import { HierarchyInfoDTO } from "../../../services/contactReportsApi";
import { memo } from "react";

type Props = {
  context?: HierarchyInfoDTO | null;
};

const resolveFullHierarchy = (context: HierarchyInfoDTO, partsSoFar: string[]): string[] => {
  partsSoFar.unshift(context.name);
  return context.parent ? resolveFullHierarchy(context.parent, partsSoFar) : partsSoFar;
};

const UserRoleContextCell = ({ context }: Props) => {
  if (!context) {
    return null;
  }

  const fullHierarchy = resolveFullHierarchy(context, []).join(" -> ");

  return (
    <TooltipHost content={fullHierarchy}>
      <span>{fullHierarchy}</span>
    </TooltipHost>
  );
};

export default memo(UserRoleContextCell);
