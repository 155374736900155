import { createTheme, PartialTheme } from "@fluentui/react";

//can be further customized here: https://fluentuipr.z22.web.core.windows.net/heads/master/theming-designer/index.html

const customizations: PartialTheme = {
  palette: {
    themePrimary: '#e10234',
    themeLighterAlt: '#fef4f6',
    themeLighter: '#fad2db',
    themeLight: '#f6adbd',
    themeTertiary: '#ed607f',
    themeSecondary: '#e41d48',
    themeDarkAlt: '#ca022d',
    themeDark: '#ab0226',
    themeDarker: '#7e011c',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#bab8b7',
    neutralSecondary: '#a3a2a0',
    neutralSecondaryAlt: '#a3a2a0',
    neutralPrimaryAlt: '#8d8b8a',
    neutralPrimary: '#323130',
    neutralDark: '#605e5d',
    black: '#494847',
    white: '#ffffff',
  }
};

const theme = createTheme(customizations);
export default theme;
