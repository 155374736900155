import { useRecoilValue } from "recoil";
import { ToastNotificatonPosition, toastNotificationState } from "../../state/toastNotificationState";
import styled from "styled-components";
import ToastNotification from "./ToastNotification";

const NotificationContainer = styled.div`
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 9999999;

  &.top-right {
    top: 12px;
    right: 12px;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
  }

  &.bottom-right {
    bottom: 12px;
    right: 12px;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
  }

  &.top-left {
    top: 12px;
    left: 12px;
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
  }

  &.bottom-left {
    bottom: 12px;
    left: 12px;
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
  }

  &.top-left,
  &.top-right {
    & .toast-notification {
      margin-bottom: 0.5rem;
    }
  }

  &.bottom-left,
  &.bottom-right {
    & .toast-notification {
      margin-top: 0.5rem;
    }
  }
`;

const ToastNotificationList = () => {
  const state = useRecoilValue(toastNotificationState);

  const renderNotifications = (position: ToastNotificatonPosition) =>
    state.notifications
      .filter((n) => n.position == position)
      .map((notification, index) => <ToastNotification key={index} notification={notification} />);

  return (
    <>
      <NotificationContainer className="top-right">{renderNotifications("top-right")}</NotificationContainer>
      <NotificationContainer className="top-left">{renderNotifications("top-left")}</NotificationContainer>
      <NotificationContainer className="bottom-right">{renderNotifications("bottom-right")}</NotificationContainer>
      <NotificationContainer className="bottom-left">{renderNotifications("bottom-left")}</NotificationContainer>
    </>
  );
};

export default ToastNotificationList;
