import { IModalProps, IconButton, Modal } from "@fluentui/react";
import styled from "styled-components";
import { cancelIcon } from "../Icons";

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding-top: 1rem;

  & p {
    margin: 0;
  }
`;

type Props = {
  title: string;
  isOpen: boolean;
  onCancel: () => any;
  children?: JSX.Element;
  footer?: JSX.Element;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
} & Omit<IModalProps, "styles">;

export default function EditDialog({ title, isOpen, onCancel, children, footer, height, minHeight, maxHeight, ...modalProps }: Props) {
  return (
    <Modal isOpen={isOpen} isBlocking={true} styles={{ scrollableContent: { height, minHeight, maxHeight } }} {...modalProps}>
      <ModalContent className="min-h-full">
        <Header>
          <p>{title}</p>
          <IconButton iconProps={cancelIcon} onClick={onCancel} />
        </Header>
        <div className="flex-auto pb-4">{children}</div>
        {footer && <div className="shrink-0 pb-4">{footer}</div>}
      </ModalContent>
    </Modal>
  );
}
