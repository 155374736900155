import { atom } from "recoil";
import { ADUserSearchResult } from "../../services/contactReportsApi";

const userSearchState = atom({
  key: "userSearch",
  default: {
    isVisible: false,
    query: "",
    selectedUser: null as null | ADUserSearchResult,
    confirm: (_selectedUser: ADUserSearchResult) => {},
    cancel: () => {},
  },
});

export { userSearchState };
