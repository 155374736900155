import { z } from "zod";

const AdUserSearchResultSchema = z.object({
  uniqueId: z.string(),
  loginName: z.string().nullable().optional(),
  firstName: z.string().nullable().optional(),
  lastName: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
  jobTitle: z.string().nullable().optional(),
  mobilePhone: z.string().nullable().optional(),
  fullName: z.string().optional().optional(),
});

export default AdUserSearchResultSchema;
