import { DefaultButton, IComboBoxOptionStyles, PrimaryButton } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ControlledCombobox } from "../../../common/components/form/ControlledComboBox";
import { nameof } from "../../../common/utilities";
import { useTranslator } from "../../../common/state/translatorState";
import { OrganizationHierarchyInfo, useOrganizationHierarchyComboBoxOptions } from "../../organizations/organizationHooks";
import theme from "../../../app/theme";
import { LocationHierarchyInfo, useLocationHierarchyComboBoxOptions } from "../../locations/locationHooks";
import { ControlledTextField } from "../../../common/components/form/ControlledTextField";
import { ApproverJurisdictionInfoDTO } from "../../../services/contactReportsApi";

const FirstLevelComboBoxStyles: Partial<IComboBoxOptionStyles> = {
  root: { color: theme.palette.themePrimary, fontWeight: 600 },
};
const SecondLevelComboBoxStyles: Partial<IComboBoxOptionStyles> = {
  root: { paddingLeft: "1rem" },
};
const SubRegionComboBoxStyles: Partial<IComboBoxOptionStyles> = {
  ...SecondLevelComboBoxStyles,
  root: { ...(SecondLevelComboBoxStyles.root as object), fontWeight: 600 },
};
const ThirdLevelComboBoxStyles: Partial<IComboBoxOptionStyles> = {
  root: { paddingLeft: "2rem" },
};

type NewRoleForm = {
  roleName: string;
  locationId: string | null;
  organizationId: string | null;
};

type Props = {
  disabled: boolean;
  onRoleAdding: (isAdding: boolean) => void;
  onRoleAdded: (role: ApproverJurisdictionInfoDTO) => any;
  canceled: boolean;
};

const NewUserRole = ({ disabled, onRoleAdding, onRoleAdded, canceled: canceled }: Props) => {
  const t = useTranslator();

  const [addingRole, setAddingRole] = useState(false);
  const locations = useLocationHierarchyComboBoxOptions(FirstLevelComboBoxStyles, SubRegionComboBoxStyles, ThirdLevelComboBoxStyles);
  const organizations = useOrganizationHierarchyComboBoxOptions(FirstLevelComboBoxStyles, SecondLevelComboBoxStyles);

  const { control, handleSubmit, reset } = useForm<NewRoleForm>({
    defaultValues: {
      roleName: t.EnumValueFullName.SystemRole.Approver,
      locationId: null,
      organizationId: null,
    },
  });

  useEffect(() => {
    if(canceled)
      handleCancel();
  }, [canceled])

  const handleAdd = handleSubmit((newRole) => {
    const organization = organizations.find((o) => o.key === newRole.organizationId)?.data as OrganizationHierarchyInfo;
    const location = locations.find((l) => l.key === newRole.locationId)?.data as LocationHierarchyInfo;

    onRoleAdded({ organisation: organization, location: location });
    onRoleAdding(false);
    reset();
    setAddingRole(false);
  });

  const handleCancel = () => {
    reset();
    setAddingRole(false);
    onRoleAdding(false);
  };

  if (!addingRole) {
    return (
      <div className="flex flex-row justify-end items-end h-16 pr-2">
        <PrimaryButton onClick={() => { onRoleAdding(true); setAddingRole(true); }} disabled={disabled}>
          {t.SystemUserDetail.Button.AddRole.Label}
        </PrimaryButton>
      </div>
    );
  }

  return (
    <div className="flex flex-row items-end justify-between h-16">
      <div className="flex flex-row items-end  gap-4">
        <ControlledTextField
          label={t.SystemUserDetail.GridColumn.SystemRole.Label}
          control={control}
          name={nameof<NewRoleForm>("roleName")}
          readOnly
        />
        <ControlledCombobox
          label={t.SystemUserDetail.GridColumn.Organisation.Label}
          control={control}
          name={nameof<NewRoleForm>("organizationId")}
          options={organizations}
          defaultValue={undefined}
        />
        <ControlledCombobox
          label={t.SystemUserDetail.GridColumn.Location.Label}
          control={control}
          name={nameof<NewRoleForm>("locationId")}
          options={locations}
          defaultValue={undefined}
        />
      </div>

      <div className="flex flex-row items-end gap-x-2 pr-2">
        <PrimaryButton onClick={handleAdd}>{t.Components.Button.Confirm.Label}</PrimaryButton>
        <DefaultButton onClick={handleCancel}>{t.Components.Button.Cancel.Label}</DefaultButton>
      </div>
    </div>
  );
};

export default NewUserRole;
