import { IButtonStyles, IFocusZoneProps, INavLink, INavLinkGroup, INavProps, IconButton, Nav } from "@fluentui/react";
import { useMemo, useRef, useState } from "react";
import { Location, RouteObject, useLocation, useNavigate } from "react-router-dom";
import { allRoutes } from "../../routes";
import useAuthorization from "../hooks/useAuthorization";
import styled from "styled-components";
import { useTranslator } from "../state/translatorState";
import logo from "../../assets/images/logo.png";
import { useMediaQueryBreakpoints } from "../hooks/useMediaQueryBreakpoints";
import { menuIcon } from "./Icons";
import theme from "../../app/theme";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

const findRouteRecursive = (routeId: string, routes: RouteObject[]): RouteObject | undefined => {
  for (const route of routes) {
    if (route.id === routeId) {
      return route;
    }

    if (route.children && route.children.length > 0) {
      const found = findRouteRecursive(routeId, route.children);
      if (found) {
        return found;
      }
    }
  }
};

const findLinkIdByPath = (location: Location, links: INavLink[]): string | undefined => {
  for (const link of links) {
    if (link.key && new RegExp(link.key).test(location.pathname)) {
      return link.key;
    }

    if (link.links && link.links.length > 0) {
      const foundSubLink = findLinkIdByPath(location, link.links);
      if (foundSubLink) {
        return foundSubLink;
      }
    }
  }
};

const createLink = (matchRegex: string, routeId: string, name: string, showName: boolean, icon?: string): INavLink => {
  const route = findRouteRecursive(routeId, allRoutes);
  if (!route) {
    throw Error(`Could not find route with id: '${routeId}'`);
  }

  return {
    name: showName ? name : "",
    key: matchRegex,
    url: route.path ?? "",
    icon: icon,
    title: name,
  };
};

const Logo = styled.img`
  height: 5rem;
  margin: 0.5rem;
  margin-bottom: 1rem;
  background-color: ${theme.palette.white};
  z-index: 2;
`;

const CollapsibleNav = (props: INavProps & { isSmallScreen: boolean; isCollapsed: boolean }) => <Nav {...props} />;

const StyledNav = styled(CollapsibleNav)`
  min-width: ${(props) => (props.isSmallScreen && props.isCollapsed ? "2.4rem" : "16rem")};
  transition: ${(props) => (props.isSmallScreen && !props.isCollapsed ? "none" : "min-width 0.2s")};
  padding-top: ${(props) => (props.isSmallScreen ? "0.5rem" : "1.7rem")};
  max-height: ${(props) => (props.isSmallScreen && !props.isCollapsed ? "calc(100vh - 12rem)" : "calc(100vh - 10rem)")};

  & li a:hover {
    cursor: pointer;
  }

  & li {
    & a.ms-Button {
      padding-left: ${(props) => (props.isCollapsed ? "6px" : "2px")};
      padding-right: ${(props) => (props.isCollapsed ? "2px" : "44px")};
    }
  }
`;

const Container = styled.div<{ isSmallScreen: boolean; isCollapsed: boolean }>`
  height: calc(100vh - 3.1rem);
  position: ${(props) => (props.isSmallScreen && !props.isCollapsed ? "absolute" : "relative")};
  z-index: 2;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const focusZoneProps: IFocusZoneProps = { style: { height: "calc(100% - 9rem)" } };
const menuButtonStyles: IButtonStyles = { root: { margin: "0.25rem" } };

export default function NavigationMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSm } = useMediaQueryBreakpoints();
  const { roles } = useAuthorization();
  const t = useTranslator();
  const containerRef = useRef(null);

  const [isCollapsed, setIsCollapsed] = useState(isSm);

  useOnClickOutside(containerRef, () => setIsCollapsed(true));

  const navigationLinkGroups: INavLinkGroup[] = useMemo(() => {
    const group: INavLinkGroup = { links: [] };
    const showMenuItemText = !isSm || !isCollapsed;
    group.links.push(createLink("(^/$|/home)", "home", t.Menu.MenuItem.Home, showMenuItemText, "home"));
    group.links.push(
      createLink("/contact-reports/new.*", "contactReportsNew", t.Menu.MenuItem.CreateContactReport, showMenuItemText, "circleplus")
    );

    group.links.push(
      createLink("/contact-reports/list/my", "myContactReportsList", t.Menu.MenuItem.MyReports, showMenuItemText, "bulletedlist2")
    );
    group.links.push(
      createLink(
        "/contact-reports/list/on-behalf-of",
        "onBehalfOfContactReportsList",
        t.Menu.MenuItem.OnBehalfOf,
        showMenuItemText,
        "contactlist"
      )
    );
    if (roles.isAdmin || roles.isApprover) {
      group.links.push(
        createLink(
          "/contact-reports/list/to-be-reviewed",
          "toBeReviewedContactReportsList",
          t.Menu.MenuItem.ToBeReviewed,
          showMenuItemText,
          "documentsearch"
        )
      );
      group.links.push(
        createLink("/contact-reports/list/all", "allContactReportsList", t.Menu.MenuItem.AllReports, showMenuItemText, "documentation")
      );
    }
    if (roles.isAdmin) {
      group.links.push({
        ...createLink("/organizations.*", "organizations", t.Menu.MenuItem.Organisations, showMenuItemText, "companydirectory"),
      });
      group.links.push(createLink("/locations.*", "locations", t.Menu.MenuItem.Locations, showMenuItemText, "globe"));
      group.links.push(createLink("/contact-types.*", "contactTypes", t.Menu.MenuItem.ContactTypes, showMenuItemText, "contactcard"));
      group.links.push(createLink("/users.*", "userList", t.Menu.MenuItem.Users, showMenuItemText, "group"));
    }

    return [group];
  }, [isCollapsed, isSm, roles, t]);

  const selectedKey = useMemo(() => {
    const allLinks: INavLink[] = [];
    for (const group of navigationLinkGroups) {
      allLinks.push(...group.links);
    }
    return findLinkIdByPath(location, allLinks);
  }, [location, navigationLinkGroups]);

  return (
    <Container className="no-print" isSmallScreen={isSm} isCollapsed={isCollapsed} ref={containerRef}>
      {isSm && <IconButton styles={menuButtonStyles} iconProps={menuIcon} onClick={() => setIsCollapsed(!isCollapsed)} />}
      <StyledNav
        isCollapsed={isCollapsed}
        isSmallScreen={isSm}
        focusZoneProps={focusZoneProps}
        groups={navigationLinkGroups}
        selectedKey={selectedKey}
        onLinkClick={(e, element) => {
          if (e && element) {
            e.preventDefault();
            navigate(element.url);
            setIsCollapsed(true);
          }
        }}
      />
      {(!isSm || !isCollapsed) && <Logo src={logo} />}
    </Container>
  );
}
