import { useCallback, useMemo } from "react";
import { useCurrentUserQuery } from "../../features/users/userQueries";
import {
  ContactReportDetailDTO,
  ContactReportListQueryDTO,
  ContactReportStatus,
  SystemRole,
  SystemUserDetailDTO,
} from "../../services/contactReportsApi";

export const hasRoleFlag = (toCheck: SystemRole, flag: SystemRole) => {
  return (toCheck & flag) === flag;
};
const getExistingRoles = (user?: SystemUserDetailDTO): Set<SystemRole> => {
  const result = new Set<SystemRole>();
  if (user) {
    if (hasRoleFlag(user.systemRoles, SystemRole.Submitter)) {
      result.add(SystemRole.Submitter);
    }
    if (hasRoleFlag(user.systemRoles, SystemRole.Approver)) {
      result.add(SystemRole.Approver);
    }
    if (hasRoleFlag(user.systemRoles, SystemRole.Administrator)) {
      result.add(SystemRole.Administrator);
    }
  }
  return result;
};

export const RoleCache = {
  SubmitterArray: [SystemRole.Submitter],
  ApproverArray: [SystemRole.Approver],
  AdminArray: [SystemRole.Administrator],
  AdminAndApproverArray: [SystemRole.Administrator, SystemRole.Approver],
};

function useRoleUtilities() {
  const { data: currentUser } = useCurrentUserQuery();
  const usersRoles = useMemo(() => getExistingRoles(currentUser), [currentUser]);

  const hasRole = useCallback((role: SystemRole) => usersRoles.has(role), [usersRoles]);
  const doesNotHaveRole = useCallback((role: SystemRole) => !usersRoles.has(role), [usersRoles]);
  const hasAllRoles = useCallback(
    (roles: SystemRole[]) => {
      if (roles.length === 0) return true;
      if (usersRoles.size === 0) return false;

      for (const role of roles) {
        if (!usersRoles.has(role)) {
          return false;
        }
      }
      return true;
    },
    [usersRoles]
  );
  const hasAnyRole = useCallback(
    (roles: SystemRole[]) => {
      for (const role of roles) {
        if (usersRoles.has(role)) {
          return true;
        }
      }
      return false;
    },
    [usersRoles]
  );

  return {
    hasRole,
    doesNotHaveRole,
    hasAllRoles,
    hasAnyRole,
  };
}

export default function useAuthorization() {
  const roleUtilities = useRoleUtilities();
  const { data: currentUser } = useCurrentUserQuery();

  return useMemo(() => {
    const isAdmin = roleUtilities.hasRole(SystemRole.Administrator);
    const isApprover = roleUtilities.hasRole(SystemRole.Approver);
    const isSubmitter = roleUtilities.hasRole(SystemRole.Submitter);

    const result = {
      utilities: roleUtilities,
      roles: {
        isAdmin,
        isApprover,
        isSubmitter,
      },
      features: {
        contactReports: {
          canSubmit: isAdmin || isSubmitter,
          canEdit: (report: ContactReportListQueryDTO) =>
            (isAdmin || (currentUser && currentUser.id === report.createdBy.id)) &&
            report.contactReportStatus === ContactReportStatus.AdditionalInfoRequested,
          canApprove: (report: ContactReportDetailDTO) =>
            (isAdmin || isApprover) && report.contactReportStatus === ContactReportStatus.Pending,
        },
      },
    };

    return result;
  }, [roleUtilities, currentUser]);
}
