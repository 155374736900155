import styled from "styled-components";
import theme from "../../../app/theme";

const FormSectionHeaderSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.palette.themePrimary};
  border-radius: 4px;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
`;

type FormSectionHeaderProps = {
  children?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const FormSectionHeader = ({ children, ...other }: FormSectionHeaderProps) => (
  <div {...other}>
    {children && <p className="mt-0 mb-1 text-base">{children}</p>}
    <FormSectionHeaderSeparator />
  </div>
);

export default FormSectionHeader;
