import { UseQueryOptions, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { ContactReportCreateUpdateDTO, ContactReportDetailDTO, ContactReportsClient } from "../../services/contactReportsApi";
import { ContactReportQueryMetadata } from "./contactReportSchemas";
import { createSortExpression } from "../../common/hooks/useQueryMetadata";
import useToastNotification from "../../common/hooks/useToastNotification";
import { useTranslator } from "../../common/state/translatorState";
import { useRecoilValue } from "recoil";
import { contactreportQueryMetadataState } from "./contactReportState";

const contactReportClient = new ContactReportsClient();

export const contactReportQueryKeys = {
  contactReports: ["contactReports"] as const,
  detail: (id: number) => [...contactReportQueryKeys.contactReports, "detail", id] as const,
  list: (metadata: ContactReportQueryMetadata) => [...contactReportQueryKeys.contactReports, "list", metadata] as const,
  latest: () => [...contactReportQueryKeys.contactReports, "latest"] as const,
};

export const useContactReportDetail = (
  id: number,
  options?: Omit<
    UseQueryOptions<ContactReportDetailDTO, unknown, ContactReportDetailDTO, readonly ["contactReports", "detail", number]>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(contactReportQueryKeys.detail(id), () => contactReportClient.getById(id), options);
};

export const useCreateContactReport = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: { contactReport: ContactReportCreateUpdateDTO }) => contactReportClient.createContactReport(data.contactReport),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(contactReportQueryKeys.contactReports);
      },
    }
  );
};

export const useUpdateContactReport = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: { id: number; contactReport: ContactReportCreateUpdateDTO }) =>
      contactReportClient.updateContactReport(data.id, data.contactReport),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(contactReportQueryKeys.contactReports);
      },
    }
  );
};

export const useUploadContactReportAttachment = () => {
  return useMutation(async (data: { file: File }) => contactReportClient.uploadAttachment({ data: data.file, fileName: data.file.name }));
};

export const useDownloadAllContactReports = () => {
  const currentMetadata = useRecoilValue(contactreportQueryMetadataState);
  return useMutation(async () =>
    contactReportClient.downloadAll(
      createSortExpression(currentMetadata.sort.fields),
      currentMetadata.filter.meetingDateFrom?.toISOString(),
      currentMetadata.filter.meetingDateTo?.toISOString(),
      currentMetadata.filter.status,
      currentMetadata.filter.attendee?.uniqueId,
      currentMetadata.filter.attendeeCompanyId,
      currentMetadata.filter.search,
      currentMetadata.filter.includingAttachments,
      currentMetadata.filter.hasAttachment
    )
  );
};

export const useLatestContactReportQuery = (
  options?: Omit<
    UseQueryOptions<ContactReportDetailDTO, unknown, ContactReportDetailDTO, readonly ["contactReports", "latest"]>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(contactReportQueryKeys.latest(), () => contactReportClient.getLatest(), options);
};

export const useRequestAdditionalInfoMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: { id: number; reviewerNote: string }) =>
      contactReportClient.requestAdditionalInfo(data.id, { reviewerNote: data.reviewerNote }),
    { onSuccess: () => queryClient.invalidateQueries(contactReportQueryKeys.contactReports) }
  );
};

export const useReviewContactReportMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: { id: number; reviewerNote: string }) =>
      contactReportClient.reviewNotApprove(data.id, { reviewerNote: data.reviewerNote }),
    { onSuccess: () => queryClient.invalidateQueries(contactReportQueryKeys.contactReports) }
  );
};

export const useApproveContactReportMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: { id: number; reviewerNote: string }) => contactReportClient.approve(data.id, { reviewerNote: data.reviewerNote }),
    { onSuccess: () => queryClient.invalidateQueries(contactReportQueryKeys.contactReports) }
  );
};

export const useContactReportListQuery = (initialMetadata: ContactReportQueryMetadata) => {
  const t = useTranslator();
  const toast = useToastNotification();

  return useInfiniteQuery({
    queryKey: contactReportQueryKeys.list(initialMetadata),
    queryFn: (infiniteParams) => {
      const metadata = (infiniteParams.pageParam as ContactReportQueryMetadata) ?? initialMetadata;
      switch (metadata.type) {
        case "all":
          return contactReportClient.getAll(
            metadata.page.offset,
            metadata.page.limit,
            createSortExpression(metadata.sort.fields),
            metadata.filter.meetingDateFrom?.toISOString(),
            metadata.filter.meetingDateTo?.toISOString(),
            metadata.filter.status,
            metadata.filter.attendee?.uniqueId,
            metadata.filter.attendeeCompanyId,
            metadata.filter.search,
            metadata.filter.includingAttachments,
            metadata.filter.hasAttachment
          );
        case "my":
          return contactReportClient.getUser(
            metadata.page.offset,
            metadata.page.limit,
            createSortExpression(metadata.sort.fields),
            metadata.filter.meetingDateFrom?.toISOString(),
            metadata.filter.meetingDateTo?.toISOString(),
            metadata.filter.status,
            metadata.filter.attendee?.uniqueId,
            metadata.filter.attendeeCompanyId,
            metadata.filter.search,
            metadata.filter.includingAttachments,
            metadata.filter.hasAttachment
          );
        case "on-behalf-of":
          return contactReportClient.getOnBehalf(
            metadata.page.offset,
            metadata.page.limit,
            createSortExpression(metadata.sort.fields),
            metadata.filter.meetingDateFrom?.toISOString(),
            metadata.filter.meetingDateTo?.toISOString(),
            metadata.filter.status,
            metadata.filter.attendee?.uniqueId,
            metadata.filter.attendeeCompanyId,
            metadata.filter.search,
            metadata.filter.includingAttachments,
            metadata.filter.hasAttachment
          );
        case "to-be-reviewed":
          return contactReportClient.getToBeReviewed(
            metadata.page.offset,
            metadata.page.limit,
            createSortExpression(metadata.sort.fields),
            metadata.filter.meetingDateFrom?.toISOString(),
            metadata.filter.meetingDateTo?.toISOString(),
            metadata.filter.status,
            metadata.filter.attendee?.uniqueId,
            metadata.filter.attendeeCompanyId,
            metadata.filter.search,
            metadata.filter.includingAttachments,
            metadata.filter.hasAttachment
          );
        default:
          throw new Error(`Unknown contact report query type: '${metadata.type}'`);
      }
    },
    getNextPageParam: (lastPage, pages): ContactReportQueryMetadata | undefined => {
      let currentRowCount = 0;
      for (const page of pages) {
        if (Array.isArray(page.data)) {
          currentRowCount += page.data.length;
        }
      }

      if (lastPage.metaData && currentRowCount === lastPage.metaData.totalRowCount) {
        return undefined;
      }

      return {
        ...initialMetadata,
        page: {
          ...initialMetadata.page,
          offset: currentRowCount,
        },
      };
    },
    staleTime: 10 * 1000,
    keepPreviousData: true,
    onError: () => toast.showError(t.GenericErrors.UnexpectedError),
  });
};
