import { UseQueryOptions, useQuery } from "react-query";
import { LanguageInfoDTO, LanguagesClient, SystemsClient } from "../../services/contactReportsApi";

const languagesClient = new LanguagesClient();
const systemClient = new SystemsClient();

export const languageQueryKeys = {
  all: ["languages"] as const,
  default: () => [...languageQueryKeys.all, "default"] as const,
};

export const useLanguagesQuery = (
  options?: Omit<UseQueryOptions<LanguageInfoDTO[], unknown, LanguageInfoDTO[], readonly ["languages"]>, "queryKey" | "queryFn">
) => {
  return useQuery(languageQueryKeys.all, () => languagesClient.getAllActiveKeywords(), options);
};

export const useDefaultLanguageQuery = (
  options?: Omit<UseQueryOptions<LanguageInfoDTO, unknown, LanguageInfoDTO, readonly ["languages", "default"]>, "queryKey" | "queryFn">
) => {
  return useQuery(languageQueryKeys.default(), () => systemClient.getDefaultLanguage(), options);
};
