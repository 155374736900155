import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslator } from "../../../common/state/translatorState";
import {
  ContactReportDetailDTO,
  ContactSystemType,
  ContactTypeInfoDTO,
  DivisionInfoDTO,
  RegionInfoDTO,
} from "../../../services/contactReportsApi";
import { ContactReportSchema, useContactReportSchema } from "../contactReportSchemas";
import { useForm, useWatch } from "react-hook-form";
import { ControlledTextField } from "../../../common/components/form/ControlledTextField";
import { nameof } from "../../../common/utilities";
import FormSectionHeader from "../../../common/components/form/FormSectionHeader";
import { ControlledDatePicker } from "../../../common/components/form/ControlledDatePicker";
import {
  DefaultButton,
  IDropdownOption,
  IStyleFunctionOrObject,
  ITextFieldStyleProps,
  ITextFieldStyles,
  Label,
  PrimaryButton,
} from "@fluentui/react";
import { useEffect, useMemo } from "react";
import { toGroupedOrganizationDropdownOptions } from "../../organizations/organizationUtilities";
import { ControlledDropdown } from "../../../common/components/form/ControlledDropdown";
import { toGroupedCountryDropdownOptions } from "../../locations/locationUtilities";
import useUserSearchDialog from "../../../common/hooks/useUserSearchDialog";
import { useCreateContactReport, useUpdateContactReport, useUploadContactReportAttachment } from "../contactReportQueries";
import { mapToContactCreateUpdateDto } from "../contactReportUtilities";
import useToastNotification from "../../../common/hooks/useToastNotification";
import ControlledFileUploadButton from "../../../common/components/form/ControlledFileUploadButton";
import { useNavigate } from "react-router-dom";
import useErrorHandler from "../../../common/hooks/useErrorHandler";
import FormTextField from "../../../common/components/form/FormTextField";
import DisplayValue from "./DisplayValue";
import { useContactTypeName } from "../../contactTypes/contactTypeHooks";
import { ControlledCombobox } from "../../../common/components/form/ControlledComboBox";
import useCanGoBack from "../../../common/hooks/useCanGoBack";
import useAuthorization from "../../../common/hooks/useAuthorization";
import { useConfirmationDialog } from "../../../common/hooks/useConfirmationDialog";

const allowedAttachmentExtensions = [".doc", ".docx", ".xls", ".xlsx", ".pdf", ".txt"];
const fullWidthTextFieldStyles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = { root: { width: "100%" } };
const onBehalfOfButtonLabelStyles: React.CSSProperties = { fontWeight: "inherit" };
const onBehalfOfButtonStyles: React.CSSProperties = { fontSize: "12px", width: "8rem", paddingLeft: "0.25rem", paddingRight: "0.25rem" };

type Props = {
  editedContactReport?: ContactReportDetailDTO;
  contactReport: ContactReportSchema;
  organizations: DivisionInfoDTO[];
  locations: RegionInfoDTO[];
  contactTypes: ContactTypeInfoDTO[];
};

const ContactReportForm = ({ editedContactReport, contactReport, organizations, locations, contactTypes }: Props) => {
  const t = useTranslator();
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();
  const contactReportSchema = useContactReportSchema(contactTypes);
  const searchUser = useUserSearchDialog();
  const toast = useToastNotification();
  const { showDialog } = useConfirmationDialog();
  const contactTypeName = useContactTypeName();
  const canGoBack = useCanGoBack();
  const { roles } = useAuthorization();
  const { mutate: createContactReport, isLoading: isContactReportCreating } = useCreateContactReport();
  const { mutate: updateContactReport, isLoading: isContactReportUpdating } = useUpdateContactReport();
  const { mutateAsync: uploadAttachment, isLoading: isAttachmentUploading } = useUploadContactReportAttachment();

  const companiesOptions: IDropdownOption[] = useMemo(() => toGroupedOrganizationDropdownOptions(organizations), [organizations]);
  const contactTypeOptions: IDropdownOption[] = useMemo(
    () => contactTypes.map((ct) => ({ key: ct.id, text: contactTypeName(ct) })).sort((a, b) => a.text.localeCompare(b.text)),
    [contactTypes, contactTypeName]
  );
  const countryOptions: IDropdownOption[] = useMemo(() => toGroupedCountryDropdownOptions(locations), [locations]);

  const isBusy = isContactReportCreating || isContactReportUpdating || isAttachmentUploading;
  const hasReviewerDetails = editedContactReport && editedContactReport.reviewedBy;

  const { control, handleSubmit, setValue, watch } = useForm<ContactReportSchema>({
    defaultValues: contactReport,
    resolver: zodResolver(contactReportSchema),
  });

  const selectedContactTypeId = watch("contactTypeId");
  const isInPersonContactTypeSelected = contactTypes.some(
    (ct) => ct.id === selectedContactTypeId && ct.contactSystemType === ContactSystemType.InPerson
  );

  useEffect(() => {
    if (!isInPersonContactTypeSelected) {
      setValue("meetingAddress", ""); //reset meeting address when remote contact type is selected
    }
  }, [isInPersonContactTypeSelected]);

  const handleOnBehalfOfClick = async () => {
    const user = await searchUser();
    if (user) {
      setValue(
        "attendee",
        {
          uniqueId: user.uniqueId,
          name: user.displayName ?? "",
          email: user.email ?? "",
          phoneNumber: user.mobilePhone ?? "",
        },
        { shouldTouch: true }
      );
    }
  };

  const handleAttachmentSelected = async (file: File, first: boolean) => {
    return await uploadAttachment(
      { file },
      {
        onSuccess: (uploadedFile) => {
          setValue(first ? "attachment1" : "attachment2", uploadedFile);
        },
      }
    );
  };

  const goBackToList = () => {
    if (canGoBack) {
      navigate(-1);
    } else {
      if (roles.isAdmin || roles.isApprover) {
        navigate("/contact-reports/list/all", { replace: true });
      } else {
        navigate("/contact-reports/list/my", { replace: true });
      }
    }
  };

  const onSubmitClick = handleSubmit((report) => {
    if (editedContactReport) {
      updateContactReport(
        { id: editedContactReport.id, contactReport: mapToContactCreateUpdateDto(report) },
        {
          onSuccess: () => {
            toast.showSuccess(t.ContactReportDetail.ToastMessage.Submitted.Title);
            goBackToList();
          },
          onError: errorHandler,
        }
      );
    } else {
      createContactReport(
        { contactReport: mapToContactCreateUpdateDto(report) },
        {
          onSuccess: () => {
            showDialog(
              t.Components.ConfirmationDialog.Successful.Title,
              t.ContactReportDetail.Dialog.Submitted.Body,
              t.Components.Button.Ok.Label,
              "info"
            );
            goBackToList();
          },
          onError: errorHandler,
        }
      );
    }
  });

  return (
    <div className="mt-4">
      <FormSectionHeader>{t.ContactReportDetail.Section.AttendeeDetails.Title}</FormSectionHeader>
      <div className="grid grid-cols-12 grid-rows-12 gap-x-4">
        <div className="col-span-12 md:col-span-6 xl:col-span-4">
          <ControlledTextField
            control={control}
            name={nameof<ContactReportSchema>("attendee.name")}
            label={t.ContactReportDetail.Field.Attendee.Label}
            readOnly
            required
            styles={fullWidthTextFieldStyles}
          />
          <ControlledCombobox
            control={control}
            name={nameof<ContactReportSchema>("attendeeCompanyId")}
            label={t.ContactReportDetail.Field.AttendeeCompany.Label}
            options={companiesOptions}
            required
            defaultValue={0}
            allowFreeInput
            autoComplete="on"
          />
          <Label style={onBehalfOfButtonLabelStyles}>If you are submitting a report on behalf of someone else, click here:</Label>
          <DefaultButton style={onBehalfOfButtonStyles} onClick={handleOnBehalfOfClick}>
            {t.ContactReportDetail.Button.OnBehalfOf.Title}
          </DefaultButton>
        </div>
        <div className="col-span-12 md:col-span-6 xl:col-span-4">
          <ControlledTextField
            control={control}
            name={nameof<ContactReportSchema>("attendee.email")}
            label={t.ContactReportDetail.Field.AttendeeEmail.Label}
            required
          />
          <ControlledTextField
            control={control}
            name={nameof<ContactReportSchema>("attendee.phoneNumber")}
            label={t.ContactReportDetail.Field.AttendeePhone.Label}
            required
          />
        </div>
      </div>
      <FormSectionHeader className="mt-8">{t.ContactReportDetail.Section.ContactDetails.Title}</FormSectionHeader>
      <div className="grid grid-cols-12 grid-rows-12 gap-x-4">
        <div className="col-span-12 md:col-span-6 xl:col-span-4">
          <ControlledTextField
            control={control}
            name={nameof<ContactReportSchema>("competitorCompanyName")}
            label={t.ContactReportDetail.Field.CompetitorCompanyName.Label}
            required
          />
          <ControlledDropdown
            control={control}
            name={nameof<ContactReportSchema>("contactTypeId")}
            options={contactTypeOptions}
            label={t.ContactReportDetail.Field.ContactType.Label}
            required
            defaultValue={0}
          />
          <ControlledDatePicker
            control={control}
            name={nameof<ContactReportSchema>("meetingDate")}
            label={t.ContactReportDetail.Field.MeetingDate.Label}
            showClear={false}
          />
          {isInPersonContactTypeSelected && (
            <ControlledTextField
              control={control}
              name={nameof<ContactReportSchema>("meetingAddress")}
              label={t.ContactReportDetail.Field.MeetingAddress.Label}
              required={isInPersonContactTypeSelected}
            />
          )}
          <ControlledCombobox
            control={control}
            name={nameof<ContactReportSchema>("meetingCountryId")}
            options={countryOptions}
            label={t.ContactReportDetail.Field.MeetingCountry.Label}
            required
            defaultValue={0}
            allowFreeInput
            autoComplete="on"
          />
          <ControlledTextField
            control={control}
            name={nameof<ContactReportSchema>("attendees")}
            label={t.ContactReportDetail.Field.Attendees.Label}
            multiline
            autoAdjustHeight
            style={{ minHeight: "120px" }}
            required
          />
        </div>
        <div className="col-span-12 xl:col-span-8">
          <ControlledTextField
            control={control}
            name={nameof<ContactReportSchema>("subject")}
            label={t.ContactReportDetail.Field.Subject.Label}
            required
          />
          <ControlledTextField
            control={control}
            name={nameof<ContactReportSchema>("summary")}
            label={t.ContactReportDetail.Field.Summary.Label}
            multiline
            autoAdjustHeight
            style={{ minHeight: "240px", maxHeight: "360px", overflowY: "auto" }}
            required
          />
          <div className="grid grid-cols-12 grid-rows-12 gap-x-4 mt-4">
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <Label>{t.ContactReportDetail.Field.Attachment.Label} #1</Label>
              <ControlledFileUploadButton
                control={control}
                name={nameof<ContactReportSchema>("attachment1")}
                onFileSelected={(f) => handleAttachmentSelected(f, true)}
                disabled={isBusy}
                allowedFileExtensions={allowedAttachmentExtensions}
              >
                {t.ContactReportDetail.Button.Upload.Title}
              </ControlledFileUploadButton>
            </div>
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <Label>{t.ContactReportDetail.Field.Attachment.Label} #2</Label>
              <ControlledFileUploadButton
                control={control}
                name={nameof<ContactReportSchema>("attachment2")}
                onFileSelected={(f) => handleAttachmentSelected(f, false)}
                disabled={isBusy}
                allowedFileExtensions={allowedAttachmentExtensions}
              >
                {t.ContactReportDetail.Button.Upload.Title}
              </ControlledFileUploadButton>
            </div>
          </div>
        </div>
      </div>
      <FormSectionHeader className="mt-4">{t.ContactReportDetail.Section.SubmitterDetails.Title}</FormSectionHeader>
      <div className="grid grid-cols-12 grid-rows-12 gap-x-4">
        <div className="col-span-12 lg:col-span-6 xl:col-span-4">
          <ControlledTextField
            control={control}
            name={nameof<ContactReportSchema>("submitterName")}
            label={t.ContactReportDetail.Field.CreatedBy.Label}
            readOnly
          />
        </div>
        <div className="col-span-12 lg:col-span-6 xl:col-span-4">
          <ControlledTextField
            control={control}
            name={nameof<ContactReportSchema>("submitterEmail")}
            label={t.ContactReportDetail.Field.SubmitterEmail.Label}
            required
          />
        </div>
        <div className="col-span-12 lg:col-span-6 xl:col-span-4">
          <ControlledTextField
            control={control}
            name={nameof<ContactReportSchema>("submitterPhone")}
            label={t.ContactReportDetail.Field.SubmitterPhone.Label}
            required
          />
        </div>
      </div>

      {hasReviewerDetails && (
        <>
          <FormSectionHeader className="mt-4">{t.ContactReportDetail.Section.ReviewDetails.Title}</FormSectionHeader>
          <div className="grid grid-cols-12 grid-rows-12 gap-x-4">
            <div className="col-span-12 lg:col-span-6 xl:col-span-4">
              <DisplayValue label={t.ContactReportDetail.Field.ReviewerName.Label}>
                {editedContactReport.reviewedBy?.displayName ?? ""}
              </DisplayValue>
            </div>
            <div className="col-span-12 lg:col-span-6 xl:col-span-4">
              <DisplayValue label={t.ContactReportDetail.Field.ReviewerEmail.Label}>
                {editedContactReport.reviewedBy?.email ?? ""}
              </DisplayValue>
            </div>
            <div className="col-span-12 lg:col-span-6 xl:col-span-4">
              <DisplayValue label={t.ContactReportDetail.Field.ReviewerPhone.Label}>
                {editedContactReport.reviewedBy?.mobilePhone ?? ""}
              </DisplayValue>
            </div>
          </div>
          <FormTextField
            value={editedContactReport?.reviewerNote ?? ""}
            label={t.ContactReportDetail.Field.ReviewerNote.Label}
            readOnly
            multiline
          />
        </>
      )}

      <div className="flex flex-row justify-end align-center gap-4 mt-4">
        <PrimaryButton onClick={onSubmitClick} disabled={isBusy}>
          {t.ContactReportDetail.Button.Submit.Title}
        </PrimaryButton>
        <DefaultButton disabled={isBusy} onClick={() => navigate(-1)}>
          {t.Components.Button.Cancel.Label}
        </DefaultButton>
      </div>
    </div>
  );
};

export default ContactReportForm;
