import { useEffect } from "react";
import { useTranslator } from "../state/translatorState";

export default function useTranslatedWindowTitle() {
  const t = useTranslator();

  useEffect(() => {
    document.title = `ABF - ${t.Layout.Header.Title}`;
  }, [t]);
}
