import { useMemo } from "react";
import { useTranslator } from "../../common/state/translatorState";
import { ApproverJurisdictionInfoDTO, HierarchyInfoDTO, SystemRole } from "../../services/contactReportsApi";
import { compareHierarchies } from "../../common/utilities";

export function useSystemRoleName() {
  const t = useTranslator();

  return (role: SystemRole, short: boolean = false) => {
    switch (role) {
      case SystemRole.Submitter:
        return short ? t.EnumValueShortName.SystemRole.Submitter : t.EnumValueFullName.SystemRole.Submitter;
      case SystemRole.Approver:
        return short ? t.EnumValueShortName.SystemRole.Approver : t.EnumValueFullName.SystemRole.Approver;
      case SystemRole.Administrator:
        return short ? t.EnumValueShortName.SystemRole.Administrator : t.EnumValueFullName.SystemRole.Administrator;
      default:
        return "";
    }
  };
}

export type ApproverListRole = {
  key: string;
  roleName: string;
  location?: HierarchyInfoDTO | null;
  organization?: HierarchyInfoDTO | null;
};

export function useApproverListRoles(approverRoles: ApproverJurisdictionInfoDTO[]): ApproverListRole[] {
  const t = useTranslator();
  return useMemo(() => {
    const result: ApproverListRole[] = [];
    for (const jurisdiction of approverRoles) {
      result.push({
        key: `location_${jurisdiction.location?.id ?? -1}_organization_${jurisdiction.organisation?.id ?? -1}`,
        roleName: t.EnumValueFullName.SystemRole.Approver,
        location: jurisdiction.location,
        organization: jurisdiction.organisation,
      });
    }
    return result.sort((a, b) => {
      const organization = compareHierarchies(a.organization, b.organization);
      if (organization !== 0) {
        return organization;
      }
      return compareHierarchies(a.location, b.location);
    });
  }, [approverRoles, t]);
}
