import { RouteObject } from "react-router-dom";
import ProtectedRoute from "../../common/components/ProtectedRoute";
import LocationsScreen from "./screens/LocationsScreen";
import { RoleCache } from "../../common/hooks/useAuthorization";

export const locationsRoutes: RouteObject[] = [
  {
    id: "locations",
    path: "/locations",
    element: (
      <ProtectedRoute allowedRoles={RoleCache.AdminArray}>
        <LocationsScreen />
      </ProtectedRoute>
    ),
  },
];
