import { PrimaryButton } from "@fluentui/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslator } from "../state/translatorState";
import PageHeader from "../components/PageHeader";

type ErrorScreenType = "not-found" | "unauthorized" | "server-error";

type Props = {
  type: ErrorScreenType;
};

const RouterErrorScreen = ({ type }: Props) => {
  const navigate = useNavigate();
  const t = useTranslator();

  const resolveContentText = () => {
    switch (type) {
      case "server-error":
        return t.GenericErrors.UnexpectedError;
      case "not-found":
        return "TODO";
      case "unauthorized":
        return t.GenericErrors.Unauthorized;
    }
  };

  const navigateHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  if (type === "not-found") {
    return (
      <>
        <PageHeader className="mb-2">{t.ErrorPage.NotFound.Header}</PageHeader>
        <div className="flex flex-col items-start gap-y-4">
          <span className="mt-4">{t.ErrorPage.NotFound.Content}</span>
          <PrimaryButton onClick={navigateHome}>{t.ErrorPage.NotFound.Button.Home.Label}</PrimaryButton>
        </div>
      </>
    );
  }

  if (type === "unauthorized") {
    return (
      <>
        <PageHeader className="mb-2">{t.ErrorPage.Unauthorized.Header}</PageHeader>
        <div className="flex flex-col items-start gap-y-4">
          <span className="mt-4">{t.ErrorPage.Unauthorized.Content}</span>
        </div>
      </>
    );
  }

  return (
    <>
      <PageHeader className="mb-2">{t.ErrorPage.UnknownError.Header}</PageHeader>
      <div className="flex flex-col items-start gap-y-4">
        <span className="mt-4">{t.ErrorPage.UnknownError.Content}</span>
      </div>
    </>
  );
};

export default RouterErrorScreen;
