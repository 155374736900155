import { RequiredFieldProps } from "../../model/requiredFieldProps";
import { ForwardRefExoticComponent, RefAttributes, forwardRef, useImperativeHandle, useRef } from "react";
import FormRequiredLabel from "./FormRequiredLabel";
import useToastNotification from "../../hooks/useToastNotification";
import { ADUserSearchResult, SystemUsersClient } from "../../../services/contactReportsApi";
import { IPeoplePickerProps, IPersonaProps, NormalPeoplePicker } from "@fluentui/react";
import { useTranslator } from "../../state/translatorState";

const userClient = new SystemUsersClient();
const personaStyles: React.CSSProperties = { maxWidth: "90vw" };

type ADUserSearchPersona = {
  user: ADUserSearchResult;
} & IPersonaProps;

type UserSearchBoxProps = {
  selectedUser: ADUserSearchResult | null;
  onChange: (user?: ADUserSearchResult) => any;
  label?: string;
};

const createPersonaFromSearchedUser = (user: ADUserSearchResult): ADUserSearchPersona => {
  return {
    key: user.uniqueId,
    text: user.displayName,
    secondaryText: user.email ?? "",
    imageInitials:
      user.firstName && user.lastName && user.firstName.length > 0 && user.lastName.length > 0
        ? `${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}`
        : "",
    className: "w-full",
    style: personaStyles,
    user: user,
  };
};

export type FormUserSearchBoxRefProps = {
  clearInput: () => void;
};

type Props = Omit<IPeoplePickerProps, "required" | "options" | "onChange" | "onResolveSuggestions"> &
  RequiredFieldProps &
  UserSearchBoxProps &
  RefAttributes<FormUserSearchBoxRefProps>;

//TODO: could be made "controlled" and support validation and value setting
const FormUserSearchBox: ForwardRefExoticComponent<Props> = forwardRef(({ required, ...props }, ref) => {
  const t = useTranslator();
  const toast = useToastNotification();
  const peoplePickerRef = useRef<any>();

  const handleFilterUsers = async (filterText: string): Promise<ADUserSearchPersona[]> => {
    try {
      if (filterText && filterText.length > 0) {
        const newUsers = await userClient.lookupADUsers(filterText);
        return newUsers.map(createPersonaFromSearchedUser);
      }
    } catch (error) {
      toast.showError(t.Components.ToastMessage.ErrorLoadingData.Title);
    }
    return [];
  };

  const clearInput = () => {
    if (peoplePickerRef.current && peoplePickerRef.current.input && peoplePickerRef.current.input.current) {
      peoplePickerRef.current.input.current.clear();
    }
  };

  useImperativeHandle(ref, () => ({
    clearInput,
  }));

  return (
    <>
      {props.label && <FormRequiredLabel label={props.label} required={required} />}
      <NormalPeoplePicker
        resolveDelay={200}
        onResolveSuggestions={handleFilterUsers}
        disabled={props.disabled}
        itemLimit={1}
        selectedItems={props.selectedUser ? [createPersonaFromSearchedUser(props.selectedUser)] : []}
        searchingText={t.Components.ProgressIndicator.LoadingData.Label}
        componentRef={peoplePickerRef}
        {...props}
        onChange={(items) => {
          let selectedUser: ADUserSearchResult | undefined;
          if (Array.isArray(items) && items.length === 1) {
            const adItem = items[0] as ADUserSearchPersona;
            selectedUser = adItem.user;
          }

          props.onChange(selectedUser);
        }}
      />
    </>
  );
});

export default FormUserSearchBox;
