import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { LocationsClient, RegionDetailDTO, RegionInfoDTO } from "../../services/contactReportsApi";
import { Location } from "./locationSchemas";
import useErrorHandler from "../../common/hooks/useErrorHandler";

const locationsClient = new LocationsClient();

export const locationsQueryKeys = {
  locations: ["locations"] as const,
  active: () => [...locationsQueryKeys.locations, "active"] as const,
  all: () => [...locationsQueryKeys.locations, "all"] as const,
};

export const useActiveLocationsQuery = (
  options?: Omit<UseQueryOptions<RegionInfoDTO[], unknown, RegionInfoDTO[], readonly ["locations", "active"]>, "queryKey" | "queryFn">
) => {
  return useQuery(locationsQueryKeys.active(), () => locationsClient.getAllActiveKeywords(), options);
};

export const useAllLocationsQuery = (
  options?: Omit<UseQueryOptions<RegionDetailDTO[], unknown, RegionDetailDTO[], readonly ["locations", "all"]>, "queryKey" | "queryFn">
) => {
  return useQuery(locationsQueryKeys.all(), () => locationsClient.getAll(), options);
};

export const useCreateLocation = () => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  return useMutation(
    async (data: { location: Location }) => {
      switch (data.location.type) {
        case "region": {
          await locationsClient.createRegion({ name: data.location.name });
          break;
        }
        case "subRegion": {
          await locationsClient.createSubRegion({ name: data.location.name, regionId: data.location.parentId ?? 0 });
          break;
        }
        case "country": {
          await locationsClient.createCountry({ name: data.location.name, subRegionId: data.location.parentId ?? 0 });
          break;
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(locationsQueryKeys.locations);
      },
      onError: errorHandler,
    }
  );
};

export const useUpdateLocation = () => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  return useMutation(
    async (data: { location: Location }) => {
      switch (data.location.type) {
        case "region": {
          await locationsClient.updateRegion(data.location.id ?? 0, { name: data.location.name });
          break;
        }
        case "subRegion": {
          await locationsClient.updateSubRegion(data.location.id ?? 0, { name: data.location.name, regionId: data.location.parentId ?? 0 });
          break;
        }
        case "country": {
          await locationsClient.updateCountry(data.location.id ?? 0, {
            name: data.location.name,
            subRegionId: data.location.parentId ?? 0,
          });
          break;
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(locationsQueryKeys.locations);
      },
      onError: errorHandler,
    }
  );
};

export const useArchiveLocation = () => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  return useMutation((locationId: number) => locationsClient.archiveLocation(locationId), {
    onSuccess: () => {
      queryClient.invalidateQueries(locationsQueryKeys.locations);
    },
    onError: errorHandler,
  });
};

export const useUnArchiveLocation = () => {
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  return useMutation((locationId: number) => locationsClient.unarchiveLocation(locationId), {
    onSuccess: () => {
      queryClient.invalidateQueries(locationsQueryKeys.locations);
    },
    onError: errorHandler,
  });
};
