import { RouteObject } from "react-router-dom";
import ContactReportWelcomeScreen from "./screens/ContactReportWelcomeScreen";
import NewContactReportScreen from "./screens/NewContactReportScreen";
import AllContactReportsListScreen from "./screens/AllContactReportsListScreen";
import ContactReportDetailScreen from "./screens/ContactReportDetailScreen";
import EditContactReportScreen from "./screens/EditContactReportScreen";
import OnBehalfOfContactReportsListScreen from "./screens/OnBehalfOfContactReportsListScreen";
import ToBeReviewedContactReportsListScreen from "./screens/ToBeReviewedContactReportsListScreen";
import MyContactReportsListScreen from "./screens/MyContactReportsListScreen";
import ContactReportBaseScreen from "./screens/ContactReportBaseScreen";

export const contactReportRoutes: RouteObject[] = [
  {
    id: "home",
    path: "/",
    element: <ContactReportWelcomeScreen />,
  },
  {
    id: "contactReports",
    path: "/contact-reports",
    element: <ContactReportBaseScreen />,
    children: [
      {
        id: "contactReportsWelcome",
        path: "/contact-reports",
        element: <ContactReportWelcomeScreen />,
      },
      {
        id: "contactReportsNew",
        path: "/contact-reports/new",
        element: <NewContactReportScreen />,
      },
      {
        id: "contactReportsEdit",
        path: "/contact-reports/edit/:id",
        element: <EditContactReportScreen />,
      },
      {
        id: "allContactReportsList",
        path: "/contact-reports/list/all",
        element: <AllContactReportsListScreen />,
      },
      {
        id: "myContactReportsList",
        path: "/contact-reports/list/my",
        element: <MyContactReportsListScreen />,
      },
      {
        id: "toBeReviewedContactReportsList",
        path: "/contact-reports/list/to-be-reviewed",
        element: <ToBeReviewedContactReportsListScreen />,
      },
      {
        id: "onBehalfOfContactReportsList",
        path: "/contact-reports/list/on-behalf-of",
        element: <OnBehalfOfContactReportsListScreen />,
      },
      {
        id: "contactReportDetail",
        path: "/contact-reports/:id",
        element: <ContactReportDetailScreen />,
      },
    ],
  },
];
