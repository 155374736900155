import { IComboBoxOption, IComboBoxOptionStyles } from "@fluentui/react";
import { useActiveOrganizationsQuery } from "./organizationQueries";
import { OrganizationType } from "./organizationSchemas";
import { CompanyInfoDTO, DivisionInfoDTO } from "../../services/contactReportsApi";
import { useMemo } from "react";
import { HierarchyInfo } from "../../common/model/hierarchyInfoSchema";

type OrganizationComboBoxOption = {
  type: OrganizationType;
  data: DivisionInfoDTO | CompanyInfoDTO;
} & Omit<IComboBoxOption, "data">;

export function useOrganizationComboBoxOptions(
  divisionStyles?: Partial<IComboBoxOptionStyles>,
  companyStyles?: Partial<IComboBoxOptionStyles>
): OrganizationComboBoxOption[] {
  const { data: organizations } = useActiveOrganizationsQuery();

  return useMemo(() => {
    if (!Array.isArray(organizations)) {
      return [];
    }

    const result: OrganizationComboBoxOption[] = [];
    for (const division of organizations) {
      result.push({
        key: division.id,
        text: division.name,
        type: "division",
        data: division,
        styles: divisionStyles,
      });
      for (const company of division.companies) {
        result.push({
          key: company.id,
          text: `${company.name} (${division.name})`,
          type: "company",
          data: company,
          styles: companyStyles,
        });
      }
    }

    return result;
  }, [organizations]);
}

export type OrganizationHierarchyInfo = {
  type: OrganizationType;
} & HierarchyInfo;

export function useOrganizationHierarchy(): OrganizationHierarchyInfo[] {
  const { data: organizations } = useActiveOrganizationsQuery();

  return useMemo(() => {
    if (!Array.isArray(organizations)) {
      return [];
    }

    const result: OrganizationHierarchyInfo[] = [];

    for (const division of organizations) {
      const mappedDivision: OrganizationHierarchyInfo = {
        id: division.id,
        isActive: division.isActive,
        name: division.name,
        parent: null,
        isActiveComputed: division.isActive,
        type: "division",
      };
      result.push(mappedDivision);

      for (const company of division.companies) {
        const mappedCompany: OrganizationHierarchyInfo = {
          id: company.id,
          isActive: company.isActive,
          name: company.name,
          isActiveComputed: division.isActive && company.isActive,
          parent: mappedDivision,
          type: "company",
        };

        result.push(mappedCompany);
      }
    }

    return result;
  }, [organizations]);
}

export type OrganizationHierarchyComboBoxOption = {
  type: OrganizationType;
  data: OrganizationHierarchyInfo;
} & Omit<IComboBoxOption, "data">;

export function useOrganizationHierarchyComboBoxOptions(
  divisionStyles?: Partial<IComboBoxOptionStyles>,
  companyStyles?: Partial<IComboBoxOptionStyles>
): OrganizationHierarchyComboBoxOption[] {
  const hierarchy = useOrganizationHierarchy();
  return hierarchy.map((h) => ({
    key: h.id,
    text: h.type === "division" ? h.name : `${h.name} (${h.parent?.name})`,
    type: h.type,
    data: h,
    styles: h.type === "division" ? divisionStyles : companyStyles,
  }));
}
