import { useWindowSize } from "./useWindowSize";

//breakpoints taken from default tailwind https://tailwindcss.com/docs/responsive-design
export function useMediaQueryBreakpoints() {
  const { width } = useWindowSize();

  return {
    isSm: width < 768,
    isMd: width >= 768 && width < 1024,
    isLg: width >= 1024 && width < 1280,
    isXl: width >= 1280 && width < 1536,
    is2Xl: width >= 1536,
  };
}
