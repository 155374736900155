import PageHeader from "../../../common/components/PageHeader";
import { useTranslator } from "../../../common/state/translatorState";
import { removeTime, removeTimezoneOffset } from "../../../common/utilities";
import { useActiveContactTypesQuery } from "../../contactTypes/contactTypeQueries";
import { useActiveLocationsQuery } from "../../locations/locationsQueries";
import { useActiveOrganizationsQuery } from "../../organizations/organizationQueries";
import { useCurrentUserQuery } from "../../users/userQueries";
import ContactReportForm from "../components/ContactReportForm";
import { useLatestContactReportQuery } from "../contactReportQueries";
import { ContactReportSchema } from "../contactReportSchemas";

const NewContactReportScreen = () => {
  const t = useTranslator();

  const { data: currentUser } = useCurrentUserQuery();
  const { data: organizations } = useActiveOrganizationsQuery();
  const { data: contactTypes } = useActiveContactTypesQuery();
  const { data: locations } = useActiveLocationsQuery();
  const { data: latestReport, isFetched: isLatestReportFetched } = useLatestContactReportQuery();

  if (!currentUser || !organizations || !contactTypes || !locations || !isLatestReportFetched) {
    //TODO: return skeleton and error handling?
    return null;
  }

  const contactReport: ContactReportSchema = {
    attendee: {
      name: latestReport?.attendee.displayName ?? currentUser.displayName ?? "",
      email: latestReport?.attendeeEmail ?? currentUser.email ?? "",
      phoneNumber: latestReport?.attendeePhone ?? currentUser.mobilePhone ?? "",
      uniqueId: latestReport?.attendee.uniqueId ?? currentUser.uniqueId,
    },
    attendeeCompanyId: latestReport?.attendeeCompany.id ?? 0,
    contactTypeId: 0,
    meetingCountryId: 0,
    attendees: "",
    competitorCompanyName: "",
    meetingDate: removeTimezoneOffset(removeTime(new Date())),
    subject: "",
    submitterName: latestReport?.createdBy.displayName ?? currentUser.displayName ?? "",
    submitterEmail: latestReport?.submitterEmail ?? currentUser.email ?? "",
    submitterPhone: latestReport?.submitterPhone ?? currentUser.mobilePhone ?? "",
    summary: "",
    meetingAddress: "",
  };

  return (
    <>
      <PageHeader className="mb-2">{t.ContactReportDetail.Header.ContactReportNew.Title}</PageHeader>
      <ContactReportForm contactReport={contactReport} contactTypes={contactTypes} locations={locations} organizations={organizations} />
    </>
  );
};

export default NewContactReportScreen;
