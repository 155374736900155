import { z } from "zod";

//TODO: possibly add uploaded by to schema
const UploadedFileDetailSchema = z.object({
  id: z.number(),
  fileName: z.string(),
  extension: z.string().optional().nullable(),
  sizeInBytes: z.number(),
  contentType: z.string(),
  context: z.string().optional().nullable(),
  storageFileId: z.string(),
  uploadedOn: z.string(),
});

export { UploadedFileDetailSchema };
