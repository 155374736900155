import { atom } from "recoil";

export type ToastNotificationType = "success" | "error";
export type ToastNotificatonPosition = "top-left" | "bottom-left" | "top-right" | "bottom-right";

export type ToastNotification = {
  title: string;
  description?: string;
  duration: number;
  type: ToastNotificationType;
  position: ToastNotificatonPosition;
};

const toastNotificationState = atom({
  key: "toastNotification",
  default: {
    notifications: new Array<ToastNotification>(),
  },
});

export { toastNotificationState };
