import { useNavigate } from "react-router-dom";
import { useTranslator } from "../../../common/state/translatorState";
import { useCreateContactType } from "../contactTypeQueries";
import { ContactSystemType, ContactTypeCreateUpdateDTO, ContactTypeTranslationDTO } from "../../../services/contactReportsApi";
import { useDefaultLanguageQuery, useLanguagesQuery } from "../../../common/queries/languageQueries";
import PageHeader from "../../../common/components/PageHeader";
import ContactTypeDetailForm from "../components/ContactTypeForm";
import useToastNotification from "../../../common/hooks/useToastNotification";

export default function NewContactTypeScreen() {
  const t = useTranslator();
  const navigate = useNavigate();
  const toast = useToastNotification();
  const { mutate: createContactType, isLoading: isUpdating } = useCreateContactType();

  const { data: languages } = useLanguagesQuery();
  const { data: defaultLanguage } = useDefaultLanguageQuery();

  if (!languages || !defaultLanguage) {
    //TODO: return skeleton form?
    return null;
  }

  const onCancel = () => navigate(-1);
  const onSubmit = (item: ContactTypeCreateUpdateDTO) => {
    item.nameTranslations = item.nameTranslations.filter((t) => t.text && t.text.length > 0);
    item.nameTranslations.push({ languageId: defaultLanguage.id, text: item.name } as ContactTypeTranslationDTO);
    createContactType(item, {
      onSuccess: () => {
        navigate(-1);
        toast.showSuccess(t.Components.ToastMessage.RecordCreated.Title);
      },
    });
  };

  const newContactType: ContactTypeCreateUpdateDTO = {
    name: "",
    contactSystemType: ContactSystemType.InPerson,
    nameTranslations: languages
      .sort((a, b) => a.defaultName.localeCompare(b.defaultName))
      .filter((l) => l.id !== defaultLanguage.id)
      .map((l): ContactTypeTranslationDTO => ({ languageId: l.id, text: "" })),
  };

  return (
    <>
      <PageHeader className="mb-2">{t.ContactTypesList.Header.ContactTypes.Title}</PageHeader>
      <ContactTypeDetailForm
        contactType={newContactType}
        languages={languages}
        defaultLanguage={defaultLanguage}
        onSubmit={onSubmit}
        onCancel={onCancel}
        disabled={isUpdating}
        editable
      />
    </>
  );
}
