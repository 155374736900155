import { RouteObject } from "react-router-dom";
import App from "./App";
import { globalRoutes } from "./app/globalRoutes";
import { organizationsRoutes } from "./features/organizations/organizationsRoutes";
import { contactTypesRoutes } from "./features/contactTypes/contactTypesRoutes";
import { locationsRoutes } from "./features/locations/locationsRoutes";
import { userRoutes } from "./features/users/userRoutes";
import { contactReportRoutes } from "./features/contactReports/contactReportRoutes";

export const allRoutes = [
  ...contactReportRoutes,
  ...userRoutes,
  ...locationsRoutes,
  ...contactTypesRoutes,
  ...organizationsRoutes,
  ...globalRoutes,
];

export const baseRoute: RouteObject[] = [
  {
    id: "base",
    path: "/",
    element: <App />,
    children: allRoutes,
  },
];
