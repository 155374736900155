import { IIconProps } from "@fluentui/react";

export const addIcon: IIconProps = { iconName: "Add" };
export const editIcon: IIconProps = { iconName: "Edit" };
export const cancelIcon: IIconProps = { iconName: "Cancel" };
export const archivelIcon: IIconProps = { iconName: "RecycleBin" };
export const unArchivelIcon: IIconProps = { iconName: "EmptyRecycleBin" };
export const infoIcon: IIconProps = { iconName: "Info" };
export const searchIcon: IIconProps = { iconName: "Search" };
export const deleteIcon: IIconProps = { iconName: "Delete" };
export const expandIcon: IIconProps = { iconName: "ChevronDownMed" };
export const confirmIcon: IIconProps = { iconName: "CheckMark" };
export const excelIcon: IIconProps = { iconName: "ExcelLogo" };
export const clearFilterIcon: IIconProps = { iconName: "ClearFilter" };
export const menuIcon: IIconProps = { iconName: "CollapseMenu" };

export default {
  addIcon,
  editIcon,
  cancelIcon,
  archivelIcon,
  unArchivelIcon,
  infoIcon,
  searchIcon,
  deleteIcon,
  expandIcon,
  confirmIcon,
  excelIcon,
  clearFilterIcon,
  menuIcon,
};
