import { ContactReportCreateUpdateDTO } from "../../services/contactReportsApi";
import { ContactReportSchema } from "./contactReportSchemas";

function mapAttachments(contactReport: ContactReportSchema): number[] | undefined {
  const result: number[] = [];
  if (contactReport.attachment1) {
    result.push(contactReport.attachment1.id);
  }
  if (contactReport.attachment2) {
    result.push(contactReport.attachment2.id);
  }

  return result.length > 0 ? result : undefined;
}

export function mapToContactCreateUpdateDto(contactReport: ContactReportSchema): ContactReportCreateUpdateDTO {
  return {
    attendeeUniqueId: contactReport.attendee.uniqueId,
    attendeeEmail: contactReport.attendee.email,
    attendeePhone: contactReport.attendee.phoneNumber,
    attendeeCompanyId: contactReport.attendeeCompanyId,
    competitorCompanyName: contactReport.competitorCompanyName,
    contactTypeId: contactReport.contactTypeId,
    meetingCountryId: contactReport.meetingCountryId,
    meetingAddress: contactReport.meetingAddress,
    meetingDate: contactReport.meetingDate.toISOString(),
    attendees: contactReport.attendees,
    subject: contactReport.subject,
    summary: contactReport.summary,
    submitterEmail: contactReport.submitterEmail,
    submitterPhone: contactReport.submitterPhone,
    uploadedFilesIds: mapAttachments(contactReport),
  };
}
