import { atom } from "recoil";
import { RecoilState } from "recoil";
import { useRecoilState } from "recoil";
import { Paths } from "../model/keyofTypes";

export type SortFields<TData> = Partial<Record<Paths<TData>, boolean>>;

export type SortableQueryMetadata<TData> = {
  fields: SortFields<TData>;
};

export type PageableQueryMetadata = {
  offset: number;
  limit: number;
};

export type QueryMetadata<TData, TFilter> = {
  filter: TFilter;
  sort: SortableQueryMetadata<TData>;
  page: PageableQueryMetadata;
};

function toPascalCase<TData>(key: Paths<TData>): string {
  return key
    .split(".")
    .map((part) => (part.length > 0 ? part.charAt(0).toUpperCase() + part.slice(1) : part))
    .join(".");
}

export function createSortExpression<TData>(fields: SortFields<TData>): string {
  if (typeof fields !== "object") {
    return "";
  }

  let expression = "";
  for (const key of Object.keys(fields)) {
    const pathKey = key as Paths<TData>;
    expression += `${toPascalCase(pathKey)} ${fields[pathKey] === true ? "ASC" : "DESC"}, `;
  }

  return expression.length > 0 ? expression.substring(0, expression.length - 2) : expression;
}

function useQuerySort<TData, TFilter, TMetadata extends QueryMetadata<TData, TFilter>>(recoilState: RecoilState<TMetadata>) {
  const [metadata, setMetadata] = useRecoilState(recoilState);

  const toggleFieldSort = (...fields: string[]) => {
    setMetadata((s) => {
      const result: TMetadata = { ...s, sort: { fields: { ...s.sort.fields } } };

      for (const field of fields) {
        let processed = false;
        for (const key of Object.keys(result.sort.fields)) {
          const pathKey = key as Paths<TData>;
          if (key === field) {
            if (result.sort.fields[pathKey] === true) {
              result.sort.fields[pathKey] = false;
              processed = true;
            } else if (result.sort.fields[pathKey] === false) {
              delete result.sort.fields[pathKey];
              processed = true;
            }
          } else if (!fields.some((f) => f === key)) {
            delete result.sort.fields[pathKey];
          }
        }

        if (!processed) {
          const pathField = field as Paths<TData>;
          result.sort.fields[pathField] = true;
        }
      }

      return result;
    });
  };

  const isSortedByField = (field: Paths<TData>) => metadata.sort.fields[field] !== undefined;
  const isSortedDescending = (field: Paths<TData>) => metadata.sort.fields[field] === false;

  return {
    toggleFieldSort,
    isSortedByField,
    isSortedDescending,
  };
}

function useQueryFilter<TData, TFilter, TMetadata extends QueryMetadata<TData, TFilter>>(
  recoilState: RecoilState<TMetadata>,
  defaultFilter: TFilter
) {
  const [metadata, setMetadata] = useRecoilState(recoilState);

  return {
    filter: metadata.filter,
    defaultFilter,
    setFilter: (filter: TFilter) => setMetadata((s) => ({ ...s, filter })),
  };
}

function useQueryPaging<TData, TFilter, TMetadata extends QueryMetadata<TData, TFilter>>(recoilState: RecoilState<TMetadata>) {
  const [metadata, setMetadata] = useRecoilState(recoilState);

  return {
    page: metadata.page,
    setPage: (offset: number) => setMetadata((s) => ({ ...s, page: { ...s.page, offset } })),
  };
}

export function createQueryMetadataState<TData, TFilter, TMetadata extends QueryMetadata<TData, TFilter>>(
  key: string,
  defaultMetadata: TMetadata
) {
  const metadataState = atom<TMetadata>({
    key,
    default: defaultMetadata,
  });

  return {
    metadataState,
    useQuerySort: () => useQuerySort<TData, TFilter, TMetadata>(metadataState),
    useQueryFilter: () => useQueryFilter<TData, TFilter, TMetadata>(metadataState, defaultMetadata.filter),
    useQueryPaging: () => useQueryPaging<TData, TFilter, TMetadata>(metadataState),
  };
}
