import { useTranslator } from "../../common/state/translatorState";
import { ContactReportStatus } from "../../services/contactReportsApi";

export function useContactReportStatusName() {
  const t = useTranslator();
  return (status: ContactReportStatus, short: boolean = false): string => {
    if (short) {
      switch (status) {
        case ContactReportStatus.Pending:
          return t.EnumValueShortName.ContactReportStatus.Pending;
        case ContactReportStatus.Approved:
          return t.EnumValueShortName.ContactReportStatus.Approved;
        case ContactReportStatus.AdditionalInfoRequested:
          return t.EnumValueShortName.ContactReportStatus.AdditionalInfoRequested;
        case ContactReportStatus.ReviewedNotApproved:
          return t.EnumValueShortName.ContactReportStatus.ReviewedNotApproved;
        default:
          return "";
      }
    }

    switch (status) {
      case ContactReportStatus.Pending:
        return t.EnumValueFullName.ContactReportStatus.Pending;
      case ContactReportStatus.Approved:
        return t.EnumValueFullName.ContactReportStatus.Approved;
      case ContactReportStatus.AdditionalInfoRequested:
        return t.EnumValueFullName.ContactReportStatus.AdditionalInfoRequested;
      case ContactReportStatus.ReviewedNotApproved:
        return t.EnumValueFullName.ContactReportStatus.ReviewedNotApproved;
      default:
        return "";
    }
  };
}
