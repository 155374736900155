import { z } from "zod";
import { useMemo } from "react";
import { useTranslator } from "../../common/state/translatorState";

export const OrganizationTypeSchema = z.union([z.literal("division"), z.literal("company")]);

export function useOrganizationSchema() {
  const t = useTranslator();

  return useMemo(() => {
    return z.object({
      id: z.number().optional(),
      parentId: z.number().optional(),
      name: z
        .string()
        .trim()
        .nonempty(t.CompanyCreateUpdateDTO.Company.Field.IsRequired)
        .max(500, t.CompanyCreateUpdateDTO.Company.Field.MaxLengthExceeded("", "500")),
      type: OrganizationTypeSchema,
      isEdit: z.boolean(),
    });
  }, [t]);
}

export type OrganizationType = z.infer<typeof OrganizationTypeSchema>;
export type Organization = z.infer<ReturnType<typeof useOrganizationSchema>>;
