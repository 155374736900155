import { IDropdownOption, IGroup, SelectableOptionMenuItemType } from "@fluentui/react";
import { CompanyDetailDTO, CompanyInfoDTO, DivisionDetailDTO, DivisionInfoDTO } from "../../services/contactReportsApi";

export const mapToItems = (organizations: DivisionDetailDTO[]): CompanyDetailDTO[] => {
  const result: CompanyDetailDTO[] = [];
  for (const organization of organizations) {
    result.push(...organization.companies);
  }
  return result;
};

export const mapToGroups = (divisions: DivisionDetailDTO[], expanded: Record<string, boolean>): IGroup[] => {
  const result: IGroup[] = [];
  let currentIndex = 0;

  for (let i = 0; i < divisions.length; i++) {
    const division = divisions[i];
    const key = `division_` + division.id;
    const mappedDivision: IGroup = {
      key,
      name: division.name,
      count: division.companies.length,
      data: division,
      startIndex: currentIndex,
      isCollapsed: expanded[key] !== true,
      children: [],
      level: 0,
    };

    currentIndex += division.companies.length;
    result.push(mappedDivision);
  }

  return result;
};

export const findParentId = (companyId: number, divisions: DivisionDetailDTO[]): number | undefined => {
  for (const division of divisions) {
    if (division.id === companyId) {
      return;
    }

    for (const company of division.companies) {
      if (company.id === companyId) {
        return division.id;
      }
    }
  }
};

export function toGroupedOrganizationDropdownOptions(divisions: DivisionInfoDTO[]): IDropdownOption<CompanyInfoDTO>[] {
  const result: IDropdownOption<CompanyInfoDTO>[] = [];
  for (const division of divisions) {
    if (!Array.isArray(division.companies) || division.companies.length === 0) {
      continue;
    }

    result.push({ key: `divider_division_${division.name}`, text: division.name, itemType: SelectableOptionMenuItemType.Header });
    for (const company of division.companies) {
      result.push({ key: company.id, text: company.name, data: company });
    }
  }

  return result;
}
