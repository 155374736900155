import PageHeader from "../../../common/components/PageHeader";
import { useTranslator } from "../../../common/state/translatorState";
import ContactReportList from "../components/ContactReportList";
import ContactReportListFilter from "../components/ContactReportListFilter";
import { ContactReportQueryType } from "../contactReportSchemas";
import { useContactReportQueryMetadata } from "../contactReportState";

const type: ContactReportQueryType = "my";

const MyContactReportsListScreen = () => {
  const t = useTranslator();
  const metadata = useContactReportQueryMetadata(type);

  return (
    <>
      <PageHeader className="mb-2">{t.ContactReportsList.Header.MyReports.Title}</PageHeader>
      <ContactReportListFilter type={metadata.type} className="mb-4" />
      <ContactReportList metadata={metadata} />
    </>
  );
};

export default MyContactReportsListScreen;
