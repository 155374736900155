import { addMinutes, format } from "date-fns";
import { Paths } from "./model/keyofTypes";
import { HierarchyInfo } from "./model/hierarchyInfoSchema";

export const nameof = <T extends {}>(name: Paths<T>) => name;

export const sleep = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds));

const DATE_FORMAT = "dd/MM/yyyy";
const DATETIME_FORMAT = "dd/MM/yyyy HH:mm";
const DATETIME_SECONDS_FORMAT = "dd/MM/yyyy HH:mm:ss";

export const formatDate = (date: string | Date | null | undefined): string => {
  if (date === null || date === undefined) {
    return "";
  }
  let dateValue: Date = typeof date === "string" ? new Date(date) : date;
  return format(dateValue, DATE_FORMAT);
};

export const formatDateTime = (date: string | Date | null | undefined, includeSeconds: boolean = false): string => {
  if (date === null || date === undefined) {
    return "";
  }
  let dateValue: Date = typeof date === "string" ? new Date(date) : date;
  return format(dateValue, includeSeconds ? DATETIME_SECONDS_FORMAT : DATETIME_FORMAT);
};

export function debounce<A = unknown, R = void>(fn: (args: A) => R, ms: number): [(args: A) => Promise<R>, () => void] {
  let timer: NodeJS.Timeout;

  const debouncedFunc = (args: A): Promise<R> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        resolve(fn(args));
      }, ms);
    });

  const teardown = () => clearTimeout(timer);

  return [debouncedFunc, teardown];
}

export const removeTimezoneOffset = (date: Date) => addMinutes(date, -date.getTimezoneOffset());

export const removeTime = (date: Date) => new Date(date.toDateString());

export const downloadFile = (file: Blob, fileName: string) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(file);
  link.download = fileName;
  link.click();
  setTimeout(() => link.remove(), 1000);
};

export function isPromise(value: any) {
  if (typeof value === "object" && typeof value.then === "function") {
    return true;
  }

  return false;
}

const extractParents = (hierarchy: HierarchyInfo): HierarchyInfo[] => {
  const extractParentRecursive = (current: HierarchyInfo, parentsSoFar: HierarchyInfo[]): HierarchyInfo[] => {
    parentsSoFar.push(current);
    if (current.parent) {
      return extractParentRecursive(current.parent, parentsSoFar);
    }
    return parentsSoFar.reverse();
  };

  return extractParentRecursive(hierarchy, []);
};

export function compareHierarchies(a?: HierarchyInfo | null, b?: HierarchyInfo | null): number {
  if (!a && b) return -1;
  if (a && !b) return 1;
  if (!a && !b) return 0;

  const aParents = a ? extractParents(a) : [];
  const bParents = b ? extractParents(b) : [];

  const minCount = Math.min(aParents.length, bParents.length);
  for (let i = 0; i < minCount; i++) {
    const comparison = aParents[i].name.localeCompare(bParents[i].name);
    if (comparison !== 0) {
      return comparison;
    }
  }

  return 0;
}

export function formatFileSize(bytes: number, decimalPoint?: number) {
  if (bytes === 0) return "0 Bytes";
  const k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
