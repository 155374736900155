import { DefaultButton, IDropdownOption, PrimaryButton, Stack } from "@fluentui/react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledTextField } from "../../../common/components/form/ControlledTextField";
import { nameof } from "../../../common/utilities";
import { DivisionDetailDTO, RegionDetailDTO, SubRegionDetailDTO } from "../../../services/contactReportsApi";
import { ControlledDropdown } from "../../../common/components/form/ControlledDropdown";
import { useMemo } from "react";
import { useTranslator } from "../../../common/state/translatorState";
import useToastNotification from "../../../common/hooks/useToastNotification";
import { Organization, useOrganizationSchema } from "../organizationSchemas";
import { useCreateOrganization, useUpdateOrganization } from "../organizationQueries";

const Container = styled.div`
  min-width: 24rem;
  min-height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

type ParentDropdownOption = IDropdownOption<DivisionDetailDTO>;

type Props = {
  organization: Organization;
  parents?: DivisionDetailDTO[];
  onCancel: () => any;
};

export default function OrganizationEditForm({ organization, parents, onCancel }: Props) {
  const t = useTranslator();
  const toast = useToastNotification();
  const organizationSchema = useOrganizationSchema();
  const { mutate: createOrganization, isLoading: isCreating } = useCreateOrganization();
  const { mutate: updateOrganization, isLoading: isUpdating } = useUpdateOrganization();

  const isLoading = isCreating || isUpdating;
  const hasParents = Array.isArray(parents);

  const parentOptions = useMemo<ParentDropdownOption[]>(
    () => (Array.isArray(parents) ? parents.map((p): ParentDropdownOption => ({ key: p.id, text: p.name, data: p })) : []),
    [parents]
  );

  const { control, handleSubmit } = useForm<Organization>({
    defaultValues: organization,
    resolver: zodResolver(organizationSchema),
  });

  const onSubmit = handleSubmit((data) => {
    if (data.isEdit) {
      updateOrganization(
        { organization: data },
        {
          onSuccess: () => {
            toast.showSuccess(t.Components.ToastMessage.RecordUpdated.Title);
            onCancel();
          },
        }
      );
    } else {
      createOrganization(
        { organization: data },
        {
          onSuccess: () => {
            toast.showSuccess(t.Components.ToastMessage.RecordCreated.Title);
            onCancel();
          },
        }
      );
    }
  });

  return (
    <Container>
      {hasParents && (
        <ControlledDropdown
          className="mb-4"
          name={nameof<Organization>("parentId")}
          control={control}
          options={parentOptions}
          label={t.OrganisationsEditor.Field.Division.Label}
        />
      )}
      <ControlledTextField name={nameof<Organization>("name")} control={control} label={t.OrganisationsEditor.Field.Name.Label} autoFocus />
      <Stack horizontal className="mt-4 gap-4 flex-row-reverse">
        <DefaultButton disabled={isLoading} text={t.Components.Button.Cancel.Label} onClick={onCancel} />
        <PrimaryButton disabled={isLoading} text={t.Components.Button.Save.Label} onClick={onSubmit} />
      </Stack>
    </Container>
  );
}
