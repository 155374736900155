import { ITextProps, Text } from "@fluentui/react";
import theme from "../../app/theme";

const style: React.CSSProperties = { color: theme.palette.themePrimary, display: "block" };

type Props = {
  children?: string;
  rightElement?: JSX.Element;
} & ITextProps;

export default function PageHeader({ children, rightElement, ...other }: Props) {
  return (
    <div className="flex flex-row justify-between align-center">
      <Text variant="xLarge" style={style} {...other}>
        {children}
      </Text>
      {rightElement}
    </div>
  );
}
