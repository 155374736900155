import { IconButton } from "@fluentui/react";
import { archivelIcon, editIcon, infoIcon, unArchivelIcon } from "../../../common/components/Icons";
import { ContactTypeDetailDTO } from "../../../services/contactReportsApi";
import { NavigateFunction } from "react-router-dom";
import { ConfirmationDialogActions } from "../../../common/hooks/useConfirmationDialog";

type Props = {
  contactType: ContactTypeDetailDTO;
  navigate: NavigateFunction;
  confirmationDialog: ConfirmationDialogActions;
  archive: (contactTypeId: number) => any;
  unarchive: (contactTypeId: number) => any;
};

const ContactTypeActionCell = ({ contactType, confirmationDialog, navigate, archive, unarchive }: Props) => {
  const onArchiveButtonClick = async () => {
    if (
      await confirmationDialog.isConfirmed(
        contactType.isActive === true ? "Archive contact type" : "Unarchive contact type",
        contactType.isActive === true
          ? "Are you sure you want to archive this contact type?"
          : "Are you sure you want to unarchive this contact type?"
      )
    ) {
      if (contactType.isActive === true) {
        archive(contactType.id);
      } else {
        unarchive(contactType.id);
      }
    }
  };

  return (
    <div className="flex flex-row justify justify-start items-center gap-2">
      <IconButton
        iconProps={contactType.archivedBy ? infoIcon : editIcon}
        onClick={() => navigate({ pathname: `/contact-types/${contactType.id}` })}
      />
      <IconButton iconProps={contactType.isActive === true ? archivelIcon : unArchivelIcon} onClick={onArchiveButtonClick} />
    </div>
  );
};

export default ContactTypeActionCell;
