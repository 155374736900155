import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import EditDialog from "./EditDialog";
import { errorDialogState } from "../../state/errorDialogState";
import { MessageBar, MessageBarType, PrimaryButton, Stack } from "@fluentui/react";
import { useTranslator } from "../../state/translatorState";

const ErrorDialog = () => {
  const t = useTranslator();
  const [state, setState] = useRecoilState(errorDialogState);
  const resetState = useResetRecoilState(errorDialogState);

  const renderFooter = () => (
    <Stack horizontal className="mt-4 gap-4 flex-row-reverse">
      <PrimaryButton onClick={closeWithoutReset} text={t.Components.Button.Ok.Label} />
    </Stack>
  );

  const closeWithoutReset = () => {
    setState((s) => ({ ...s, isVisible: false }));
  };

  //TODO: translate
  return (
    <EditDialog
      isOpen={state.isVisible}
      title={state.title && state.title.length > 0 ? state.title : "Error occurred"}
      containerClassName="w-full lg:w-2/3 xl:w-1/2"
      footer={renderFooter()}
      maxHeight="20rem"
      onDismissed={resetState}
      onCancel={closeWithoutReset}
    >
      <div className="flex flex-col gap-2 mt-8">
        {state.errors.map((e, index) => (
          <MessageBar key={index} messageBarType={MessageBarType.error} isMultiline>
            {e}
          </MessageBar>
        ))}
      </div>
    </EditDialog>
  );
};

export default ErrorDialog;
