import { ActionButton } from "@fluentui/react";
import PageHeader from "../../../common/components/PageHeader";
import OrganizationList from "../components/OrganizationList";
import { useTranslator } from "../../../common/state/translatorState";
import styled from "styled-components";
import OrganizationEditDialog from "../components/OrganizationEditDialog";
import { addIcon } from "../../../common/components/Icons";
import { useCallback, useState } from "react";
import { DivisionDetailDTO } from "../../../services/contactReportsApi";
import { Organization, OrganizationType } from "../organizationSchemas";
import useToastNotification from "../../../common/hooks/useToastNotification";
import { useAllOrganizationsQuery, useArchiveOrganization, useUnArchiveOrganization } from "../organizationQueries";
import { useConfirmationDialog } from "../../../common/hooks/useConfirmationDialog";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 24rem;
`;

type DialogState = {
  isOpen: boolean;
  organization: Organization;
  parents?: DivisionDetailDTO[];
};

const OrganizationsScreen = () => {
  const t = useTranslator();
  const toast = useToastNotification();
  const { data: regions } = useAllOrganizationsQuery();
  const { mutate: archiveLocation } = useArchiveOrganization();
  const { mutate: unArchiveLocation } = useUnArchiveOrganization();
  const { isConfirmed } = useConfirmationDialog();

  const [dialogState, setDialogState] = useState<DialogState>({
    isOpen: false,
    organization: { name: "", type: "division", isEdit: false },
  });

  const openCreateDialog = useCallback(
    (type: OrganizationType, parentId?: number) => {
      setDialogState((state) => ({
        ...state,
        isOpen: true,
        organization: { name: "", type, parentId, isEdit: false },
        parents: undefined,
      }));
    },
    [setDialogState]
  );

  const openEditDialog = useCallback(
    (organization: Organization, parents?: DivisionDetailDTO[]) => {
      setDialogState((state) => ({ ...state, isOpen: true, organization, parents }));
    },
    [setDialogState]
  );

  const closeDialog = useCallback(() => setDialogState((state) => ({ ...state, isOpen: false, parents: undefined })), [setDialogState]);

  const handleArchive = useCallback(
    async (organizationId: number) => {
      if (await isConfirmed(t.Components.ConfirmationDialog.Archive.Title, t.Components.ConfirmationDialog.Archive.Description)) {
        archiveLocation(organizationId, { onSuccess: () => toast.showSuccess(t.Components.ToastMessage.RecordArchived.Title) });
      }
    },
    [t, isConfirmed, archiveLocation]
  );

  const handleUnArchive = useCallback(
    async (organizationId: number) => {
      if (await isConfirmed(t.Components.ConfirmationDialog.Unarchive.Title, t.Components.ConfirmationDialog.Unarchive.Description)) {
        unArchiveLocation(organizationId, { onSuccess: () => toast.showSuccess(t.Components.ToastMessage.RecordUnarchived.Title) });
      }
    },
    [t, isConfirmed, unArchiveLocation]
  );

  return (
    <>
      <PageHeader>{t.Menu.MenuItem.Organisations}</PageHeader>
      <Container>
        <ActionButton iconProps={addIcon} onClick={() => openCreateDialog("division")} className="mb-1">
          {t.OrganisationsEditor.Button.AddNewDivision.Label}
        </ActionButton>
        <OrganizationList
          organizations={regions ?? []}
          onCreate={openCreateDialog}
          onEdit={openEditDialog}
          onArchive={handleArchive}
          onUnArchive={handleUnArchive}
        />
      </Container>
      <OrganizationEditDialog
        isOpen={dialogState.isOpen}
        onCancel={closeDialog}
        organization={dialogState.organization}
        parents={dialogState.parents}
      />
    </>
  );
};

export default OrganizationsScreen;
