import { DetailsListLayoutMode, IColumn, IconButton, SelectionMode } from "@fluentui/react";
import { memo, useCallback, useMemo } from "react";
import { UserQueryMetadata } from "../userSchemas";
import { nameof } from "../../../common/utilities";
import { SystemRole, SystemUserListQueryDTO } from "../../../services/contactReportsApi";
import { useTranslator } from "../../../common/state/translatorState";
import { useUserQuerySort } from "../userState";
import { searchIcon } from "../../../common/components/Icons";
import { useNavigate } from "react-router-dom";
import { useUserListQuery } from "../userQueries";
import { useDebouncedValue } from "../../../common/hooks/useDebounce";
import ExtendedShimmeredDetailsList from "../../../common/components/ExtendedShimmeredDetailsList";
import useThrottle from "../../../common/hooks/useThrottle";
import Chip from "../../../common/components/Chip";
import { useSystemRoleName } from "../userHooks";
import theme from "../../../app/theme";
import { hasRoleFlag } from "../../../common/hooks/useAuthorization";

const SystemRoleChip = memo(({ role }: { role: SystemRole }) => {
  const systemRoleName = useSystemRoleName();
  const isAdmin = hasRoleFlag(role, SystemRole.Administrator);
  const isApprover = hasRoleFlag(role, SystemRole.Approver);
  const isSubmitter = hasRoleFlag(role, SystemRole.Submitter);

  return (
    <div className="flex flex-row items-center flex-wrap gap-1">
      {isAdmin && (
        <Chip
          text={systemRoleName(SystemRole.Administrator, true)}
          backgroundColor={theme.palette.themePrimary}
          textColor={theme.palette.white}
        />
      )}
      {isApprover && (
        <Chip text={systemRoleName(SystemRole.Approver, true)} backgroundColor={theme.palette.blueLight} textColor={theme.palette.white} />
      )}
      {isSubmitter && <Chip text={systemRoleName(SystemRole.Submitter, true)} />}
    </div>
  );
});

type Props = {
  metadata: UserQueryMetadata;
};

const UserList = ({ metadata }: Props) => {
  const t = useTranslator();
  const navigate = useNavigate();
  const { toggleFieldSort, isSortedDescending, isSortedByField } = useUserQuerySort();
  const { data: users, isFetching, isFetched, fetchNextPage, hasNextPage } = useUserListQuery(useDebouncedValue(metadata, 150));

  const onDetailButtonClick = useCallback(
    (user: SystemUserListQueryDTO) => {
      navigate(`/users/${user.id}`);
    },
    [navigate]
  );

  const columns = useMemo(
    (): IColumn[] => [
      {
        key: "lastName",
        name: t.SystemUsersList.GridColumn.LastName.Label,
        flexGrow: 1,
        minWidth: 180,
        calculatedWidth: 0,
        fieldName: nameof<SystemUserListQueryDTO>("lastName"),
        onColumnClick: (_, column) => toggleFieldSort(column.fieldName ?? ""),
        isSorted: isSortedByField("lastName"),
        isSortedDescending: isSortedDescending("lastName"),
      },
      {
        key: "firstName",
        name: t.SystemUsersList.GridColumn.FirstName.Label,
        flexGrow: 1,
        minWidth: 180,
        calculatedWidth: 0,
        fieldName: nameof<SystemUserListQueryDTO>("firstName"),
        onColumnClick: (_, column) => toggleFieldSort(column.fieldName ?? ""),
        isSorted: isSortedByField("firstName"),
        isSortedDescending: isSortedDescending("firstName"),
      },
      {
        key: "email",
        name: t.SystemUsersList.GridColumn.Email.Label,
        flexGrow: 1,
        minWidth: 240,
        calculatedWidth: 0,
        fieldName: nameof<SystemUserListQueryDTO>("email"),
        onColumnClick: (_, column) => toggleFieldSort(column.fieldName ?? ""),
        isSorted: isSortedByField("email"),
        isSortedDescending: isSortedDescending("email"),
      },
      {
        key: "systemRole",
        name: t.SystemUsersList.GridColumn.SystemRoles.Label,
        minWidth: 200,
        fieldName: nameof<SystemUserListQueryDTO>("systemRoles"),
        onColumnClick: (_, column) => toggleFieldSort(column.fieldName ?? ""),
        onRender: (item: SystemUserListQueryDTO) => <SystemRoleChip role={item.systemRoles} />,
        isSorted: isSortedByField("systemRoles"),
        isSortedDescending: isSortedDescending("systemRoles"),
      },
      {
        key: "action",
        name: t.ContactReportsList.GridColumn.Action.Label,
        minWidth: 50,
        maxWidth: 50,
        onRender: (item: SystemUserListQueryDTO) => <IconButton iconProps={searchIcon} onClick={() => onDetailButtonClick(item)} />,
        isSorted: false,
        headerClassName: "non-sortable",
      },
    ],
    [t, isSortedDescending, toggleFieldSort, isSortedByField, onDetailButtonClick]
  );

  const handleScrolledToBottom = useThrottle(
    () => {
      if (hasNextPage) {
        fetchNextPage();
      }
    },
    250,
    [hasNextPage, fetchNextPage]
  );

  const totalRows = users && users.pages.length > 0 ? users.pages[0].metaData?.totalRowCount ?? 0 : 0;
  const listItems = users ? users.pages.flat().flatMap((x) => x.data ?? []) : [];
  return (
    <>
      <ExtendedShimmeredDetailsList
        columns={columns}
        items={listItems}
        compact
        selectionMode={SelectionMode.none}
        enableShimmer={isFetching && listItems.length === 0}
        layoutMode={DetailsListLayoutMode.justified}
        onScrolledToBottom={handleScrolledToBottom}
        totalRowCount={totalRows}
      />
      {isFetched && !isFetching && listItems.length === 0 && <p className="m-0 text-center">{t.SystemUsersList.List.NoUsers}</p>}
    </>
  );
};

export default UserList;
