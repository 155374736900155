import { RouteObject } from "react-router-dom";
import ProtectedRoute from "../../common/components/ProtectedRoute";
import { RoleCache } from "../../common/hooks/useAuthorization";
import OrganizationsScreen from "./screens/OrganizationsScreen";

export const organizationsRoutes: RouteObject[] = [
  {
    id: "organizations",
    path: "/organizations",
    element: (
      <ProtectedRoute allowedRoles={RoleCache.AdminArray}>
        <OrganizationsScreen />
      </ProtectedRoute>
    ),
  },
];
