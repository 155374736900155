import { ComboBox, ICalloutProps, IComboBoxProps, IIconStyles, Icon, Stack } from "@fluentui/react";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { HookFormProps } from "../../model/hookFormProps";
import { RequiredFieldProps } from "../../model/requiredFieldProps";
import FormRequiredLabel from "./FormRequiredLabel";
import { ClearableFieldProps } from "../../model/clearableFieldProps";

const calloutProps: ICalloutProps = { calloutMaxHeight: 500 };

const comboBoxIconStyles: IIconStyles = {
  root: {
    color: "rgb(96, 94, 92)",
    "&:hover": {
      fontWeight: 800,
    },
  },
};

export const ControlledCombobox: FC<HookFormProps & ClearableFieldProps & Omit<IComboBoxProps, "required"> & RequiredFieldProps> = ({required, ...props}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => {
        const renderIconButtonWithClear = () => (
          <Stack horizontal verticalAlign={"center"} horizontalAlign="end">
            {value !== props.defaultValue && value !== null && value !== undefined && props.showClear !== false && (
              <Icon
                iconName={"Cancel"}
                className="mr-2"
                styles={comboBoxIconStyles}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onChange(props.defaultValue);
                }}
              />
            )}
            <Icon iconName={"ChevronDown"} styles={comboBoxIconStyles} />
          </Stack>
        );

        return (
          <ComboBox
            calloutProps={calloutProps}
            {...props}
            selectedKey={value ?? null}
            onChange={(_, option) => {
              if (option) {
                onChange(option.key);
              }
            }}
            onEmptied={() => onChange(props.defaultValue)}
            onBlur={onBlur}
            errorMessage={error && error.message}
            defaultValue={undefined}
            onRenderLabel={(comboboxFieldProps) => <FormRequiredLabel label={comboboxFieldProps?.props?.label} required={required} />}
            iconButtonProps={{ onRenderMenuIcon: renderIconButtonWithClear }}
            caretDownButtonStyles={{
              icon: { display: "none" },
              root: { width: value ? "40px" : "30px" },
              rootHovered: { backgroundColor: "transparent" },
              rootPressed: { backgroundColor: "transparent" },
              rootChecked: { backgroundColor: "transparent" },
              rootExpanded: { backgroundColor: "transparent" },
              rootExpandedHovered: { backgroundColor: "transparent" },
              rootCheckedHovered: { backgroundColor: "transparent" },
              rootCheckedPressed: { backgroundColor: "transparent" },
            }}
            styles={{ root: { paddingRight: value ? "50px" : "35px" } }}
          />
        );
      }}
    />
  );
};
