import { Label } from "@fluentui/react";
import styled from "styled-components";
import theme from "../../../app/theme";

const RequiredIndicator = styled.span`
  margin-left: 4px;
  margin-top: 3px;
  font-size: 1.2rem;
  color: ${theme.semanticColors.errorText};
`;

type Props = {
  label?: string;
  required?: boolean;
};

const FormRequiredLabel = ({ label, required }: Props) => (
  <div className="flex flex-row items-center">
    <Label>{label}</Label> {required === true && <RequiredIndicator>*</RequiredIndicator>}
  </div>
);

export default FormRequiredLabel;
