import { SilentRequest } from "@azure/msal-browser";
import environment from "../app/environment";
import { msalInstance } from "../index";

export class ApiClientBase {
  getBaseUrl(_defaultUrl: string, _baseUrl?: string): string {
    return environment.apiUrl;
  }

  protected async transformOptions(options: RequestInit): Promise<RequestInit> {
    const token = await this.getAccessToken();
    if (token) {
      options.headers = { ...options.headers, authorization: `Bearer ${token.accessToken}` };
    }
    return options;
  }

  private async getAccessToken() {
    const request: SilentRequest = {
      scopes: environment.msal.scopes,
      account: msalInstance.getAllAccounts()[0],
    };

    try {
      return await msalInstance.acquireTokenSilent(request);
    } catch (error) {
      console.error("Error while getting access token", error);
      await msalInstance.acquireTokenRedirect({ scopes: environment.msal.scopes, account: msalInstance.getAllAccounts()[0] });
    }
  }
}
