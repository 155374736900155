import { useMemo } from "react";
import { useTranslator } from "../../common/state/translatorState";
import { ZodSchema, z } from "zod";
import { ContactSystemType, ContactTypeCreateUpdateDTO, ContactTypeTranslationDTO } from "../../services/contactReportsApi";

const ContactSystemTypeSchema = z.nativeEnum(ContactSystemType);

const ContactTypeTranslationDTOSchema: ZodSchema<ContactTypeTranslationDTO> = z.object({
  languageId: z.number(),
  text: z.string(),
});

export function useContactTypeSchema(): ZodSchema<ContactTypeCreateUpdateDTO> {
  const t = useTranslator();

  return useMemo(() => {
    return z.object({
      name: z.string().nonempty(),
      contactSystemType: ContactSystemTypeSchema,
      nameTranslations: z.array(ContactTypeTranslationDTOSchema),
    });
  }, [t]);
}
