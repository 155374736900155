import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useCurrentUserQuery, useSetPreferredLanguage } from "../../features/users/userQueries";
import { useMediaQueryBreakpoints } from "../hooks/useMediaQueryBreakpoints";
import { useLanguagesQuery } from "../queries/languageQueries";
import FlagIcon from "./FlagIcon";

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
`;

type IconOptionData = {
  icon: string;
};

export default memo(() => {
  const { data: languages } = useLanguagesQuery({ initialData: [] });
  const { data: currentUser } = useCurrentUserQuery();
  const { mutate: setPreferredLanguage, isLoading: savingLanguage } = useSetPreferredLanguage();
  const { isSm } = useMediaQueryBreakpoints();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const options: IDropdownOption<IconOptionData>[] = useMemo(
    () =>
      (languages ?? [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(
          (l): IDropdownOption<IconOptionData> => ({
            key: l.id,
            text: isSm && !isDropdownOpen ? "" : l.name,
            selected: l.id === currentUser?.language.id,
            data: { icon: l.icon },
          })
        ),
    [languages, isSm, isDropdownOpen, currentUser?.language.id]
  );

  const onRenderOption = (option: IDropdownOption<IconOptionData> | undefined) =>
    option ? (
      <ItemContainer key={option.id}>
        <FlagIcon countryCode={option.data?.icon ?? ""} />
        <span>{option.text}</span>
      </ItemContainer>
    ) : null;

  const onRenderTitle = (options: IDropdownOption<IconOptionData>[] | undefined, showText: boolean) =>
    options ? (
      <ItemContainer>
        <FlagIcon countryCode={options[0].data?.icon ?? ""} />
        {showText && <span>{options[0].text}</span>}
      </ItemContainer>
    ) : null;

  const handleSelectionChanged = useCallback(
    (option?: IDropdownOption) => {
      if (option && typeof option.key === "number") {
        setPreferredLanguage({ languageId: option.key });
      }
    },
    [setPreferredLanguage]
  );

  if (options.length === 0 || !currentUser) {
    return <div />;
  }

  return (
    <Dropdown
      onRenderTitle={(options) => onRenderTitle(options, !isSm)}
      onRenderOption={onRenderOption}
      styles={{ dropdown: { width: isSm ? 65 : 180 } }}
      options={options}
      defaultSelectedKey={currentUser.language.id}
      onChange={(_, option) => handleSelectionChanged(option)}
      onRenderList={(props, defaultRender) => {
        setIsDropdownOpen(true);
        return defaultRender ? defaultRender(props) : <></>;
      }}
      onDismiss={() => setIsDropdownOpen(false)}
      disabled={savingLanguage}
      calloutProps={{ calloutWidth: 180 }}
      // selectedKey={currentUser.language.id}
    />
  );
});
