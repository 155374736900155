import styled from "styled-components";
import theme from "../../app/theme";
import LanguagePicker from "./LanguagePicker";
import { useTranslator } from "../state/translatorState";
import { useCurrentUserQuery } from "../../features/users/userQueries";

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  height: 2.5rem;
  padding: 0.3rem 1rem;
  position: relative;
  background-color: ${theme.palette.themePrimary};
`;

const ApplicationName = styled.p`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
`;

const Username = styled.p`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
  color: white;
`;

export default () => {
  const t = useTranslator();
  const { data: currentUser } = useCurrentUserQuery();

  return (
    <StyledNav className="no-print">
      <Username>{currentUser?.displayName ?? ""}</Username>
      <ApplicationName>{t.Layout.Header.Title}</ApplicationName>
      <LanguagePicker />
    </StyledNav>
  );
};
