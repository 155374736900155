import { useInfiniteQuery, useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import {
  ADUserSearchResult,
  CurrentSystemUserDetailDTO,
  SystemUserDetailDTO,
  SystemUsersClient,
  SystemUserUpdateDTO,
} from "../../services/contactReportsApi";
import useToastNotification from "../../common/hooks/useToastNotification";
import { useTranslator } from "../../common/state/translatorState";
import { UserQueryMetadata } from "./userSchemas";
import { createSortExpression } from "../../common/hooks/useQueryMetadata";
import { translationQueryKeys } from "../../common/queries/translationQueries";

const client = new SystemUsersClient();

export const userQueryKeys = {
  users: ["users"] as const,
  current: () => [...userQueryKeys.users, "current"] as const,
  detail: (id: number) => [...userQueryKeys.users, "detail", id] as const,
  search: (query: string) => [...userQueryKeys.users, "search", query] as const,
  list: (metadata: UserQueryMetadata) => [...userQueryKeys.users, "list", metadata] as const,
};

export const useCurrentUserQuery = (
  options?: Omit<
    UseQueryOptions<CurrentSystemUserDetailDTO, unknown, CurrentSystemUserDetailDTO, readonly ["users", "current"]>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(userQueryKeys.current(), () => client.getCurrentUser(), options);
};

export const useUserDetailsQuery = (
  id: number,
  options?: Omit<
    UseQueryOptions<SystemUserDetailDTO, unknown, SystemUserDetailDTO, readonly ["users", "detail", number]>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(userQueryKeys.detail(id), () => client.getById(id), options);
};

export const useSetPreferredLanguage = () => {
  const queryClient = useQueryClient();
  return useMutation((data: { languageId: number }) => client.setPreferredLanguage({ preferredLanguageId: data.languageId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKeys.current());
      queryClient.invalidateQueries(translationQueryKeys.translations());
    },
  });
};

export const useAddUserFromAd = () => {
  return useMutation((data: { uniqueId: string }) => client.createSystemUser({ uniqueId: data.uniqueId }));
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation((data: { userId: number; update: SystemUserUpdateDTO }) => client.updateSystemUser(data.userId, data.update), {
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKeys.users);
    },
  });
};

export const useSearchUsersQuery = (
  query: string,
  options?: Omit<
    UseQueryOptions<ADUserSearchResult[], unknown, ADUserSearchResult[], readonly ["users", "search", string]>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(userQueryKeys.search(query), () => client.lookupADUsers(query), options);
};

export const useUserListQuery = (initialMetadata: UserQueryMetadata) => {
  const t = useTranslator();
  const toast = useToastNotification();

  return useInfiniteQuery({
    queryKey: userQueryKeys.list(initialMetadata),
    queryFn: (infiniteParams) => {
      const metadata = (infiniteParams.pageParam as UserQueryMetadata) ?? initialMetadata;
      return client.getAllByFilter(
        metadata.page.offset,
        metadata.page.limit,
        createSortExpression(metadata.sort.fields),
        metadata.filter.firstName,
        metadata.filter.lastName,
        metadata.filter.systemRole
      );
    },
    getNextPageParam: (lastPage, pages): UserQueryMetadata | undefined => {
      let currentRowCount = 0;
      for (const page of pages) {
        if (Array.isArray(page.data)) {
          currentRowCount += page.data.length;
        }
      }

      if (lastPage.metaData && currentRowCount === lastPage.metaData.totalRowCount) {
        return undefined;
      }

      return {
        ...initialMetadata,
        page: {
          ...initialMetadata.page,
          offset: currentRowCount,
        },
      };
    },
    staleTime: 10 * 1000,
    keepPreviousData: true,
    onError: () => toast.showError(t.GenericErrors.UnexpectedError),
  });
};
